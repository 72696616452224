import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { DataService } from '../data.service';

declare var window: any;


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('stickyNavbar') navbarElement: ElementRef;

  sticky: boolean = false;
  navbarPosition: any;

  ngAfterViewInit() {
    this.navbarPosition = this.navbarElement.nativeElement.offsetTop;
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll > this.navbarPosition) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }
  selectedPage;

  constructor(
    public fb: FormBuilder,
    public service: DataService,
    public spinner: NgxSpinnerService,
    public toster: ToastrService
  ) { }
  @Output() SiginUpModal: EventEmitter<any> = new EventEmitter<any>();
  ngOnInit(): void {
    this.registrationForm = this.fb.group({
      name: ['', Validators.required],
      dob: '',
      email: ['', [Validators.required, Validators.email]],
      nric: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(12),
          Validators.pattern(this.pattern),
        ],
      ],
      password: ['', [Validators.required, Validators.minLength(6)]],
      phone: ['', [Validators.required, Validators.pattern(this.pattern)]],
    });

    this.loginFrom = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.restPasswordMail = this.fb.group({
      email: ['', Validators.required],
    });
    this.loginCheck();
    this.service.language.subscribe((lang) => {
      if (lang == 'ENG') {
        this.header = this.EN;
      } else {
        this.header = this.BM;
      }
    });
    this.displyModel = this.BMObj;
    this.modalLanguage();
    // let y:any = localStorage.getItem('InvalidCount');
    //  y = parseInt(y);
    //  if(y==2){
    //   this.failAttempt();
    //  }
  }
  // <-- variable Section --> //
  public loginFrom: FormGroup;
  public registrationForm: FormGroup;
  public userIsLogOut = false;
  public forgetPasswors = false;
  public pattern = /^[0-9"]*$/;
  public downWord = true;
  public upWord = false;
  public temporarilyBlocked = false;
  public restPasswordMail: FormGroup;
  public BM = {
    header0: 'LAMAN UTAMA',
    header1: 'program',
    header2: 'pertandingan',
    header3: 'promosi',
    header4: 'RAKAN KORPORAT',
    header5: 'Khidmat Nasihat 1-1',
    header6: 'Soalan Lazim',
    header7: 'T&S',
    header8: 'Daftar',
    header9: 'Log Masuk',
    header10: 'Log Keluar',
    logMessage: 'Anda telah berjaya log keluar',
    inValid: 'Invalid Credentials',
    menus: [
      'Eh Mana Kod',
      'Cabutan Bertuah',
      '#LakaranJuara',
      'Live Trivia & Live Trivia Bonanza',
      'Pass the Mic',
    ],
    pdfFiles: [
      '/assets//TERMSCONDITIONS-PDF/TNS-MANAKOD.pdf',
      '/assets//TERMSCONDITIONS-PDF/TNS-CABUTANBERTUAH.pdf',
      '/assets//TERMSCONDITIONS-PDF/TNS-LAKARANJUARA.pdf',
      '/assets//TERMSCONDITIONS-PDF/BM-LiveTrivia.pdf',
      '/assets//TERMSCONDITIONS-PDF/BM-PASSTHEMIC.pdf',
    ],
  };
  public EN = {
    header0: 'Home',
    header1: 'Programmes',
    header2: 'Contests',
    header3: 'Promotions',
    header4: 'Partners',
    header5: '1-on-1 Advisors',
    header6: 'FAQ',
    header7: 'T&C',
    header8: 'Register',
    header9: 'Login',
    header10: 'LogOut',
    logMessage: 'Logout Successfully',
    inValid: 'Invalid Credentials',
    menus: [
      'Eh Mana Kod',
      'Lucky Draw',
      '#LakaranJuara',
      'Live Trivia & Live Trivia Bonanza',
      'Pass the Mic',
    ],
    pdfFiles: [
      '/assets//TERMSCONDITIONS-PDF/TNC-MANAKOD.pdf',
      '/assets//TERMSCONDITIONS-PDF/TNC-LUCKYDRAW.pdf',
      '/assets//TERMSCONDITIONS-PDF/TNC-LAKARANJUARA.pdf',
      '/assets//TERMSCONDITIONS-PDF/EN-LIVETRIVIA.pdf',
      '/assets//TERMSCONDITIONS-PDF/EN-PASSTHEMIC.pdf',
    ],
  };
  public header;
  public displyModel: any;

  // <-- Function section --> //
  get f() {
    return this.registrationForm.controls;
  }
  public modalLanguage = () => {
    this.service.language.subscribe((res: any) => {
      if (res == 'ENG') {
        this.displyModel = this.EnglishObj;
        this.header = this.EN;
      } else {
        this.displyModel = this.BMObj;
        this.header = this.BM;
      }
    });
  };
  // public languageCheck = () => {

  //   if (this.service.setLanguage == 'BM') {
  //     this.header = this.BM
  //   } else {
  //     this.header = this.EN
  //   }
  //   this.service.language.subscribe((res: any) => {
  //     if (res == 'BM') {
  //       this.header = this.BM;
  //     } else {
  //       this.header = this.EN;
  //     }
  //   })

  // }

  public logOut = () => {
    localStorage.clear();
    this.toster.success(this.header.logMessage);
    //logout
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'logout',
      'eventCallback': function () {
        console.log('Logout Event Tags Triggered and Fired');
      }
    });
    this.loginCheck();
  };
  public loginCheck = () => {
    let token = localStorage.getItem('authToken');
    if (token) {
      this.userIsLogOut = false;
      this.service.login.next(true);
    } else {
      this.userIsLogOut = true;
      this.service.login.next(false);
    }
  };


  public login = () => {
    this.spinner.show();
    this.service.signIn(this.loginFrom.value).subscribe(
      (res: any) => {
        //login
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'login', //or logout for logging out
          eventCallback: function () {
            console.log('Login/Logout Event Tags Triggered and Fired');
          },
        });

        let x = document.getElementById('closeModal1');
        this.loginFrom.reset();
        this.spinner.hide();
        if (x) {
          x.click();
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'login',
          'eventCallback': function () {
            console.log('Login Event Tags Triggered and Fired');
          }
        });
        localStorage.setItem('authToken', res.jwt);
        this.loginCheck();
      },
      (err) => {
        this.spinner.hide();

        console.log(err.status);
        if (err.status == 401 || err.status == 0) {
          // this.failAttempt();
        }
        this.toster.error(this.displyModel.inValid);
      }
    );
  };

  // public failAttempt = () => {
  //   let count =  localStorage.getItem('InvalidCount');
  //        if(count) {
  //           let x:any =  parseInt(count);
  //           if(x==2){
  //            this.temporarilyBlocked =true;
  //            this.loginFrom.disable();
  //            this.loginFrom.reset();

  //            let y:any = document.getElementById('loginBtn')
  //            if(y){
  //             y.disabled = true;
  //            }
  //            setTimeout(() => {
  //              localStorage.removeItem('InvalidCount');
  //              this.loginFrom.enable();
  //              this.temporarilyBlocked = false;
  //              let btn:any = document.getElementById('loginBtn')
  //              btn.disabled = false;
  //            }, 10000);
  //           }else{
  //             x = x+1
  //             localStorage.setItem('InvalidCount',x)
  //           }

  //        }
  //        else{
  //         localStorage.setItem('InvalidCount', '1')
  //        }
  // }
  public submit = () => {
    console.log(this.registrationForm.value);
    this.spinner.show();
    this.service.registerUser(this.registrationForm.value).subscribe(
      (res: any) => {
        console.log(res);
        localStorage.setItem('authToken', res.jwt);

        this.loginCheck();
        this.spinner.hide();

        this.toster.success(this.displyModel.successMessage);

        this.registrationForm.reset();
        debugger;
        let x = document.getElementById('closeModal');
        x.click();

        if (x) {
          x.click();
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'successful-form-submission',
          'eventCallback': function () {
            console.log('ALL tags which fire on successful-form-submission have now fired');
          }
        });

      },
      (err) => {
        this.spinner.hide();
        this.toster.error(this.displyModel.error);
      }
    );
  };

  password() {
    this.forgetPasswors = true;
  }

  public openMD = () => {
    let y = document.getElementById('openModel');
    if (y) {
      y.click();
      console.log('click');
    }
  };

  public openMD2 = () => {
    let y = document.getElementById('modal2bt');
    if (y) {
      y.click();
      console.log('click');
    }
  };

  onScroll(event) {
    let y = document.getElementById('positionId');
    let rect = y.getBoundingClientRect();
    console.log(rect.top);
    if (rect.top <= 516.515625) {
      this.downWord = false;
      this.upWord = true;
    } else if (rect.top > 516.515625) {
      this.downWord = true;
      this.upWord = false;
    }
  }
  DaftModal() {
    this.SiginUpModal.emit('Clicked');
  }

  scrolldown(el: HTMLElement) {
    this.downWord = false;
    this.upWord = true;
    el.scrollIntoView();
  }

  scrollUp(el: HTMLElement) {
    this.downWord = true;
    this.upWord = false;
    el.scrollIntoView();
  }
  public checkScrollDirectionIsUp(event) {
    console.log(window.scrollY);

    if (event.wheelDelta) {
      return event.wheelDelta > 0;
    }
    return event.deltaY < 0;
  }
  public advisory() {
    window.open('https://minggusahamdigital.com.my/schedule.php#', '_blank');
    /* window.open('https://msd2020.as.me/schedule.php#', '_blank'); */
  }

  public openLink = (data) => {
    window.open(data, '_blank');
  };

  public EnglishObj = {
    loginHader1: 'Get a chance to',
    loginHader2: 'win luxury prizes!!',
    image1: '/assets/imgs/top-banner-en.png',
    image2: '/assets/imgs/syno2.png',
    buttonText: 'REGISTER NOW',
    ModalHeader1: 'Register Now and stand a',
    ModalHeader2: ' chance to win prizes!',
    field1: 'Name',
    field2: 'E-mail',
    field3: 'IC Number',
    field4: 'Mobile Number',
    field5: 'Password',
    bottomText: 'I agree to receive e-mails on promotions',
    bySubmitting:
      '*By submitting this registration form, participant hereby agrees to allow PNB, and PNB reserves the right to collect and process participant’s personal data, if any, for advertising, publicity or research purposes, in accordance with the requirement of the relevant laws, without any cost or compensation.',
    ModalBtn: 'REGISTER',
    LoginBtn: 'Login',
    forget: 'Forgot password',
    info: 'Terms and Conditions (Eh, Mana Kod?)',
    info2: 'Terms and Conditions (Lucky Draw)',
    successMessage: 'Successfully registered',
    error: 'User already exists',
    T_C:
      '/assets/TERMS%20_%20CONDITIONS_%20MANA%20KOD%20-%201910%20-%20final.pdf',
    T_C_2:
      '/assets/TERMS%20_%20CONDITIONS_%20Lucky%20Draw%20-%201910%20-%20final.pdf',
    EmailValidator: 'Email must be a valid email address',
    passwordValidator: 'Password must be at least 6 characters',
    lengthValidator: 'Max 12 characters are Allowed ',
    numberValidator: 'Only Numbers are Allowed ',
  };
  public BMObj = {
    loginHader1: 'Dapatkan peluang untuk',
    loginHader2: 'memenangi hadiah mewah!',
    image1: '/assets/imgs/top-banner-bm.png',
    text1: 'Baucar-Baucar Bernilai RM125,000',
    image2: '/assets/imgs/synopsis.png',
    buttonText: 'DAFTAR SEKARANG',
    ModalHeader1: 'Daftar sekarang untuk ',
    ModalHeader2: 'menyertai pertandingan!',
    field1: 'Nama',
    field2: 'E-mel',
    field3: 'Nombor KP',
    field4: 'Nombor Telefon Mudah Alih',
    field5: 'Kata Laluan',
    bottomText: 'Saya bersetuju untuk menerima e-mel promosi',
    bySubmitting:
      '*Dengan menyerahkan borang pendaftaran ini, peserta dengan ini setuju untuk mengizinkan PNB, dan PNB berhak mengumpulkan dan memproses data peribadi peserta, jika ada, untuk tujuan periklanan, publisiti atau penyelidikan, sesuai dengan kehendak undang-undang yang relevan, tanpa sebarang biaya atau pampasan.',
    ModalBtn: 'DAFTAR',
    forget: 'Lupa kata laluan',
    LoginBtn: 'Log Masuk',
    info: 'Terma & Syarat (Eh, Mana Kod?)',
    info2: 'Terma & Syarat (Cabutan Bertuah)',
    successMessage: 'Pendaftaran berjaya!',
    error: 'Nama pengguna telah diambil.',
    T_C:
      '/assets/TERMA%20_%20SYARAT%20PERADUAN_%20MANA%20KOD%20-%201910%20-%20final.pdf',
    T_C_2:
      '/assets/TERMA%20_%20SYARAT%20PERADUAN_%20Cabutan%20Bertuah%20-%201910%20-%20final.pdf',
    EmailValidator: 'E-mel mestilah e-mel yang sah.',
    passwordValidator: 'Kata laluan perlulah 6 angka atau lebih',
    lengthValidator: 'Tidak melebihi 12 angka',
    numberValidator: 'Hanya angka dibenarkan',
  };
  openPDF() {
    window.open(this.displyModel.T_C, '_blank');
  }
  openPDF2() {
    window.open(this.displyModel.T_C_2, '_blank');
  }

  public restPasswod = () => {
    console.log(this.restPasswordMail.value);
    this.spinner.show();
    this.service.resetPassword(this.restPasswordMail.value).subscribe(
      (res: any) => {
        this.toster.success('Please Check Your Email');
        this.spinner.hide();
        this.restPasswordMail.reset();
        this.forgetPasswors = false;
      },
      (err) => {
        this.toster.success('Error Try Again');
      }
    );
  };
}
// Declare gTM dataLayer array.
declare global {
  interface Window {
    dataLayer: any[];
  }
}
