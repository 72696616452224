import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DataService } from './data.service';
import { ToastrModule } from 'ngx-toastr';
import { ProgramsComponent } from './programs/programs.component';
import { CompetitionComponent } from './competition/competition.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { SubmitCodeComponent } from './submit-code/submit-code.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { PartnersComponent } from './partners/partners.component';
import { FaqsComponent } from './faqs/faqs.component';
import { SimeDarbyComponent } from './sime-darby/sime-darby.component';
import { MayBankPartnerComponent } from './may-bank-partner/may-bank-partner.component';
import { ReferComponent } from './refer/refer.component';
import { PerRegisterComponent } from './per-register/per-register.component';
import { Footer2Component } from './footer2/footer2.component';
import { Home2Component } from './home2/home2.component';
import { Footer3Component } from './footer3/footer3.component';
import { KhidmatComponent } from './khidmat/khidmat.component';
import { nl2brPipe } from './pipe/nl2br.pipe';
import { SafePipe } from './pipe/safe.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TooltipModule } from 'ng2-tooltip-directive';
import { UrlTree, DefaultUrlSerializer, UrlSerializer } from '@angular/router';




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    ProgramsComponent,
    CompetitionComponent,
    SubmitCodeComponent,
    PromotionsComponent,
    PartnersComponent,
    FaqsComponent,
    SimeDarbyComponent,
    MayBankPartnerComponent,
    ReferComponent,
    PerRegisterComponent,
    Footer2Component,
    Home2Component,
    Footer3Component,
    KhidmatComponent,
    nl2brPipe,
    SafePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    NgxSpinnerModule,
    MatTooltipModule,
    TooltipModule,
  ],
  providers: [DataService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
