import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

declare var window: any;

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss'],
})
export class PromotionsComponent implements OnInit {
  imageItems: imageItem[];
  btText_EN: string = 'FIND OUT MORE  ';
  btText_BM: string = 'KETAHUI LEBIH LANJUT';
  btText: string = '';
  public englishMode = false;

  imageItemsENG: imageItem[] = [
    {
      description:
        ' Alif, Paling Arif \n\nAlif, Malaysia’s premier certified sustainable cooking oil, helps bring rich authenticity in flavour to each and every kitchen in Malaysia.\n\nRich in vitamin E (Tocopherol and Tocotrienols) – a natural antioxidant, Alif is trans-fat-free. Superior in quality, Alif by Sime Darby Oils can be reused for frying, for up to 3 times!\n\nIt is also high in monounsaturated fats that promote heart health and lower bad cholesterol. Alif, a farm-to-table cooking oil, is one of the most preferred cooking oil brands in Malaysia!',
      src: '/assets/imgs/alif-promo.png',
      active: 'active',
      url: 'https://www.facebook.com/officialMinyakMasakALIF',
    },
    {
      description:
        ' ATRIÉO TRI.E TOCOTRIENOLS With COVID-19 on the rise, a strong immune system for your body is now more important than ever. NATRIÉO by Sime Darby Oils packs the goodness of Tocotrienols extract, found rich in palm oil. 40 – 60 times more potent in anti-oxidative and anti-inflammatory properties than regular Vitamin E, it helps to increase antibody production and protect you against stroke, cardiovascular disease, dementia as well as fatty liver disease. Boost your immunity today with this Super Vitamin E from the heart of nature!',
      src: '/assets/imgs/natrieo-promo.png',
      url: 'https://www.natrieo.com/',
    },
    {
      description:
        ' Duopharma Biotech has core competencies in the pharmaceutical industry inclusive of Manufacturing, Research & Development and Commercialisation & Marketing of over 300 generic drugs - among others, Omesec and Prelica as well as its Consumer Healthcare (CHC) products such as CHAMPS®, FLAVETTES®, PROVITON® and Uphamol, which are well recognised and accepted by consumers in Malaysia, regionally and globally. The Company has also diversified into the biosimilars space with technology and commercialisation collaborations with credible and strong international partners.',
      src: '/assets/imgs/duopharma-promo.png',
      url: 'http://www.dbhealth2go.com/',
    },
    {
      description:
        ' Sapura Energy Berhad (“Sapura Energy” or the “Group”) is a global integrated oil and gas services and solutions provider operating across the entire upstream value chain. The Group’s spectrum of capabilities cover exploration, development, production, rejuvenation as well as decommissioning and abandonment. With a highly skilled and technically-capable workforce, strategic world-class assets and strong project management capabilities, the Group today delivers its integrated solutions and expertise in over 20 countries. Headquartered in Malaysia, the Group is committed to purposeful, responsible growth while it strives to develop solutions and expand capabilities at the forefront of the energy industry.',
      src: '/assets/imgs/sapura-promo.png',
      url: 'https://www.sapuraenergy.com/',
    },
    {
      description:
        ' Asia School of Business, a partnership between MIT Sloan School of Management and Bank Negara Malaysia, the country’s central bank, can rightly claim to have one of the most innovative MBA programs in the world today” - Poets&Quants.\n\n- Register now for ASB-Iclif Executive Education programs and avail 30% off.\n- Find out more about the ASB MBA at the upcoming Info Session.',
      src: '/assets/imgs/asb-promo.png',
      url: 'https://asb.edu.my/pnb-digital-carnival-with-asb',
    },
    {
      description: ` Diversify your portfolio with ASB investments through RHB Bank's higher financing margins, lower financing rates and more flexible financing periods.\n\nMaximise your ASB investments and grab the chance to win an iPad Mini 4.*\n\n*Subject to terms and conditions`,
      src: '/assets/imgs/rhb-promo.jpeg',
      url:'https://www.rhbgroup.com/asb/index.html?utm_source=MSAM&utm_medium=Banner&utm_campaign=asbfinancing',
    },
    {
      description: ` Attana Hotels & Resorts manages 7 hotels and resorts:

- Perdana Kuala Lumpur City Centre
- Perdana Kota Bharu
- Espira Kinrara
- Espira Sri Petaling
- Villea Port Dickson
- Villea Rompin
- Villea Morib

In conjunction with Minggu Saham Digital 2020, enjoy up to 50% discount for room stay when you book at www.attanahotels.com. Insert this exclusive promo code DCATN20 and enjoy the discount!

This promotion valid from 18 Nov 2020 to 24 Nov 2020.
`,
      src: '/assets/imgs/attana-promo.jpg',
      url:
        'https://www.attanahotels.com',
    },
    {
      description:
        ' Download now and get RM5 bonus as our way of saying welcome! Promo Code "PNBMSD"',
      src: '/assets/imgs/raiz-prom.png',
      url: 'https://www.raiz.com.my/',
    },
{
      description: ` Diversify your investment portfolio with Maybank ASB Financing /-i.  Apply online through Maybank2u wherever you are & enjoy financing rates as low as 3.50% p.a for a minimum financing amount of RM50,000. Plus, grab the chance to get RM20 cash credited into your MAE account. Apply at Maybank2u now. Terms and conditions apply. Campaign Period: 1 November 2020 - 31 December 2020`,
      src: '/assets/imgs/mbb-promo1.png',
      url:
        'https://www.maybank2u.com.my/maybank2u/malaysia/en/personal/promotions/loans/promo_my_asb-financing-i0920.page?utm_source=pnb&utm_medium=affliate&utm_campaign=cnv-rf-mass-MSD-18112020&utm_content=promosi-asbf',
    },    
{
      description: ` What’s better than winning the latest gadgets & cash prizes? Helping each other to win as well! Log in to Maybank2u and register to unlock more prizes. Save consistently each month for a higher chance to win. Spread the news to your family and friends and invite them to save with Maybank now. Terms & conditions apply. Campaign Period: 1 October 2020 - 31 January 2021`,
      src: '/assets/imgs/mbb-promo2.png',
      url:
        'https://www.maybank2u.com.my/maybank2u/malaysia/en/personal/promotions/account_and_banking/promo_my_casa-growth-bm.page?utm_source=affiliate&utm_medium=pnb&utm_campaign=awa-dep-mass-MSD-18112020&utm_content=promosi-casa ',
    },
    {
      description: ` Life's about to get even easier. Say hi to the all-new banking app, MAE by Maybank2u. Discover how the MAE app can sort out your spending, savings, cravings, banking matters and more. Download the all-new MAE app and link your Maybank2u account to enjoy exclusive rewards. As a start, get RM20 credited into your MAE account when you sign up for ASB Financing or Education Financing from now till 30 November 2020.`,
      src: '/assets/imgs/mbb-promo3.png',
      url:
        'https://www.maybank2u.com.my/maybank2u/malaysia/en/personal/services/digital_banking/mae_by_maybank2u.page?utm_source=affiliate&utm_medium=pnb&utm_campaign=awa-m2u-mass-MSD-18112020&utm_content=promosi-maya',
    },
    {
      description: ` Apply for a Maybank / Maybank Islamic Credit Card online via Maybank2u, spend and get rewarded with up to RM280 cash back. Whatever your lifestyle or preference - cash back, TreatsPoints, e-commerce rewards or exclusive privileges, we've got just the card for you! Terms & conditions apply.
Campaign Period: 1 October 2020 - 31 December 2020`,
      src: '/assets/imgs/mbb-promo4.png',
      url:
        'https://www.maybank2u.com.my/maybank2u/malaysia/en/personal/promotions/maybank_cards/promo_my_cardsstp0219.page?utm_source=affiliate&utm_medium=pnb&utm_campaign=cnv-card-stp-mass-MSD-18112020&utm_content=promosi-cc',
    },
    {
      description: ` Stand to win amazing prizes worth RM36,000 when you sign up for selected Personal Accident, Car, Home and Car Value Protection Insurance/Takaful plans. Terms & conditions apply. Campaign ends 31st December 2020.`,
      src: '/assets/imgs/mbb-promo5.png',
    url:'https://www.maybank2u.com.my/maybank2u/malaysia/en/personal/promotions/investment_insurance/promo_my_etiqa-gi0820.page?utm_source=affiliate&utm_medium=pnb&utm_campaign=awa-banca-mass-MSD-18112020&utm_content=promosi-etiqa',
    },
  ];
  imageItemsBM: imageItem[] = [
    {
      description:
        ' Alif, Paling Arif\n\nAlif, Minyak masak tempatan pertama diperakui mampan, memperkaya rasa asli masakan di setiap dapur di Malaysia.\n\nSarat dengan antioksidan semulajadi vitamin E serta bebas lemak trans, Alif keluaran Sime Darby Oils berkualiti unggul dan sesuai diguna semula, sehingga 3 kali gorengan!\n\nAlif juga kaya dengan asid lemak mono tak tepu yang bermanfaat kepada kesihatan jantung serta pengurangan kadar kolesterol tidak baik. Beridentiti ‘Ladang ke Meja’, Alif adalah antara jenama utama pilihan pengguna Malaysia!',
      src: '/assets/imgs/alif-promo.png',
      active: 'active',
      url: 'https://www.facebook.com/officialMinyakMasakALIF',
    },
    {
      description:
        ' TOCOTRIENOL NATRIÉO TRI. ESistem keimunan tubuh yang kuat kini lebih penting dengan peningkatan COVID-19. NATRIÉO, oleh Sime Darby Oils, sarat dengan esktrak Tocotrieonols daripada minyak sawit, menawarkan kebaikan antioksidan dan antiradang, 40 – 60 kali lebih efektif berbanding vitamin E biasa. Ia membantu meningkatkan pengeluaran antibodi dan melindungi anda daripada strok, penyakit kardiovaskular, demensia serta penyakit hati berlemak. Kuatkan keimunan anda dengan Vitamin E luar biasa daripada sumber alami!',
      src: '/assets/imgs/natrieo-promo.png',
      url: 'https://www.natrieo.com/',
    },
    {
      description:
        ' Sebagai sebuah Syarikat di dalam industri farmaseutikal, Duopharma Biotech mempunyai pelbagai cabang aktiviti perniagaan termasuk Pembuatan, Penyelidikan & Pembangunan serta Komersial & Pemasaran untuk lebih dari 300 ubat generik - antaranya, Omesec dan Prelica serta produk Penjagaan Kesihatan Pengguna (CHC) seperti CHAMPS®, FLAVETTES®, PROVITON® dan Uphamol yang menjadi pilihan pengguna di Malaysia, negara-negara serantau dan antarabangsa. Syarikat juga menawarkan produk biosimilar dengan kerjasama teknologi dan komersial dengan rakan perniagaan antarabangsa yang mempunyai kepakaran dalam bidang tersebut.',
      src: '/assets/imgs/duopharma-promo.png',
      url: 'http://www.dbhealth2go.com/',
    },
    {
      description:
        ' Sapura Energy Berhad merupakan syarikat perkhidmatan dan penyelesaian minyak dan gas bersepadu yang beroperasi di peringkat antarabangsa. Keupayaan kami merangkumi seluruh rantaian nilai huluan minyak dan gas termasuk penerokaan, pembinaan, pengeluaran, rejuvenasi serta kegiatan pasca operasi, khususnya di pesisir pantai. Berbekalkan sumber insan yang berkemampuan tinggi dan cekap dari segi teknikal, aset strategik yang bertaraf dunia, serta kepakaran dalam pengurusan projek, Sapura Energy telah memperluaskan khidmat penyelesaian dan kepakaran bersepadunya kepada lebih 20 negara.',
      src: '/assets/imgs/sapura-promo.png',
      url: 'https://www.sapuraenergy.com/',
    },
    {
      description:
        ' ASB kerjasama antara Sekolah Pengurusan MIT Sloan dan Bank Negara, iaitu Bank Pusat Malaysia, berhak membuat tuntutan sebagai satu di antara peneraju program MBA yang paling inovatif zaman kini.” - Poets&Quants.\n\n- Ketahuilah dengan lebih lanjut mengenai program MBA ASB di Sesi Info yang akan datang.\n- Daftarlah sekarang untuk Program Pendidikan Eksekutif ASB-Iclif dan nikmati potongan 30%.',
      src: '/assets/imgs/asb-promo.png',
      url: 'https://asb.edu.my/pnb-digital-carnival-with-asb',
    },
    {
      description:
        ' Pelbagaikan portfolio anda dengan pelaburan ASB menerusi margin pembiayaan yang lebih tinggi, dengan kadar pembiayaan yang lebih rendah dan tempoh pembiayaan yang lebih fleksibel dengan RHB Bank.\n\nMaksimakan pelaburan ASB anda dan rebut peluang untuk memenagi iPad Mini 4*\n\n*Tertakluk pada Terma & Syarat.',
      src: '/assets/imgs/rhb-promo.jpeg',
      url:
        'https://www.rhbgroup.com/asb/index.html?utm_source=MSAM&utm_medium=Banner&utm_campaign=asbfinancing',
    },
    {
      description: ` Attana Hotels & Resorts menguruskan tujuh hotel dan resort:

- Perdana Kuala Lumpur City Centre
- Perdana Kota Bharu
- Espira Kinrara
- Espira Sri Petaling
- Villea Port Dickson
- Villea Rompin
- Villea Morib

Nikmati diskaun bernilai sehingga 50% untuk penginapan apabila anda membuat tempahan di www.attanahotels.com. Sila guna kod promosi DCATN20 dan nikmati diskaun!

Promosi ini sah dari 18 Nov 2020 sehingga 24 Nov 2020.`,
      src: '/assets/imgs/attana-promo.jpg',
      url:
        'https://www.attanahotels.com',
    },
    {
      description:
        ' Muat turun skrg dan dapat bonus RM5 sebagai tanda "selamat datang"! Kod promo “PNBMSD”',
      src: '/assets/imgs/raiz-prom.png',
      url: 'https://www.raiz.com.my/',
    },    
{
      description: ` Pelbagaikan portfolio pelaburan anda dengan Pembiayaan ASB/-i Maybank. Mohon di mana jua anda berada secara dalam talian & nikmati kadar pembiayaan serendah 3.50% setahun untuk jumlah pembiayaan minimum RM50,000. Rebut juga peluang untuk mendapatkan RM20 tunai dikreditkan ke dalam akaun MAE anda. Mohon di Maybank2u sekarang. Tertakluk pada terma. Tempoh Kempen: 1 November 2020 - 31 Disember 2020.`,
      src: '/assets/imgs/mbb-promo1.png',
      url:
        'https://www.maybank2u.com.my/maybank2u/malaysia/en/personal/promotions/loans/promo_my_asb-financing-i0920.page?utm_source=pnb&utm_medium=affliate&utm_campaign=cnv-rf-mass-MSD-18112020&utm_content=promosi-asbf',
    },
{
      description: ` Apa yang lebih baik daripada memenangi gajet terkini & ganjaran tunai? Membantu antara satu sama lain untuk menang juga! Log masuk ke Maybank2u, daftar dan simpan setiap bulan untuk peluang memenangi hadiah lumayan. Jemput keluarga dan rakan anda untuk menyimpan dengan Maybank dan menang bersama. Tertakluk pada terma. Tempoh Kempen: 1 Oktober 2020 - 31 Januari 2021`,
      src: '/assets/imgs/mbb-promo2.png',
      url:
        'https://www.maybank2u.com.my/maybank2u/malaysia/en/personal/promotions/account_and_banking/promo_my_casa-growth-bm.page?utm_source=affiliate&utm_medium=pnb&utm_campaign=awa-dep-mass-MSD-18112020&utm_content=promosi-casa',
    },
    {
      description: ` Hidup anda akan menjadi lebih mudah. Memperkenalkan aplikasi perbankan serba baru, MAE oleh Maybank2u. Ketahui bagaimana aplikasi MAE dapat menguruskan perbelanjaan, simpanan, keinginan, urusan perbankan anda & banyak lagi. Muat turun aplikasi MAE, pautkan akaun Maybank2u anda & nikmati pelbagai hadiah eksklusif. Untuk permulaan, dapatkan RM20 tunai apabila anda memohon Pembiayaan ASB atau Pembiayaan Pendidikan Maybank dari sekarang hingga 30 Nov 2020.`,
      src: '/assets/imgs/mbb-promo3.png',
      url:
        'https://www.maybank2u.com.my/maybank2u/malaysia/en/personal/services/digital_banking/mae_by_maybank2u.page?utm_source=affiliate&utm_medium=pnb&utm_campaign=awa-m2u-mass-MSD-18112020&utm_content=promosi-maya',
    },
    {
      description: ` Mohon Kad Kredit Maybank/Maybank Islamic secara dalam talian melalui Maybank2u, belanja dan dapatkan pulangan wang tunai sehingga RM280. Apa sahaja gaya hidup atau pilihan anda - pulangan tunai, mata ganjaran TreatsPoints, ganjaran e-dagang atau tawaran eksklusif, kami mempunyai kad yang sesuai untuk anda! Tertakluk pada terma & syarat. Tempoh Kempen: 1 Oktober 2020 - 31 Disember 2020`,
      src: '/assets/imgs/mbb-promo4.png',
      url:
        'https://www.maybank2u.com.my/maybank2u/malaysia/en/personal/promotions/maybank_cards/promo_my_cardsstp0219.page?utm_source=affiliate&utm_medium=pnb&utm_campaign=cnv-card-stp-mass-MSD-18112020&utm_content=promosi-cc',
    },
    {
      description: ` Berpeluang untuk memenangi hadiah lumayan bernilai RM36,000 apabila anda mendaftar untuk pelan Insurans/Takaful yang terpilih seperti  Kemalangan Diri, Kereta, Rumah dan Perlindungan Nilai Kereta terpilih bersama kami. Tertakluk pada terma. Kempen tamat pada 31 December 2020.`,
      src: '/assets/imgs/mbb-promo5.png',
      url:
        'https://www.maybank2u.com.my/maybank2u/malaysia/en/personal/promotions/investment_insurance/promo_my_etiqa-gi0820.page?utm_source=affiliate&utm_medium=pnb&utm_campaign=awa-banca-mass-MSD-18112020&utm_content=promosi-etiqa',
    },
  ];

  constructor(private service: DataService) { }

  ngOnInit(): void {
    this.service.language.subscribe((lang) => {
      if (lang == 'ENG') {
        this.imageItems = this.imageItemsENG;
        this.englishMode = true;
        this.btText = this.btText_EN;
      } else {
        this.imageItems = this.imageItemsBM;
        this.englishMode = false;
        this.btText = this.btText_BM;
      }
    });
  }
  public languageChange = (data: any) => {

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Language Switch',
      'eventCallback': function () {
        console.log('Language Switch Event Tags Triggered and Fired');
      }
    });

    if (data == 'ENG') {
      this.service.language.next('ENG');
      this.imageItems = this.imageItemsENG;
      this.englishMode = true;
      this.btText = this.btText_EN;
    } else if (data == 'BM') {
      this.imageItems = this.imageItemsBM;
      this.service.language.next('BM');
      this.englishMode = false;
      this.btText = this.btText_BM;
    }
  };
public newtab = (data:any) => {
  console.log(data);
  window.open(data, "_blank");
}

}
interface imageItem {
  description: string;
  src: string;
  url: string;
  active?: string;
}
