<app-header></app-header>

<div class="row  mainCont">
  <div class="col-sm-9 px-0">
    <div class="row justify-content-between">
      <div class="col-sm-6">
        <img src="/assets/imgs/ipad.png" width="95%">
      </div>
      <div class="col-sm-6 align-center">
      <form [formGroup]='kotForm'>
      <div  formArrayName='kot'>
        <h1 class="CustFont">eh, dah dapat kod?</h1>
        <div class="form-group"  *ngFor='let data of f.controls; let i=index' [formGroupName]='i'>
          <label>Kod  {{i+1}}</label>
          <input (change)='change(i)' formControlName='kotValue' type="text" class="form-control" > 
          <p id="err{{i}}" style="color: red;padding: 0.4rem"></p>
          <p class="text-white text-right mb-0 pointer pt-3" *ngIf='i==lastField'>
            <a (click)='addfield()'> <i class="fas fa-plus mr-1"></i><a >Add</a></a>
           
          </p>
          <p class="text-white text-right mb-0 pointer pt-3" *ngIf='i<lastField'>
            <a (click)='remove(i)'> <i class="fas fa-times pr-2"></i><a >Remove</a></a>
         
          </p>
        </div>
        <!-- <div class="form-group">
          <label>Kot 2</label>
          <input type="text" class="form-control">
          <div class="text-right pt-3"><button class="btn addMoreBtn">Add More Code</button></div>
        </div> -->
        <button class="btn yellowBtn" [disabled]='kotForm.invalid ||isLogin'  id="subBtn" (click)='submit()'>Hantar</button>
       
      </div>
  
    </form>
  </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
