<div class="footer">
    <div class="text-center">
      <a href="https://www.facebook.com/minggusahamdigital" target="_blank">
          <img src="/assets/imgs/Asset 4.svg"height="35px">
      </a>
      <a href="https://www.instagram.com/minggusahamdigital/" target="_blank">
          <img src="/assets/imgs/Asset 1.svg" height="30px" class="mx-3">
      </a>
      <a href="https://www.youtube.com/channel/UCoNCjruylHYpwBYkIvRwClA/" target="_blank">
          <img src="/assets/imgs/Asset 5.svg" height="28px">
      </a>

  
      <p class="mt-3 font-13">Permodalan Nasional Berhad © 2020</p>
    </div>
  </div>
  