import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProgramsComponent } from './programs/programs.component';
import { CompetitionComponent } from './competition/competition.component';
import { SubmitCodeComponent } from './submit-code/submit-code.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { PartnersComponent } from './partners/partners.component';
import { FaqsComponent } from './faqs/faqs.component';
import { SimeDarbyComponent } from './sime-darby/sime-darby.component';
import { MayBankPartnerComponent } from './may-bank-partner/may-bank-partner.component';
import { ReferComponent } from './refer/refer.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CallInterceptor } from './call.interceptor'

import { PerRegisterComponent } from './per-register/per-register.component';
import { Home2Component } from './home2/home2.component';
import { KhidmatComponent } from './khidmat/khidmat.component';


const routes: Routes = [
  { path: 'Sample_home', component: HomeComponent },
  { path: '', component: Home2Component },
  { path: 'programs', component: ProgramsComponent },
  { path: 'competitions', component: CompetitionComponent },
  { path: 'submit-code', component: SubmitCodeComponent },
  { path: 'promotions', component: PromotionsComponent },
  { path: 'partners', component: PartnersComponent },
  { path: 'faqs', component: FaqsComponent },
  { path: 'partnersDetails', component: SimeDarbyComponent },
  { path: 'may-bank-partner', component: MayBankPartnerComponent },
  { path: 'qr', component: Home2Component },
  { path: 'khidmat', component: KhidmatComponent },
  { path: 'pre-register', component: PerRegisterComponent },
  { path: '', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: CallInterceptor,
    multi: true
  }]
})
export class AppRoutingModule { }
