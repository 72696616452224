<app-header></app-header>
<div class="languageCont">
  <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
    BM
  </h6>
  <h6 class="mx-2">|</h6>
  <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
</div>
<div class="row mainCont">
  <div class="col-sm-10 px-0">
    <div class="partnersCont">
      <img (click)="toPartner('PNB')" src="/assets/pnb_partners/pnb.png" />
      <img (click)="toPartner('ASNB')" src="/assets/pnb_partners/asnb.png" />
    </div>
    <div class="partnersCont">
      <h3 class="text-center mt-5">
        <a style="font-weight: lighter !important">{{ language.header1 }}</a>
      </h3>
      <img (click)="toPartner('MayBank')" src="/assets/pnb_partners/maybank.png" />
      <img (click)="toPartner('Plantation')" src="/assets/pnb_partners/sime plant.png" />
      <img (click)="toPartner('UMW')" src="/assets/imgs/partner6.png" />
    </div>
    <div class="partnersCont">
      <h3 class="text-center mt-5">
        <a style="font-weight: lighter !important">{{ language.header2 }}</a>
      </h3>

      <img (click)="toPartner('sime_plant')" src="assets/pnb_partners/sime darby.png" />
      <img (click)='toPartner("SimeDarbyProperty")' src="assets/pnb_partners/sime prop.png"  />
      <img (click)="toPartner('setia')" src="/assets/imgs/partner9.png" />
      <img (click)="toPartner('MIDF')" src="/assets/imgs/partner10.png" />
      <img (click)="toPartner('perodua')" src="assets/pnb_partners/perodua.png" />
    </div>

    <div class="partnersCont">
      <h3 class="text-center mt-5">{{ language.header3 }}</h3>
      <img (click)="toPartner('MNRB')" src="/assets/imgs/partner11.png" />
      <img (click)="toPartner('CCM')" src="assets/pnb_partners/ccm.png" />
      <img (click)="toPartner('Duophrma')" src="/assets/pnb_partners/duopharma.png" />
      <img (click)="toPartner('velesto')" src="/assets/imgs/partner2.png" />
      <img (click)="toPartner('sapura')" src="/assets/imgs/partner4.png" />
      <img (click)="toPartner('Prolintas')" src="/assets/imgs/partner8.png" />
    </div>

    <div class="partnersCont">
      <h3 class="text-center mt-5">{{ language.header4 }}</h3>

      <img (click)="toPartner('AMBank')" src="/assets/pnb_partners/am bank.png" />
      <img (click)="toPartner('bankIslam')" src="/assets/pnb_partners/bank islam.png" />
      <img (click)="toPartner('CIMB')" src="/assets/imgs/partner14.png" />
      <img (click)="toPartner('RHB')" src="/assets/imgs/partner15.png" />
    </div>

    <div class="partnersCont">
      <h3 class="text-center mt-5">{{ language.header5 }}</h3>
      <img (click)="toPartner('ASB')" src="/assets/pnb_partners/asb.png" />
      <img (click)="toPartner('attana')" src="/assets/imgs/partner13.png" />
      <img (click)="toPartner('jdv')" src="assets/pnb_partners/jdv 1.png" />

      <img (click)="toPartner('merdeka')" src="/assets/pnb_partners/pnb merdeka ventures 1.png" />
      <img (click)="toPartner('raiz')" src="/assets/imgs/partner7.png" />
    </div>
    <!--
   <div class="partnersCont">
      <h3 class="text-center mt-5"><a style="font-weight: lighter !important;">RAKAN</a> PREMIER</h3>
        <img routerLink="/may-bank-partner" src="/assets/imgs/partner1.png">
        <img src="/assets/imgs/partner2.png">
        <img routerLink="/sime-darby" src="/assets/imgs/partner3.png">
        <img src="/assets/imgs/partner4.png">
        <img src="/assets/imgs/partner5.png">
        <img src="/assets/imgs/partner6.png">
        <img src="/assets/imgs/partner7.png">
        <img src="/assets/imgs/partner8.png">
        <img src="/assets/imgs/partner9.png">
        <img src="/assets/imgs/partner10.png">
        <img src="/assets/imgs/partner11.png">
        <img src="/assets/imgs/partner12.png">
        <img src="/assets/imgs/partner13.png">
        <img src="/assets/imgs/partner14.png">
        <img src="/assets/imgs/partner15.png">
    </div>  -->
  </div>
</div>

<app-footer></app-footer>