import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { HeaderComponent } from '../header/header.component';


declare var window: any;


@Component({
  selector: 'app-competition',
  templateUrl: './competition.component.html',
  styleUrls: ['./competition.component.scss'],
})
export class CompetitionComponent implements OnInit {
  constructor(public router: Router, public service: DataService) { }
  @ViewChild(HeaderComponent) header: HeaderComponent;
  ngOnInit(): void {
    this.service.language.subscribe((lang) => {
      this.englishMode = lang == 'ENG' ? true : false;
      if (this.englishMode) {
        this.dis_Obj1 = this.competition1.EN;
        this.dis_Obj2 = this.competition2.EN;
        this.dis_Obj3 = this.competition3.EN;
        this.dis_Obj4 = this.competition4.EN;
        this.dis_Obj5 = this.competition5.EN;
        this.mainObj = this.outerObj_EN;
      } else {
        this.dis_Obj1 = this.competition1.BM;
        this.dis_Obj2 = this.competition2.BM;
        this.dis_Obj3 = this.competition3.BM;
        this.dis_Obj4 = this.competition4.BM;
        this.dis_Obj5 = this.competition5.BM;
        this.mainObj = this.outerObj_BM;
      }
    });
  }
  toSubmit = () => {
    this.router.navigate(['/submit-code']);
  };
  public competition1 = {
    BM: {
      img: '/assets/imgs/competitionImg1.png',
      btn: 'EH, DAH DAPAT KOD?',
      T_C: 'Terma & Syarat',
      pdf:
        '/assets/TERMA%20_%20SYARAT%20PERADUAN_%20MANA%20KOD%20-%201910%20-%20final.pdf',
      heading1: {
        title: 'Langkah #1',
        text: `Pastikan anda sudah berdaftar. Jika belum, <u class="pointer">daftar sekarang!</u>`,
      },
      heading2: {
        title: 'Langkah #2',
        text: `Saksikan siaran langsung kami di <a class="linkTxt" target="_blank" href="https://www.facebook.com/minggusahamdigital">Facebook</a> & <a class="linkTxt" target="_blank" href="https://www.youtube.com/channel/UCoNCjruylHYpwBYkIvRwClA/">Youtube</a> Minggu
        Saham Digital. Satu kod akan tersembunyi dalam setiap rancangan.`,
      },
      heading3: {
        title: 'Langkah #3',
        text: `Lawati halaman web <u>rakan korporat</u> kami. Satu kod akan tersembunyi dalam
        setiap halaman rakan korporat.`,
      },
      heading4: {
        title: 'Langkah #4',
        text: `Klik butang di bawah dan masukkan semua kod yang anda kumpul - satu kod
        adalah satu peluang untuk menang!`,
      },
      heading5: {
        title: 'Langkah #5',
        text: `Saksikan pengumuman cabutan bertuah kami pada
        10 Disember 2020 di halaman <a class="linkTxt" target="_blank" href="https://www.facebook.com/minggusahamdigital">Facebook</a> & <a class="linkTxt" target="_blank" href="https://www.youtube.com/channel/UCoNCjruylHYpwBYkIvRwClA/">Youtube</a> kami.`,
      },
    },
    EN: {
      img: '/assets/competition_imgs_English/competitionImg1_eng.png',
      btn: 'EH, GOT THE CODES?',
      T_C: 'Terms & Conditions',
      pdf:
        '/assets/TERMS%20_%20CONDITIONS_%20MANA%20KOD%20-%201910%20-%20final.pdf',
      heading1: {
        title: 'Step #1',
        text: `<u>Register Now</u> on our website.`,
      },
      heading2: {
        title: 'step #2',
        text: `Watch our livestream on our official <a class="linkTxt" target="_blank" href="https://www.facebook.com/minggusahamdigital">Facebook page</a> & <a class="linkTxt" target="_blank" href="https://www.youtube.com/channel/UCoNCjruylHYpwBYkIvRwClA/">YouTube channel</a> pages. <b>One code</b> will be revealed in each show.`,
      },
      heading3: {
        title: 'step #3',
        text: `Visit our <u>Corporate Partners' pages</u>. One code will be hidden in each partner's page.`,
      },
      heading4: {
        title: 'step #4',
        text: `Click the button below and enter all your collected codes. One code counts as one entry.`,
      },
      heading5: {
        title: 'step #5',
        text: `Watch our lucky draw announcement on
       10 December 2020 on our official <a class="linkTxt" target="_blank" href="https://www.facebook.com/minggusahamdigital">Facebook page</a> & <a class="linkTxt" target="_blank" href="https://www.youtube.com/channel/UCoNCjruylHYpwBYkIvRwClA/">YouTube channel</a> pages.`,
      },
    },
  };
  public competition2 = {
    BM: {
      img: 'assets/imgs/competitionImg2.png',
      text: `Saksikan konsert siaran langsung "Pass the Mic" di <a class="linkTxt" target="_blank" href="https://www.facebook.com/minggusahamdigital">Facebook</a> kami untuk pengumuman pertandingan
      mengejutkan!`,
      T_C: 'Terma & Syarat',
      pdf: '/assets//TERMSCONDITIONS-PDF/BM-PASSTHEMIC.pdf',
    },
    EN: {
      img: 'assets/competition_imgs_English/competitionImg2_eng.png',
      text: `Watch our livestream "Pass the Mic" concert on our offical <a class="linkTxt" target="_blank" href="https://www.facebook.com/minggusahamdigital">Facebook</a> page for a surprise competition!
      `,
      T_C: 'Terms & Conditions',
      pdf: '/assets//TERMSCONDITIONS-PDF/EN-PASSTHEMIC.pdf',
    },
  };
  public competition3 = {
    BM: {
      img: '/assets/imgs/competitionImg3.png',
      btn: `DAFTAR SEKARANG`,
      T_C: 'Terma & Syarat',
      pdf: '/assets//TERMSCONDITIONS-PDF/TNS-CABUTANBERTUAH.pdf',

      header1: {
        heading1: 'Langkah #1',
        text: `<u>Pra-daftar sekarang!</u>`,
      },
      header2: {
        heading1: 'Langkah #2',
        text: ` Kami akan mengumumkan seramai 2,500 pemenang selama 6 hari melalui e-mel dari 18 Nov hingga 23 Nov 2020`,
      },
    },
    EN: {
      img: '/assets/competition_imgs_English/competitionImg3_eng.png',
      T_C: 'Terms & Conditions',
      pdf: '/assets//TERMSCONDITIONS-PDF/TNC-LUCKYDRAW.pdf',

      btn: `PRE-REGISTER NOW`,
      header1: {
        heading1: 'Step #1',
        text: `<u>Pre-register on our website now!</u>`,
      },
      header2: {
        heading1: 'Step #2',
        text: `We will announce 2,500 winners over 6 days via email from 18 to 23 Nov 2020.`,
      },
    },
  };
  public competition4 = {
    BM: {
      img: '/assets/imgs/competitionImg4.png',
      Info: `Sertailah Live Trivia & Live Trivia Bonanza yang berlangsung 24 kali dari 18 hingga
      24 Nov 2020 di siaran langsung Laman Facebook rasmi Minggu Saham Digital. Lihatlah <u>jadual</u> kami
      untuk
      waktu-waktu rancangan ini!`,
      T_C: 'Terma & Syarat',
      pdf: '/assets//TERMSCONDITIONS-PDF/BM-LiveTrivia.pdf',

      heading1: {
        title: `Langkah #1`,
        text: `Saksikan siaran langsungnya di <a class="linkTxt" target="_blank" href="https://www.facebook.com/minggusahamdigital">Facebook</a> pada waktu-waktu
        tertentu.`,
      },
      heading2: {
        title: `Langkah #2`,
        text: `Jawab soalan-soalan trivia melalui bahagian komen siaran langsung.`,
      },
      heading3: {
        title: `Langkah #3`,
        text: ` Pada setiap sesi Live Trivia, sebanyak 10 peserta dengan markah yang terbanyak akan menang!.`,
      },
      heading4: {
        title: `Langkah #4`,
        text: ` Pasukan kami akan menghubungi semua pemenang melalui Facebook Messenger untuk penebusan hadiah dalam
        masa 48 jam dari sesi yang disertai.`,
      },
    },
    EN: {
      img: '/assets/competition_imgs_English/competitionImg4_eng.png',
      Info: `Join our Live Trivia & Live Trivia Bonanza happening 23 times from 18 to 23 Nov 2020. See our <u>Schedule</u> for the show times.`,
      T_C: 'Terms & Conditions',
      pdf: '/assets//TERMSCONDITIONS-PDF/EN-LIVETRIVIA.pdf',

      heading1: {
        title: `Step #1`,
        text: `Catch the livestream on our <a class="linkTxt" target="_blank" href="https://www.facebook.com/minggusahamdigital">Facebook page</a> at the scheduled show times.`,
      },
      heading2: {
        title: `Step #2`,
        text: `Answer the trivia questions via the livestream comment section.`,
      },
      heading3: {
        title: `Step #3`,
        text: `Prizes will be awarded to the top 10 scorers for every session.`,
      },
      heading4: {
        title: `Step #4`,
        text: `Our team will get in touch with all the winners via our FB messenger for prize redemption within 48 hours of the Trivia session.`,
      },
    },
  };
  public competition5 = {
    BM: {
      img: '/assets/imgs/competitionImg5.png',
      Info: 'Reka pelitup muka rasmi Minggu Saham Digital 2020!',
      T_C: 'Terma & Syarat',
      pdf: '/assets//TERMSCONDITIONS-PDF/TNS-LAKARANJUARA.pdf',
      heading1: {
        title: 'Langkah #1',
        text: `Lawati halaman <u>Instagram</u> kami @minggusahamdigital`,
      },
      heading2: {
        title: 'Langkah #2',
        text: `Di Instagram, lihat Highlights #LakaranJuara kami untuk mengetahui lebih lanjut.`,
      },
      heading3: {
        title: 'Langkah #3',
        text: `Kongsikan rekaan pelitup muka anda di Instagram dengan hashtag #lakaranjuara dan #laburbersama.`,
      },
      heading4: {
        title: 'Langkah #4',
        text: `  Pasukan kami akan menghubungi juara #LakaranJuara melalui Instagram pada 2 Nov 2020 untuk penebusan hadiah.`,
      },
    },
    EN: {
      img: 'assets/competition_imgs_English/competitionImg5_eng.png',
      Info: 'Design the official mask for Minggu Saham Digital 2020!',
      T_C: 'Terms & Conditions',
      pdf: '/assets//TERMSCONDITIONS-PDF/TNC-LAKARANJUARA.pdf',

      heading1: {
        title: 'Step #1',
        text: `Visit our <u>Instagram</u> page @minggusahamdigital.`,
      },
      heading2: {
        title: 'Step #2',
        text: `Watch our #LakaranJuara Highlights for more details.`,
      },
      heading3: {
        title: 'Step #3',
        text: `Post your creative designs to Instagram with the hashtags #lakaranjuara and #laburbersama.`,
      },
      heading4: {
        title: 'Step #4',
        text: `We will contact the winner via Instagram on 2 Nov 2020 for the prize redemption.`,
      },
    },
  };

  public outerObj_BM = {
    btn1: 'cabutan  bertuah',
    title: 'pertandingan',
  };
  public outerObj_EN = {
    btn1: 'LUCKY DRAW',
    title: 'CONTESTS',
  };
  public englishMode = false;

  public dis_Obj1 = this.competition1.BM;
  public dis_Obj2 = this.competition2.BM;
  public dis_Obj3 = this.competition3.BM;
  public dis_Obj4 = this.competition4.BM;
  public dis_Obj5 = this.competition5.BM;
  public mainObj = this.outerObj_BM;

  public languageChange = (data) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Language Switch',
      'eventCallback': function () {
        console.log('Language Switch Event Tags Triggered and Fired');
      }
    });

    if (data == 'ENG') {
      this.englishMode = true;
      this.service.language.next('ENG');
      this.mainObj = this.outerObj_EN;
      this.dis_Obj1 = this.competition1.EN;
      this.dis_Obj2 = this.competition2.EN;
      this.dis_Obj3 = this.competition3.EN;
      this.dis_Obj4 = this.competition4.EN;
      this.dis_Obj5 = this.competition5.EN;
    } else if (data == 'BM') {
      this.englishMode = false;
      this.service.language.next('BM');
      this.mainObj = this.outerObj_BM;
      this.dis_Obj1 = this.competition1.BM;
      this.dis_Obj2 = this.competition2.BM;
      this.dis_Obj3 = this.competition3.BM;
      this.dis_Obj4 = this.competition4.BM;
      this.dis_Obj5 = this.competition5.BM;
    }
  };

  openPDF() {
    window.open(this.dis_Obj1.pdf, '_blank');
  }
  openPDF2() {
    window.open(this.dis_Obj2.pdf, '_blank');
  }
  openPDF3() {
    window.open(this.dis_Obj3.pdf, '_blank');
  }
  openPDF4() {
    window.open(this.dis_Obj4.pdf, '_blank');
  }
  openPDF5() {
    window.open(this.dis_Obj5.pdf, '_blank');
  }
  public MOdal = () => {
    this.header.openMD2();
  };
}
