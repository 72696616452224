<app-header></app-header>
<div class="languageCont">
  <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
    BM
  </h6>
  <h6 class="mx-2">|</h6>
  <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
</div>
<div class="row mainCont">
  <div class="col-sm-6 px-0">
    <ng-container *ngIf="!englishMode">
      <h1 class="CustFont text-center">Soalan Lazim</h1>
      <!-- <div class="row faqsCont">
      <div class="col-sm-5">
        <div class="questCont">
          <h5 class="mb-3 CustFont">1. Lorem Ipsum</h5>
          <p class="mb-0 font-17">Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et
            dolore magna aliqua. Ut enim ad minim veniam.</p>
        </div>
        <div class="questCont">
          <h5 class="mb-3 CustFont">2. Lorem Ipsum</h5>
          <p class="mb-0 font-17">Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et
            dolore magna aliqua. Ut enim ad minim veniam.</p>
        </div>
        <div class="questCont">
          <h5 class="mb-3 CustFont">3. Lorem Ipsum</h5>
          <p class="mb-0 font-17">Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et
            dolore magna aliqua. Ut enim ad minim veniam.</p>
        </div>
      </div>
      <div class="col-sm-1">
        <div class="borderCont"></div>
      </div>
      <div class="col-sm-5">
        <div class="questCont">
          <h5 class="mb-3 CustFont">4. Lorem Ipsum</h5>
          <p class="mb-0 font-17">Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et
            dolore magna aliqua. Ut enim ad minim veniam.</p>
        </div>
        <div class="questCont">
          <h5 class="mb-3 CustFont">5. Lorem Ipsum</h5>
          <p class="mb-0 font-17">Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et
            dolore magna aliqua. Ut enim ad minim veniam.</p>
        </div>
        <div class="questCont">
          <h5 class="mb-3 CustFont">6. Lorem Ipsum</h5>
          <p class="mb-0 font-17">Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et
            dolore magna aliqua. Ut enim ad minim veniam.</p>
        </div>
      </div>
    </div> -->
      <h2 class="heading">Minggu saham digital</h2>
      <p class="quest">Apa itu Minggu Saham Digital?</p>
      <p class="answer">
        Dianjurkan oleh Permodalan Nasional Berhad (PNB), Minggu Saham Digital adalah acara strim langsung selama 7 hari yang disiarkan menerusi laman Facebook dan YouTube. Dengan bertemakan #LaburBersama, karnival digital ini bertujuan untuk mempamerkan pelaburan-pelaburan strategik PNB dan menambah pengetahuan rakyat Malaysia dari semua peringkat usia, tentang kepentingan pelaburan dan perancangan kewangan, diselangi dengan hiburan. Sepanjang acara digital ini, penonton dapat menyaksikan pelbagai forum, siri webinar, eksperimen sains yang unik yang serba informatif, dan segmen-segmen hiburan menerusi konsert maya hingga ke rancangan menarik seperti bual bicara, game show, sesi memasak, dan sebagainya.
      </p>
      <p class="quest">Bila dan di manakah Minggu Saham Digital diadakan?</p>
      <p class="answer">
        Minggu Saham Digital disiarkan secara langsung melalui laman Facebook dan YouTube @MingguSahamDigital setiap hari, dari 18 November hingga 24 November 2020 antara pukul 12.30 tengah hari hingga 10.30 malam.
      </p>
      <p class="quest">Siapa yang boleh menghadiri Minggu Saham Digital?</p>
      <p class="answer">
        Minggu Saham Digital terbuka kepada semua yang mempunyai akses kepada
        Facebook dan Youtube.
      </p>
      <p class="quest">Adakah Minggu Saham Digital percuma untuk ditonton?</p>
      <p class="answer">
        Ya. Minggu Saham Digital adalah percuma untuk ditonton.
      </p>
      <p class="quest">
        Rancangan apa yang boleh dijangkakan sepanjang Minggu Saham Digital?
      </p>
      <p class="answer">
        Penonton akan dihiburkan oleh para selebriti menerusi rancangan seperti
        konsert maya, program bual bicara, game show, pertunjukan memasak,
        eksperimen sains, sambil berpeluang mengikut penceramah antarabangsa dan
        pakar industri dalam pelbagai forum dan siri webinar yang informatif dan
        bernas.
      </p>
      <p class="quest">
        Di manakah jadual penuh program Minggu Saham Digital boleh didapati?
      </p>
      <p class="answer">
        Jadual penuh disiarkan di saluran media sosial Minggu Saham Digital dan juga laman web rasmi Minggu Saham Digital. Program Minggu Saham Digital berlangsung selama tujuh hari berturut-turut dengan lebih daripada 9 jam rancangan siaran langsung setiap hari dari jam 12.30 tengah hari hingga 10.30 malam.
      </p>
      <p class="quest">
        Siapakah tetamu atau penceramah yang akan muncul di Minggu Saham
        Digital?
      </p>
      <p class="answer">
        Senarai penuh penceramah dan tetamu disiarkan di saluran media sosial Minggu Saham Digital dan juga laman web rasmi Minggu Saham Digital.
      </p>
      <p class="quest">Siapakah rakan korporat Minggu Saham Digital 2020?</p>
      <p class="answer mb-3">
        Rakan korporat untuk Minggu Saham Digital 2020 adalah:<br />
      </p>
      <p class="answer mb-3">
        Rakan Premier: Malayan Banking Berhad (Maybank), Sime Darby Plantation
        Berhad dan UMW Holdings Berhad (UMW).
      </p>
      <p class="answer mb-3">
        Rakan Utama: Sime Darby Berhad, Sime Darby Property Berhad, S P Setia
        Berhad, Malaysian Industrial Development Finance Berhad (MIDF), dan
        Syarikat Otomobil Kedua Sendirian Berhad (Perodua).
      </p>
      <p class="answer">
        Rakan: Malaysian National Reinsurance Berhad (MNRB), CCM Berhad (CCM),
        Duopharma Biotech Berhad, Velesto Energy Berhad, Sapura Energy Berhad,
        dan Projek Lintasan Kota Holdings Sendirian Berhad (PROLINTAS).
      </p>
      <p class="quest">
        Sekiranya saya terlepas rancangan, bolehkah saya menontonnya lagi?
      </p>
      <p class="answer">
        Ya. Anda boleh menggunakan fungsi Live Rewind yang membolehkan anda
        memutar balik strim langsung kami, asalkan siaran belum berakhir. Fungsi
        Live Rewind akan membolehkan kawalan ulang tayang kepada video semasa ia
        distrim dan akan bertindak langsung kepada permintaan anda.
      </p>
      <p class="quest">
        Di manakah informasi Minggu Saham Digital yang terkini boleh didapati?
      </p>
      <p class="answer">
        Ikuti laman Facebook dan akaun Instagram kami di @minggusahamdigital
        untuk semua maklumat terkini. Sebagai alternatif, anda juga boleh "Klik
        Menghadiri" ke semua halaman acara individu kami di Facebook sekiranya
        anda berminat dengan acara tertentu dan ingin mendapat maklumat terkini.
      </p>
      <p class="quest">Apakah laman media sosial Minggu Saham Digital rasmi?</p>
      <p class="answer">
        Laman media sosial rasmi untuk Minggu Saham Digital adalah
        @minggusahamdigital di Facebook, @minggusahamdigital di Instagram dan
        Minggu Saham Digital di Youtube.
      </p>
      <p class="quest">Apakah laman web rasmi untuk Minggu Saham Digital?</p>
      <p class="answer">
        Laman web rasmi untuk Minggu Saham Digital adalah
        www.minggusahamdigital.com.my
      </p>
      <p class="quest">Apakah hashtag rasmi untuk Minggu Saham Digital?</p>
      <p class="answer">
        Hashtag rasmi untuk Minggu Saham Digital adalah #MingguSahamDigital dan
        #LaburBersama.
      </p>

      <p class="quest">
        Adakah sebarang peraduan yang boleh disertai sepanjang Minggu Saham
        Digital?
      </p>
      <p class="answer mb-3">
        Ya. Minggu Saham Digital akan menganjurkan peraduan maya ‘Eh, Mana Kod?’
        dan ‘#LakaranJuara’, yang mana para peserta berpeluang memenangi hadiah
        bernilai melebihi RM500,000.
      </p>
      <p class="answer">
        Selain itu, terdapat juga 'Cabutan Bertuah' untuk 2,500 peserta yang
        berdaftar yang bertuah untuk memenangi e-baucar Lazada. Sewaktu
        rancangan ‘Pass the Mic’ yang merupakan konsert besar kami, kami juga
        akan mengadakan peraduan istimewa, yang boleh disertai melalui laman
        Facebook Digital Minggu Saham rasmi. Selain itu, terdapat juga ‘Live
        Trivia’ & Live Trivia Bonanza’ setiap hari yang akan berlangsung
        beberapa kali sehari selama 7 hari, yang boleh disertai melalui Laman
        Facebook rasmi Digital Minggu Saham.
      </p>

      <h2 class="heading">Pertandingan</h2>
      <h3 class="text1 mb-4 pb-2">Eh, Mana Kod?</h3>
      <p class="quest">Hadiah Peraduan ‘Eh, Mana Kod?’</p>
      <p class="answer mb-2">
        1 x Ford Ranger 2.0L XLT Plus 4WD 10AT YM2020 bernilai RM130,000.00.<br />
1 x Toyota Vios 1.5 G (AT) bernilai RM90,000.00.<br />
1 x Yamaha R25 bernilai RM21,500.00.<br />
8 x Honda Dash 125 S bernilai RM6,000.00 setiap satu.<br />
10 x Wang Tunai Raiz bernilai RM250.00 setiap satu.<br />
1000 x e-baucar Lazada bernilai RM50.00 setiap satu.<br />
      </p>
      <p class="answer font-italic" style="font-size: 11px !important">
        *Semua hadiah tertakluk kepada perubahan
      </p>
      <p class="quest">
        Bagaimanakah cara untuk menyertai peraduan ‘Eh, Mana Kod?’
      </p>
      <ol type="1">
        <li>
          Peserta perlu mendaftar di laman web Minggu Saham Digital
          <a>www.minggusahamdigital.com.my</a> dengan mengisi data seperti nama
          penuh, nombor kad pengenalan dan alamat emel untuk menyertai Peraduan
          ini.
        </li>
        <li>
          Peserta perlu mengumpul sebanyak mungkin kod-kod yang akan dipaparkan
          di dalam video-video yang disiarkan di laman sosial dan juga di laman
          rakan korporat di laman web Minggu Saham Digital
          <a>www.minggusahamdigital.com.my.</a>
        </li>
        <li>
          Peserta perlu menjejak semua kod dan kemudian mengunjungi laman web
          Minggu Saham Digital untuk memasukkan kod di halaman penebusan. Setiap
          hantaran akan dikira sebagai satu penyertaan. Semakin banyak bilangan
          penyertaan, semakin tinggi peluang untuk memenangi hadiah.
        </li>
        <li>
          Pemenang Peraduan akan diumumkan pada 10 Disember 2020 melalui laman
          Facebook Minggu Saham Digital
          <a>www.facebook.com/minggusahamdigital.</a>
        </li>
        <li>
          Untuk hadiah berbentuk digital, Penganjur akan memaklumkan butiran
          lanjut tentang kaedah penebusan hadiah melalui emel. Untuk hadiah
          fizikal, Penganjur akan menghubungi pemenang dan pihak yang terlibat
          selepas pengumuman pemenang tersebut dibuat.
        </li>
      </ol>
      <p class="answer">
        Peraduan ‘Eh, Mana Kod?’ bermula pada 2 November 2020 dan berakhir pada 30 November 2020. Cabutan bertuah ini akan dilakukan secara langsung pada 10 Disember 2020 di laman Facebook Minggu Saham Digital. Peraduan ini terbuka kepada semua warganegara Malaysia yang berumur 18 tahun ke atas. Terma dan syarat disenaraikan di bawah bahagian "Catatan" di laman Facebook kami.
      </p>
      <h3 class="text1 mb-4 pb-2 pt-4">Cabutan Bertuah</h3>
      <p class="quest">Hadiah ‘Cabutan Bertuah’</p>
      <p class="answer mb-2">
        2500 x E-baucar Lazada bernilai RM50.00 setiap satu.
      </p>
      <p class="answer font-italic" style="font-size: 11px !important">
        *Semua hadiah tertakluk kepada perubahan
      </p>
      <p class="quest">Apakah cara menyertai peraduan ‘Cabutan Bertuah’?</p>
      <ol type="1">
        <li>
          Peserta perlu mendaftar di laman web Minggu Saham Digital
          <a>www.minggusahamdigital.com.my.</a> Peserta perlu mengisi maklumat
          mereka seperti nama, nombor kad pengenalan dan e-mel dalam borang
          e-pendaftaran.
        </li>
        <li>
          ‘Cabutan Bertuah’ akan diumumkan pada tarikh acara berlangsung bermula
          18 November 2020 dan berakhir pada 24 November 2020 ("Tempoh
          Peraduan") di siaran langsung laman Facebook rasmi Minggu Saham
          Digital
          <a>www.facebook.com/minggusahamdigital.</a>
        </li>
        <li>
          Sepanjang tempoh Peraduan, penganjur akan memilih secara rawak 2,500
          peserta yang telah mendaftar di laman web Minggu Saham Digital
          <a>www.minggusahamdigital.com.my.</a>
        </li>
        <li>
          Pemenang Peraduan akan diumumkan pada 10 Disember 2020 melalui laman
          Facebook rasmi Minggu Saham Digital
          <a>www.facebook.com/minggusahamdigital</a>.
        </li>
        <li>
          Pemenang akan dimaklumkan melalui e-mel. E-baucar akan dihantar ke
          e-mel Pemenang ‘Cabutan Bertuah’.
        </li>
      </ol>
      <p class="answer">
        Peraduan 'Cabutan Bertuah' akan diumumkan sewaktu tarikh acara
        berlangsung bermula 18 November 2020 dan berakhir pada 24 November 2020
        ("Tempoh Cabutan Bertuah") melalui laman Facebook rasmi Minggu Saham
        Digital
        <a>www.facebook.com/minggusahamdigital</a>. Pemenang akan dimaklumkan
        melalui e-mel. E-baucar akan dihantar ke e-mel pemenang ‘Cabutan
        Bertuah’. Peraduan ini terbuka kepada warganegara Malaysia yang berumur
        18 tahun ke atas. Terma dan syarat disenaraikan di bahagian "Catatan" di
        laman Facebook kami.
      </p>
      <h3 class="text1 mb-4 pb-2 pt-4">Pass The Mic</h3>
      <p class="quest">Hadiah Konsert Maya ‘Pass the Mic’</p>
      <p class="answer mb-2">
        1 x Perodua Aruz bernilai RM75,000.00.<br />
        10 x E-baucar Lazada bernilai RM500.00 setiap satu.
      </p>
      <p class="answer font-italic" style="font-size: 11px !important">
        *Semua hadiah tertakluk kepada perubahan
      </p>
      <p class="quest">
        Bagaimana saya hendak menyertai peraduan ‘Pass the Mic’?
      </p>
      <ol type="1">
        <li>
          Peserta perlu mengikuti rancangan ‘Pass The Mic’ yang bersiaran antara
          pukul 8.30 malam hingga 10.30 malam pada 21 November 2020 di siaran
          langsung laman Facebook rasmi Minggu Saham Digital.
        </li>
        <li>
          Peserta perlu memberikan maklumat mereka seperti nama, nombor kad
          pengenalan dan e-mel jika mereka adalah antara pemenang yang bertuah.
        </li>
        <li>
          Sewaktu siaran ‘Pass the Mic’, penonton akan berpeluang menyertai
          cabaran mengejut dan perlu mengirimkan jawapan mereka dalam tempoh
          persembahan dari pukul 8.30 malam hingga 10.30 malam.
        </li>
        <li>
          Pemenang akan diumumkan pada akhir siaran ‘Pass the Mic’ di Laman
          Facebook Minggu Saham Digital rasmi. Pemenang akan dihubungi melalui
          pesanan langsung di Facebook oleh wakil Minggu Saham Digital.
        </li>
      </ol>
      <p class="answer">
        Pemenang peraduan ‘Pass the Mic’ akan diumumkan pada 21 November 2020
        menerusi laman Facebook Minggu Saham Digital
        <a>www.facebook.com/minggusahamdigital.</a> E-baucar akan dihantar ke
        e-mel Pemenang peraduan ‘Pass The Mic’. Peraduan ini terbuka kepada
        warganegara Malaysia yang berumur 18 tahun ke atas. Terma dan syarat
        disenaraikan di bahagian "Catatan" di laman Facebook kami.
      </p>
      <h3 class="text1 mb-4 pb-2 pt-4">#LakaranJuara</h3>
      <p class="quest">Hadiah Peraduan #LakaranJuara</p>
      <p class="answer mb-2">
        Hasil karya pemenang dijadikan pelitup muka khusus untuk Majlis Pelancaran Minggu Saham Digital dan pemenang juga menerima e-baucar Lazada bernilai RM1,000 serta pelitup muka tersebut.
      </p>
      <p class="answer font-italic" style="font-size: 11px !important">
        *Semua hadiah tertakluk kepada perubahan
      </p>
      <p class="quest">
        Bagaimanakan cara untuk menyertai peraduan ‘#LakaranJuara’?
      </p>
      <ol type="1">
        <li>Ikuti akaun rasmi Instagram @minggusahamdigital</li>
        <li>
          #LakaranJuara adalah peraduan lukisan atau rekaan yang bertemakan “Era
          Digital”. Peserta perlu melukis atau mereka pelitup muka berdasarkan
          tema tersebut. Ekspresikan fikiran, perasaan dan idea tentang
          bagaimana kita bergerak ke era digital.
        </li>
        <li>
          Muat turun templat penyertaaan di <a>bit.ly/lakaranjuara</a> atau
          tangkap layar (screenshot) templat penutup muka yang terdapat di
          Instagram highlight #LakaranJuara di akaun @minggusahamdigital
        </li>
        <li>
          Peserta mesti menyerahkan rekaan pelitup muka terbaik mereka dengan
          memuat naiknya di laman Instagram mereka dan pastikan tag
          @minggusahamdigital dan hashtag #LaburBersama dan #LakaranJuara
          disertakan bersama di kapsyen. Pastikan Laman Instagram peserta
          terbuka kepada umum.
        </li>
        <li>
          Juri akan memutuskan pemenang karya seni berdasarkan tema, idea dan
          keaslian.
        </li>
        <li>
          Hasil karya plagiat adalah tidak dibenarkan dan akan menyebabkan
          penyertaan terbatal serta-merta.
        </li>
        <li>
          Hasil karya pemenang akan dijadikan pelitup muka sebenar yang akan
          disiarkan pada Majlis Pelancaran Minggu Saham Digital (19 November
          2020) dan pemenang juga akan menerima e-baucar Lazada bernilai RM1,000
          berserta pelitup muka rekaan mereka.
        </li>
        <li>
          Pemenang #LakaranJuara akan diumumkan pada 2 November 2020 melalui
          laman Instagram @minggusahamdigital.
        </li>
      </ol>
      <p class="answer">
        Peraduan ‘#LakaranJuara’ telah berlangsung dari 20 Oktober 2020 hingga 31 Oktober 2020. Pemenang peraduan diumumkan pada 2 November 2020 di akaun Instagram Minggu Saham Digital.
      </p>
      <h3 class="text1 mb-4 pb-2 pt-4">Live Trivia & Live Trivia Bonanza</h3>
      <p class="quest">Hadiah Live Trivia</p>
      <p class="answer mb-2">
        15 x E-baucar Lazada bernilai RM300.00 setiap satu.<br />
        135 x E-baucar Lazada bernilai RM150.00 setiap satu.<br />
      </p>
      <p class="answer font-italic" style="font-size: 11px !important">
        *Semua hadiah tertakluk kepada perubahan
      </p>
      <p class="quest">
        Bagaimana saya mengambil bahagian dalam ‘Live Trivia’ & ‘Live Trivia
        Bonanza’?
      </p>
      <ol type="1">
        <li>
          Peserta perlu mengikuti rancangan ‘Live Trivia & Live Trivia Bonanza’
          yang diadakan sebanyak 24 kali antara tarikh 18 hingga 24 November
          2020 di laman Facebook rasmi Minggu Saham Digital (rujuk
          <a>www.minggusahamdigital.com.my</a> untuk jadual penuh). Peserta
          perlu mengisi maklumat mereka seperti nama, nombor kad pengenalan dan
          e-mel sekiranya mereka adalah salah seorang pemenang yang bertuah.
        </li>
        <li>
          Semasa pertunjukan Live Trivia dan Live Trivia Bonanza, penonton akan
          diminta untuk memberikan jawapan mereka di bahagian komen di Facebook
          Livestream.
        </li>
        <li>
          Hadiah akan diberikan kepada 10 peserta dengan jawapan tepat yang
          terbanyak untuk setiap sesi.
        </li>
        <li>
          Pemenang akan dihubungi melalui pesanan langsung di Facebook oleh
          wakil Minggu Saham Digital.
        </li>
      </ol>
      <p class="answer">
        Pemenang ‘Live Trivia’ & ‘Live Trivia Bonanza’ akan diumumkan setelah
        setiap sesi ‘Live Trivia’ dan ‘Live Trivia Bonanza’ selesai melalui
        laman Facebook rasmi Minggu Saham Digital
        <a>www.facebook.com/minggusahamdigital.</a> Pemenang akan dihubungi
        melalui pesanan langsung di Facebook oleh wakil Minggu Saham Digital.
        E-baucar akan dihantar ke e-mel Pemenang ‘Live Trivia & Live Trivia
        Bonanza’. Peraduan ini terbuka untuk warganegara Malaysia yang berumur
        18 tahun ke atas. Terma dan syarat disenaraikan di bahagian "Catatan" di
        laman Facebook kami.
      </p>
    </ng-container>
    <ng-container *ngIf="englishMode">
      <h1 class="CustFont text-center">Frequently asked questions</h1>
      <!-- <div class="row faqsCont">
      <div class="col-sm-5">
        <div class="questCont">
          <h5 class="mb-3 CustFont">1. Lorem Ipsum</h5>
          <p class="mb-0 font-17">Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et
            dolore magna aliqua. Ut enim ad minim veniam.</p>
        </div>
        <div class="questCont">
          <h5 class="mb-3 CustFont">2. Lorem Ipsum</h5>
          <p class="mb-0 font-17">Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et
            dolore magna aliqua. Ut enim ad minim veniam.</p>
        </div>
        <div class="questCont">
          <h5 class="mb-3 CustFont">3. Lorem Ipsum</h5>
          <p class="mb-0 font-17">Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et
            dolore magna aliqua. Ut enim ad minim veniam.</p>
        </div>
      </div>
      <div class="col-sm-1">
        <div class="borderCont"></div>
      </div>
      <div class="col-sm-5">
        <div class="questCont">
          <h5 class="mb-3 CustFont">4. Lorem Ipsum</h5>
          <p class="mb-0 font-17">Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et
            dolore magna aliqua. Ut enim ad minim veniam.</p>
        </div>
        <div class="questCont">
          <h5 class="mb-3 CustFont">5. Lorem Ipsum</h5>
          <p class="mb-0 font-17">Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et
            dolore magna aliqua. Ut enim ad minim veniam.</p>
        </div>
        <div class="questCont">
          <h5 class="mb-3 CustFont">6. Lorem Ipsum</h5>
          <p class="mb-0 font-17">Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et
            dolore magna aliqua. Ut enim ad minim veniam.</p>
        </div>
      </div>
    </div> -->
      <h2 class="heading">Minggu Saham Digital</h2>
      <p class="quest">What is Minggu Saham Digital?</p>
      <p class="answer">
        Hosted by Permodalan Nasional Berhad (PNB), Minggu Saham Digital is a
        seven-day livestream event that will be broadcasted on Facebook and
        Youtube. With the theme #LaburBersama, this digital carnival aims to
        showcase the institution’s strategic investments and educate Malaysians
        of all ages on the importance of investing and financial planning,
        whilst entertaining. Throughout the digital event, viewers will find
        different forums and webinar series that will prove to be educational,
        while enjoying entertaining segments by way of virtual concert, engaging
        talk shows, game shows, cooking shows, and science experiments, just to
        name a few.
      </p>
      <p class="quest">When and where is Minggu Saham Digital?</p>
      <p class="answer">
        Minggu Saham Digital will be broadcasted live through Minggu Saham
        Digital’s Facebook page and Minggu Saham Digital’s YouTube page daily
        from 18 November to 24 November 2020 between 12.30 p.m. and 10.30 p.m.
      </p>
      <p class="quest">Who can attend Minggu Saham Digital?</p>
      <p class="answer">
        Minggu Saham Digital is open to anyone with access to Facebook and
        Youtube.
      </p>
      <p class="quest">Is Minggu Saham Digital free to attend/view?</p>
      <p class="answer">Yes. Minggu Saham Digital is free-to-attend for all.</p>
      <p class="quest">What content can I expect on Minggu Saham Digital?</p>
      <p class="answer">
        Viewers will experience different educational forums and webinar series,
        while enjoying entertaining celebrity-studded segments by way of a
        virtual concert, enigmatic talk shows, game shows, cooking shows, and
        science experiments.
      </p>
      <p class="quest">Where can I find the full schedule?</p>
      <p class="answer">
        Minggu Saham Digital’s programmes are spread over 7 consecutive days
        with more than 9 hours of live stream content daily. The full schedule
        will be posted on Minggu Saham Digital’s social media channels and
        official website soon.
      </p>
      <p class="quest">Who are the guests/speakers?</p>
      <p class="answer">
        The full list of speakers and guests will be posted on Minggu Saham
        Digital’s social media channels and official website soon.
      </p>
      <p class="quest">
        Who are the corporate partners for Minggu Saham Digital 2020?
      </p>
      <p class="answer mb-3">
        The corporate partners for Minggu Saham Digital 2020 are:<br />
      </p>
      <p class="answer mb-3">
        Rakan Premier: Malayan Banking Berhad (Maybank), Sime Darby Plantation
        Berhad and UMW Holdings Berhad (UMW).
      </p>
      <p class="answer mb-3">
        Rakan Utama: Sime Darby Berhad, Sime Darby Property Berhad, S P Setia
        Berhad, Malaysian Industrial Development Finance Berhad (MIDF), and
        Perusahaan Otomobil Kedua Sendirian Berhad (Perodua).
      </p>
      <p class="answer">
        Rakan: Malaysian National Reinsurance Berhad (MNRB), CCM Berhad (CCM),
        Duopharma Biotech Berhad, Velesto Energy Berhad, Sapura Energy Berhad,
        and Projek Lintasan Kota Holdings Sendirian Berhad (PROLINTAS).
      </p>
      <p class="quest">I missed a show. Can I watch it again?</p>
      <p class="answer">
        Yes. You can use the “Live Rewind” function which allows you to rewind
        our livestreams, even before the broadcast has ended. The “Live Rewind”
        function will bring rewind controls to videos as they are streaming and
        is a direct response to your requests.
      </p>
      <p class="quest">How do I receive event updates?</p>
      <p class="answer">
        Follow our Facebook page and Instagram account at @minggusahamdigital
        for all the latest updates. Alternatively, you may also click “Attend”
        in all of our individual event pages on Facebook should you be
        interested in a specific event and want to receive updates.
      </p>
      <p class="quest">
        What are the official Minggu Saham Digital social media channels?
      </p>
      <p class="answer">
        The official social media channels for Minggu Saham Digital are
        @minggusahamdigital on Facebook, @minggusahamdigital on Instagram and
        Minggu Saham Digital on YouTube.
      </p>
      <p class="quest">
        What is the official website for Minggu Saham Digital?
      </p>
      <p class="answer">
        The official website for Minggu Saham Digital is
        www.minggusahamdigital.com.my
      </p>
      <p class="quest">
        What is the official hashtag(s) for Minggu Saham Digital?
      </p>
      <p class="answer">
        The official hashtags for Minggu Saham Digital are #MingguSahamDigital
        and #LaburBersama.
      </p>

      <p class="quest">Are there any contests for Minggu Saham Digital?</p>
      <p class="answer mb-3">
        Yes. Minggu Saham Digital will be hosting a virtual treasure hunt ‘Eh,
        Mana Kod?’ and face mask-designing contest #LakaranJuara, where
        contestants will have the opportunity to win prizes worth more than
        RM500,000.
      </p>
      <p class="answer">
        Aside from that, there will also be a special ‘Lucky Draw’ where 2,500
        registered participants will be selected to win Lazada e-vouchers.
        During the “Pass the Mic” show which is our grand concert, we will be
        having a surprise competition as well. To participate, tune in through
        the official Minggu Saham Digital Facebook Page. There will also be
        daily Live Trivia and Live Trivia Bonanza Shows running multiple times a
        day during the 7-day stream. To participate, tune in through the
        official Minggu Saham Digital Facebook Page.
      </p>

      <h2 class="heading">Competition</h2>
      <h3 class="text1 mb-4 pb-2">Eh, Mana Kod?</h3>
      <p class="quest">‘Eh, Mana Kod?’ Prizes</p>
      <p class="answer mb-2">
        1 x Ford Ranger 2.0L XLT Plus 4WD 10AT worth RM130,000.00.<br />
        1 x Toyota Vios 1.5 G (AT) worth RM90,000.00.<br />
        1 x Yamaha R25 worth RM21,500.00.<br />
        8 x Honda Dash 125 S worth RM6,000.00 each.<br />
        10 x Raiz Cash worth RM250.00 each.<br />
      </p>
      <p class="answer font-italic" style="font-size: 11px !important">
        *All prizes are subject to change
      </p>
      <p class="quest">How do I participate in the Eh, Mana Kod contest?’</p>
      <ol type="1">
        <li>
          Participants need to register on the Minggu Saham Digital website
          <a>www.minggusahamdigital.com.my</a> and provide personal data such as
          full name, identity card number and email address to participate in
          the Contest.
        </li>
        <li>
          Participants need to collect as many codes as possible. The codes will
          be displayed in all videos published in Minggu Saham Digital’s social
          media channels and also on the partners’ pages on the Minggu Saham
          Digital website
          <a>www.minggusahamdigital.com.my.</a>
        </li>
        <li>
          Participants need to track all of the codes and then visit the Minggu
          Saham Digital website to enter the codes into the code redemption
          page. One submission is considered as one entry. The more entries, the
          higher the chances of winning the prizes.
        </li>
        <li>
          The winners will be announced during the live prize giving ceremony on
          10 December 2020 via Minggu Saham Digital Facebook page
        </li>
        <li>
          For the digital prizes, the Organiser will send further details on the
          prizes and redemption process to the winners via email. For the
          physical prizes, the Organiser will communicate and make necessary
          arrangements with the winners and the relevant parties (if any) after
          the prize giving announcement.
        </li>
      </ol>
      <p class="answer">
        The ‘Eh, Mana Kod?’ contest will begin on 2 November 2020 and end on 30
        November 2020. The lucky draw will be done live on 10 December 2020 on
        Minggu Saham Digital’s Facebook page. This contest is open to all
        Malaysian citizens aged 18 and above. Terms and conditions apply and are
        listed under the “Notes” section of our Facebook page
        <a>www.facebook.com/minggusahamdigital.</a>
      </p>
      <h3 class="text1 mb-4 pb-2 pt-4">Lucky Draw</h3>
      <p class="quest">Lucky Draw Prizes</p>
      <p class="answer mb-2">2,500 x Lazada e-vouchers worth RM50.00 each.</p>
      <p class="answer font-italic" style="font-size: 11px !important">
        *All prizes are subject to change
      </p>
      <p class="quest">How do I participate in the ‘Lucky Draw’ contest?</p>
      <ol type="1">
        <li>
          Participants need to register on the Minggu Saham Digital website
          <a>www.minggusahamdigital.com.my.</a> Participants need to provide
          personal data such as name, identity card number and e-mail in the
          registration e-form.
        </li>
        <li>
          The lucky draw will be announced between 18 November 2020 and 23
          November 2020 (“Lucky Draw Period”) via Minggu Saham Digital Facebook
          page
          <a>www.facebook.com/minggusahamdigital.</a>
        </li>
        <li>
          Throughout the Lucky Draw period, the organisers will randomly select
          2,500 participants who have registered on the Minggu Saham Digital
          website
          <a>www.minggusahamdigital.com.my.</a>
        </li>
        <li>
          The winners will be notified via email. E-vouchers will be sent to the
          email of the Winners of the Lucky Draw.
        </li>
        <li>
          The 'Lucky Draw' contest will be announced during the event date
          starting 18 November 2020 and ending on 24 November 2020 ("Lucky Draw
          Period") via the official Facebook page of Minggu Saham Digital
        </li>
      </ol>
      <p class="answer">
        The ‘Lucky Draw’ contest will be announced between 18 November 2020 and
        23 November 2020 (“Lucky Draw Period”) via Minggu Saham Digital Facebook
        page
        <a>www.facebook.com/minggusahamdigital</a>. The winners will be notified
        via email. E-vouchers will be sent to the email of the Winners of
        theLucky Draw. This contest is open to Malaysian citizens aged 18 and
        above. Terms and conditions apply and are listed under the “Notes”
        section of our Facebook page.
        <a>www.facebook.com/minggusahamdigital</a>.
      </p>
      <h3 class="text1 mb-4 pb-2 pt-4">Pass the Mic</h3>
      <p class="quest">Pass the Mic Grand Concert Prizes</p>
      <p class="answer mb-2">
        1 x Perodua Aruz worth RM75,000.00.<br />
        10 x Lazada e-vouchers worth RM500.00 each.
      </p>
      <p class="answer font-italic" style="font-size: 11px !important">
        *All prizes are subject to change
      </p>
      <p class="quest">How do I participate in the ‘Pass the Mic’ contest?</p>
      <ol type="1">
        <li>
          Participants need to tune in to the Pass the Mic show at 8.30pm to
          10.30pm on 21 November 2020 on the official Minggu Saham Digital
          Facebook Page Livestream. Participants need to provide personal data
          such as name, identity card number and e-mail if they are one of the
          lucky winners.
        </li>
        <li>
          During the Pass the Mic show, viewers will be instructed to execute a
          surprise challenge and submit their answers within the show period of
          8.30pm to 10.30pm.
        </li>
        <li>
          The winners will be announced at the end of the Pass the Mic show on
          the official Minggu Saham Digital Facebook Page Livestream.
        </li>
        <li>
          The winners will be contacted via direct message on Facebook by Minggu
          Saham Digital’s representative.
        </li>
      </ol>
      <p class="answer">
        The ‘Pass the Mic’ contest winners will be announced on 21 November 2020
        via Minggu Saham Digital Facebook page
        <a>www.facebook.com/minggusahamdigital.</a> E-vouchers will be sent to
        the email of the Winners of the Pass The Mic Contest. This contest is
        open to Malaysian citizens aged 18 and above. Terms and conditions apply
        and are listed under the “Notes” section of our Facebook page.
      </p>
      <h3 class="text1 mb-4 pb-2 pt-4">#LakaranJuara</h3>
      <p class="quest">#LakaranJuara Contest Prizes</p>
      <p class="answer mb-2">
        The winning artwork will be printed on a face mask which will be
        showcased in conjunction with Minggu Saham Digital Launch Ceremony and
        the winner will also receive a Lazada e-voucher worth RM1,000 and the
        finished product.
      </p>
      <p class="answer font-italic" style="font-size: 11px !important">
        *All prizes are subject to change
      </p>
      <p class="quest">How do I participate in the contest #LakaranJuara?</p>
      <ol type="1">
        <li>Follow the official @minggusahamdigital Instagram account.</li>
        <li>
          #LakaranJuara is a drawing/design contest. The theme of the contest is
          “Digital Era”. Participants are required to draw or design a face mask
          according to the theme and we invite you to submit your artwork
          expressing your thoughts, feelings and ideas on entering the digital
          era
        </li>
        <li>
          Download the face mask entry template from <a>bit.ly/lakaranjuara</a>
          aor from the Instagram highlight #LakaranJuara at @minggusahamdigital.
        </li>
        <li>
          Participants must submit their best face mask design by uploading it
          on their Instagram page and be sure to tag @minggusahamdigital and
          also include the hashtag #LakaranJuara and #LaburBersama in the
          caption. Participants' Instagram pages must be made public. The jury
          will decide the winner of the artwork based on themes, ideas and
          authenticity.
        </li>
        <li>
          Plagiarism is strictly prohibited and will lead to automatic
          disqualification from the Contest.
        </li>
        <li>
          The winning artwork will be printed on a face mask which will be
          showcased in conjunction with Minggu Saham Digital Launch Ceremony (19
          November 2020) and the winner will also receive a Lazada e-voucher
          worth RM1,000.
        </li>
        <li>
          The winner of #LakaranJuara will be announced on 2 November 2020 on
          the Instagram page @minggusahamdigital
        </li>
      </ol>
      <p class="answer">
        The ‘#LakaranJuara’ contest will start on 20 October 2020 and end at
        11.59 pm on 31 October 2020. The contest winner will be announced on 2
        November 2020 on Minggu Saham Digital’s Instagram account. This contest
        is open to Malaysian citizens aged 18 and above. Terms and conditions
        apply and are listed under the “Notes” section of our Facebook page.
      </p>
      <h3 class="text1 mb-4 pb-2 pt-4">Live Trivia & Live Trivia Bonanza</h3>
      <p class="quest">Live Trivia Prizes</p>
      <p class="answer mb-2">
        15 x Lazada e-vouchers worth RM300.00 each.
        <br />
        135 x Lazada e-vouchers worth RM150.00 each.<br />
      </p>
      <p class="answer font-italic" style="font-size: 11px !important">
        *All prizes are subject to change
      </p>
      <p class="quest">
        How do I participate in the ‘Live Trivia’ & ‘Live Trivia Bonanza’?
      </p>
      <ol type="1">
        <li>
          Participants need to tune in to the Live Trivia & Live Trivia Bonanza
          show happening 24 times between 18 to 24 November 2020 on the official
          Minggu Saham Digital Facebook Page Livestream (refer to
          <a>www.minggusahamdigital.com.my</a> for full schedule). Participants
          will need to provide personal data such as name, identity card number
          and e-mail if they are one of the lucky winners.
        </li>
        <li>
          During the Live Trivia and Live Trivia Bonanza show, viewers will be
          instructed to submit their answers to the questions via the comment
          section in the Facebook Livestream..
        </li>
        <li>Prizes will be awarded to the top 10 scorers for every session.</li>
        <li>
          The winners will be contacted via direct message on Facebook by a
          Minggu Saham Digital’s representative.
        </li>
      </ol>
      <p class="answer">
        The ‘Live Trivia’ & ‘Live Trivia Bonanza’ winners will be announced
        after each session is completed via Minggu Saham Digital Facebook page
        <a>www.facebook.com/minggusahamdigital.</a> E-vouchers will be sent to
        the email of the Winners of the ‘Live Trivia’ & ‘Live Trivia Bonanza’.
        This contest is open to Malaysian citizens aged 18 and above. Terms and
        conditions apply and are listed under the “Notes” section of our
        Facebook page.
      </p>
    </ng-container>
  </div>
</div>

<app-footer></app-footer>
