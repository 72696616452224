<app-header></app-header>
<div class="languageCont">
  <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
    BM
  </h6>
  <h6 class="mx-2">|</h6>
  <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
</div>
<div class="row mainCont">
  <ng-container *ngIf="!englishMode">
    <div class="col-sm-6 px-0 text-center">
      <div class="khidmatLogo">
        <img src="/assets/imgs/khidmatLogo.png" width="80%" />
      </div>
      <h4 class="mb-2">Apa itu Khidmat Nasihat 1-1?</h4>
      <p class="mobPad">
        Anda boleh mendapatkan khidmat nasihat dan rundingan peribadi selama 30 minit dengan penasihat-penasihat dari ASNB, Ambank, Bank Islam, CIMB & RHB.Tempah awal sesi anda!
      </p>
      <div class="ansbLogoCont">
        <img src="/assets/imgs/ASNB_Logo2.png" height="50px" />
      </div>
      <h5 class="mobPad">Penasihat ASNB pakar dan boleh membantu dengan:</h5>
      <p class="font-15">
        Unit Amanah ASNB<br />
        Pembiayaan ASB<br />
        Hibah Amanah & Pengisytiharan Amanah<br />
        Pelaburan Sistematik Auto Labur<br />
        Perancangan Kewangan dan Pelaburan<br />
        Pelaburan Melalui i-Invest KWSP<br />
      </p>
      <div class="dateCont">
        <h5 class="text1 mb-4 "><u>18 - 19 & 23 - 24 Nov 2020</u></h5>
        <h3 class="mb-2">Pembiayaan ASB</h3>
        <p class="mb-0">
          Dalam sesi tertutup ini, penasihat akan membimbing anda<br />
          tentang kebaikan pembiayaan ASB dan cara untuk memohon
        </p>
      </div>
      <div class="dateCont">
        <h5 class="text1 mb-4 "><u>20 Nov 2020</u></h5>
        <h3 class="mb-2">Unit Amanah ASNB</h3>
        <p class="mb-0">
          Sesi tertutup untuk pemegang unit amanah ASNB mendapatkan<br />
          khidmat nasihat perancangan kewangan secara peribadi.
        </p>
      </div>
      <img src="/assets/imgs/khidmatImg2.png" class="partnerImgs" width="100%" />
      <div class="row justify-content-center">
        <div class="col-sm-8">
          <p class="mb-4">
            Penasihat daripada Ejen Bank kami yang pakar sedia membantu anda
            mengenai perkara-perkara berikut:
          </p>
          <h3 class="mb-2">Pembiayaan ASB</h3>
          <p class="mb-4 pb-3">
            Penasihat akan berkongsi tentang faedah pembiayaan ASB dan cara
            untuk memohon
          </p>
          <h3 class="mb-2">Unit Amanah ASNB</h3>
          <p class="mb-4 pb-3">
            Khidmat nasihat peribadi mengenai perancangan kewangan melalui unit
            amanah ASNB
          </p>
        </div>
      </div>
      <div class="hrLine"></div>
    </div>

    <div class="col-sm-8 px-0 text-center">
      <h3 class="bold mt-3">Tetapkan Temujanji Anda Sekarang</h3>
      <div class="row bankCont">
        <div class="col-xl-2 col-md-3 col-6">
          <div class="imgCont">
            <img src="/assets/imgs/ASNB_Logo2.png" (click)="hyperlink('https://msd2020asnb.as.me/')" />
          </div>
        </div>
      </div>
      <div class="row bankCont">
        <div class="col-xl-2 col-md-3 col-6">
          <div class="imgCont">
            <img src="/assets/imgs/ambank_khidmat.svg" (click)="hyperlink('https://msd2020ambank.as.me/')" />
          </div>
        </div>
        <div class="col-xl-2 col-md-3 col-6">
          <div class="imgCont">
            <img src="/assets/imgs/bankIslamix_khidmat.svg" (click)="hyperlink('https://msd2020bankislam.as.me/')" />
          </div>
        </div>
        <div class="col-xl-2 col-md-3 col-6">
          <div class="imgCont">
            <img src="/assets/imgs/cimbBank_khidmat.svg" (click)="hyperlink('https://msd2020cimb.as.me/')" />
          </div>
        </div>
        <div class="col-xl-2 col-md-3 col-6">
          <div class="imgCont">
            ><img src="/assets/imgs/RHB_khidmat.svg" (click)="hyperlink('https://msd2020rhb.as.me/')" />
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="englishMode">
    <div class="col-sm-6 px-0 text-center">
      <div class="khidmatLogo">
        <img src="/assets/imgs/khidmatLogo.png" width="80%" />
      </div>
      <h4 class="mb-2">What is 1-on-1 Advisor Session?</h4>
      <p class="mobPad">
        Customers can pre-book a 30-minute private consultation with advisors
        from ASNB, Ambank, Bank Islam, CIMB & RHB.
      </p>
      <div class="ansbLogoCont">
        <img src="/assets/imgs/ASNB_Logo2.png" height="50px" />
      </div>
      <h5 class="mobPad">ASNB Advisors specialise and can assist with:</h5>
      <p class="font-15">
        Unit Trust ASNB<br />
        ASB Financing <br />
        Estate Planning <br />
        Recurring Investment<br />
        Financial and Investment Planning <br />
        Investment via KWSP i-Invest <br />
      </p>
      <div class="dateCont">
        <h5 class="text1 mb-4 "><u>18 - 19 & 23 - 24 Nov 2020</u></h5>
        <h3 class="mb-2">ASB Financing</h3>
        <p class="mb-0">
          In this 1-on-1 session, our advisor can provide consultation on the benefits of
          <br />
          ASB financing and guidance on the application process.
        </p>
      </div>
      <div class="dateCont">
        <h5 class="text1 mb-4 "><u>20 Nov 2020</u></h5>
        <h3 class="mb-2">ASNB Unit Trust</h3>
        <p class="mb-0">
          A private session for ASNB Unit Trust holders to get personalised
          financial <br />
          planning advice.
        </p>
      </div>
      <img src="/assets/imgs/khidmatImg2.png" class="partnerImgs" width="100%" />
      <div class="row justify-content-center">
        <div class="col-sm-8">
          <p class="mb-4">
            Our Agent Bank Advisors specialise and can assist with:
          </p>
          <h3 class="mb-2">ASB Financing:</h3>
          <p class="mb-4 pb-3">
            The advisor can provide consultation on the benefits of ASB Financing and
            subsequently guidance to apply for ASB Financing.
          </p>
          <h3 class="mb-2">Unit Trust:</h3>
          <p class="mb-4 pb-3">
            The advisor can give personalised financial planning advice to the
            customer with unit trust products.
          </p>
        </div>
      </div>
      <div class="hrLine"></div>
    </div>
    <div class="col-sm-8 px-0 text-center">
      <h3 class="bold mt-3">Make your appointment</h3>
      <div class="row bankCont">
        <div class="col-xl-2 col-md-3 col-6">
          <div class="imgCont">
            <img src="/assets/imgs/ASNB_Logo2.png" (click)="hyperlink('https://msd2020asnb.as.me/')" />
          </div>
        </div>
      </div>
      <div class="row bankCont">
        <div class="col-xl-2 col-md-3 col-6">
          <div class="imgCont">
            <img src="/assets/imgs/ambank_khidmat.svg" (click)="hyperlink('https://msd2020ambank.as.me/')" />
          </div>
        </div>
        <div class="col-xl-2 col-md-3 col-6">
          <div class="imgCont">
            <img src="/assets/imgs/bankIslamix_khidmat.svg" (click)="hyperlink('https://msd2020bankislam.as.me/')" />
          </div>
        </div>
        <div class="col-xl-2 col-md-3 col-6">
          <div class="imgCont">
            <img src="/assets/imgs/cimbBank_khidmat.svg" (click)="hyperlink('https://msd2020cimb.as.me/')" />
          </div>
        </div>
        <div class="col-xl-2 col-md-3 col-6">
          <div class="imgCont">
            ><img src="/assets/imgs/RHB_khidmat.svg" (click)="hyperlink('https://msd2020rhb.as.me/')" />
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<app-footer></app-footer>
