import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../data.service';
import { HeaderComponent } from '../header/header.component';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  constructor(public fb: FormBuilder, public service: DataService, public toastr: ToastrService, private spinner: NgxSpinnerService) { }
  ngAfterViewInit(): void {
    this.loginCheck();
  }
  @ViewChild(HeaderComponent) header: HeaderComponent;

public submitted = false;
public pattern = /^[a-zA-Z0-9"]*$/
  ngOnInit(): void {


    this.registrationForm = this.fb.group({
      name:['' , Validators.required],
      dob:['' , Validators.required],
      email:['', [Validators.required,Validators.email]],
      nric:['' ,[Validators.required , Validators.minLength(1
        ),Validators.maxLength(12),Validators.pattern(this.pattern)]],
      password:['' , [Validators.required,Validators.minLength(6)]]
    })
  }
  // <-- variable Section  --> //
public registrationForm:FormGroup;
public isLogin=false;
public downWord = true;
public upWord = false;
  // <-- Function Section  --> //

  get f() { return this.registrationForm.controls; }

  public submit = () => {
    this.spinner.show();
    console.log(this.registrationForm.value);
    this.service.registerUser(this.registrationForm.value).subscribe((res: any) => {
      console.log(res);
      localStorage.setItem('authToken', res.jwt)
      // this.loginCheck();
      // this.header.loginCheck();
      this.spinner.hide();
      this.registrationForm.reset();

      let x = document.getElementById('thisModal');

      x.click();
      if (x) {

        x.click();


        this.toastr.success('User Registerd successfully');
        this.header.ngOnInit();
      }


    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.error.message + ' try Again')
      console.log(err.error.message)
    })

  }


  formOpen() {
    let y = document.getElementById('positionId');
    let rect = y.getBoundingClientRect();
    console.log(rect.top);
    this.registrationForm.reset();
  }
  public loginCheck = () => {
    let token = localStorage.getItem('authToken');
    if (token) {
      this.isLogin = true;
    }
    else {
      this.isLogin = false;
      let y = document.getElementById('openModalBtn');

      if (y) {
        y.click();
      }
    }
  }

  openPicker() {
    let x = document.getElementById('picker');
    console.log(x);
  }

  scrolldown(el: HTMLElement) {

    this.downWord = false;
    this.upWord = true;
    el.scrollIntoView();
  }

  scrollUp(el: HTMLElement) {
    this.downWord = true;
    this.upWord = false;
    el.scrollIntoView();
  }

  onScroll(event) {
    let y = document.getElementById('positionId');
    let rect = y.getBoundingClientRect();
    console.log(rect.top);
    if (rect.top <= 516.515625) {
      this.downWord = false;
      this.upWord = true
    }
    else if (rect.top > 516.515625) {
      this.downWord = true;
      this.upWord = false;
    }

  }

  public checkScrollDirectionIsUp(event) {
    console.log(window.scrollY)

    if (event.wheelDelta) {
      return event.wheelDelta > 0;
    }
    return event.deltaY < 0;
  }

  public authCheck = () => {
    let token = localStorage.getItem('authToken');
    if (token) {
      return false;;
    }
    else {
      return true;
    }
  }



}
