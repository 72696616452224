import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

// const httpOptions = {
//   headers: headers_object
// }
@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(public http: HttpClient) {
    this.language.next(this.setLanguage);
  }
  // public headers_object = new HttpHeaders();
  public baseUrl = environment.baseUrl;
  public login: Subject<any> = new Subject();
  public setLanguage = 'BM';
  public language: BehaviorSubject<any> = new BehaviorSubject('BM');

  public registerUser = (obj): Observable<any[]> => {
    return this.http.post<any>(this.baseUrl + 'create_user.php', obj);
  };

  public signIn = (obj): Observable<any[]> => {
    return this.http.post<any>(this.baseUrl + 'login.php', obj);
  };

  public addKot = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.baseUrl + 'add_code.php', obj);
  };

  public getCustomHeaders(): HttpHeaders {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Authorization', `Basic` + btoa('user-demo:1,{}oGtVrdHy'));

    return headers;
  }

  public addRef = (obj): Observable<any> => {
    return this.http.post<any[]>(this.baseUrl + 'add_referral.php', obj);
  };

  public resetPassword = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.baseUrl + 'forgot_password.php', obj);
  };

  public getUserCodes = (obj): Observable<any[]> => {
    console.log(obj);
    return this.http.post<any[]>(
      'https://minggusahamdigital.com.my/api/get_codes.php',
      /* 'https://ms2020demo.com/api/get_codes.php', */
      obj
    );
  };
}
