<app-header ></app-header>

<div class="row  mainCont">

  <div class="col-sm-9 px-0">
    <div class="row">
      <div class="col-sm-6 align-center">
        <img src="/assets/imgs/Maybank-Logo.png" height="70px">
        <p class="appTxt mb-0 mt-5">
          Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
          aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, adipiscing elit. Lorem ipsum dolor sit amet,
          adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </div>
      <div class="col-sm-6 videoCont">
        <img src="/assets/imgs/videoContTab.png" class="videoFrame">
        <video controls poster="/assets/imgs/poster.png">
          <source src="/assets/videos/sample.mp4" type="video/mp4">
        </video>
      </div>
    </div>
  </div>


  <!-- <div class="col-sm-11">
    <div class="row justify-content-center pt-4">
      <div class="col-sm-3 text-center">
        <img src="/assets/imgs/SimeDarby_Logo.svg" height="120px">
      </div>
      <div class="col-sm-7 align-center">
        <p class="appTxt mb-0">Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore
          et
          dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum is dolor sit amet, adipiscing elit. Lorem ipsum
          dolor sit amet, adipiscing elite, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
      </div>
    </div>
  </div> -->

  
  <div class="col-sm-12 px-0 sliderCont">
    <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
      <ul class="carousel-indicators">
        <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
        <li data-target="#expertsSlider" data-slide-to="1"></li>
        <li data-target="#expertsSlider" data-slide-to="2"></li>
        <li data-target="#expertsSlider" data-slide-to="3"></li>
        <li data-target="#expertsSlider" data-slide-to="4"></li>
      </ul>
      <div class="carousel-inner row" role="listbox">
        <div class="carousel-item col-sm-12 px-0 active">
          <div class="row justify-content-center">
            <div class="col-sm-9">
              <img src="/assets/imgs/partnerSliderImg1.png" class="slideImg">
            </div>
          </div>
        </div>
        <div class="carousel-item col-sm-12 px-0">
          <div class="row justify-content-center">
            <div class="col-sm-9">
              <img src="/assets/imgs/partnerSliderImg1.png" class="slideImg">
            </div>
          </div>
        </div>
        <div class="carousel-item col-sm-12 px-0">
          <div class="row justify-content-center">
            <div class="col-sm-9">
              <img src="/assets/imgs/partnerSliderImg1.png" class="slideImg">
            </div>
          </div>
        </div>
        <div class="carousel-item col-sm-12 px-0">
          <div class="row justify-content-center">
            <div class="col-sm-9">
              <img src="/assets/imgs/partnerSliderImg1.png" class="slideImg">
            </div>
          </div>
        </div>
        <div class="carousel-item col-sm-12 px-0">
          <div class="row justify-content-center">
            <div class="col-sm-9">
              <img src="/assets/imgs/partnerSliderImg1.png" class="slideImg">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
