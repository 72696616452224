<app-header></app-header>

<section class="sect1">
  <div class="bgLayer"></div>
</section>

<div class="row sect2">
  <div class="col-sm-8">
    <h4 class="mb-3 bold">Minggu Saham Live</h4>
    <video controls poster="/assets/imgs/poster.png">
      <source src="/assets/videos/sample.mp4" type="video/mp4">
    </video>
  </div>
  <div class="col-sm-8">
    <div class="row mt-5">
      <div class="col-sm-6 px-0">
        <h5 class="mb-0 text-justify">
          Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
          aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore.
        </h5>
      </div>
      <div class="col-sm-6 text-center align-center">
        <button data-toggle="modal" data-target="#referModal" id="refModal" class="btn loginBtn">Daftar</button>
      </div>
    </div>
  </div>
</div>


<app-footer></app-footer>


<div class="modal fade" id="referModal">
  <div class="modal-dialog myModal modal-dialog-centered">
    <div class="modal-content">
      <i class="fas fa-times closeBtn pointer" id="RefcloseModal" data-dismiss="modal"></i>
      <div class="inputCont">
        <h5 class="mt-2 mb-4">Dapatkan peluang untuk<br /> memenagi hadiah mewah!</h5>
        <div class="row justify-content-center">
          <div class="col-sm-8 col-9 px-0">
            <form [formGroup]='refForm'>
              <div class="form-group">
                <label>Bank</label>
                <select class="form-control" formControlName='agent_bank' (change)='bankSelection($event.target.value)'>
                  <option hidden value="" disabled selected>Select Bank</option>
                  <option *ngFor='let data of classifyArray' [value]='data.bankName'>{{data.bankName}}</option>


                </select>
              </div>
              <div class="form-group">
                <label>State</label>
                <select class="form-control" formControlName='state' (change)='stateSelection($event.target.value)'>
                  <option hidden value="" disabled selected>Select State</option>
                  <option *ngFor='let data of states' [value]='data.stateName'>{{data.stateName}}</option>

                </select>
              </div>
              <div class="form-group">
                <label>Branch</label>
                <select class="form-control" formControlName='branch'>
                  <option disabled value="" selected>Select Branch</option>
                  <option *ngFor='let data of branches' [value]='data'>{{data}}</option>
                </select>
              </div>
            </form>
            <button class="btn darkBtn btn-block" (click)='submit()' [disabled]='refForm.invalid'>HANTAR</button>
            <!--  <p class="font-14 my-0 pointer"><u>Akta Perlindungan Data Peribadi 2010</u></p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fall" [fullScreen]="true">
  <p style="color: white"></p>
</ngx-spinner>
