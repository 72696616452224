<app-header></app-header>

<section class="sect1">
  <!-- <div class="languageCont">
    <h6 class="my-0">BM</h6>
    <h6 class="mx-2">|</h6>
    <h6>ENG</h6>
  </div> -->
  <div class="bgLayer"></div>
</section>

<div class="row sect2">
  <div class="col-sm-8">
    <h4 class="mb-3 bold">Minggu Saham Live</h4>
    <!-- <video controls poster="/assets/imgs/poster.png">
      <source src="/assets/videos/sample.mp4" type="video/mp4">
    </video> -->
    <div class="embed-responsive embed-responsive-4by3">
      <iframe class="embed-responsive-item" src="/assets/videos/sample.mp4" allowfullscreen></iframe>
    </div>
  </div>
  <div class="col-sm-8">
    <div class="row mt-5">
      <div class="col-sm-6 px-0">
        <h5 class="mb-0 text-justify">
          Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
          aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore.
        </h5>
      </div>
      <div class="col-sm-6 text-center align-center">
        <button *ngIf='authCheck()' data-toggle="modal" data-target="#this" id='openModalBtn' class="btn loginBtn"
          (click)='formOpen()'>Daftar</button>
      </div>
    </div>
  </div>
</div>


<app-footer></app-footer>


<!-- Login Modal -->
<div class="modal fade" id="this">
  <div class="modal-dialog myModal modal-dialog-centered">
    <div class="modal-content">
      <i id="thisModal" class="fas fa-times closeBtn pointer" data-dismiss="modal"></i>
      <div class="inputCont" id='Sdiv' (scroll)="onScroll($event)">

        <h5 class="mt-2 mb-3" #target2>Dapatkan peluang untuk<br /> memenagi hadiah mewah!</h5>
        <form [formGroup]='registrationForm'>
          <div class="row justify-content-center">

            <div class="col-sm-8 col-9 px-0">

              <div class="form-group">
                <label>Nama</label>
                <input formControlName='name' type="text" class="form-control">
              </div>
              <div class="form-group">
                <label>Tarikh Lahir</label>
                <!-- <mat-datepicker-toggle hidden id='matPicker' style="position: absolute; padding: 1.5rem;" matSuffix [for]="picker"></mat-datepicker-toggle> -->
                <input placeholder="yy-mm-dd" class="form-control" type='date' max="2019-12-31" formControlName='dob'>
                <!--
              <mat-datepicker id="picker" #picker></mat-datepicker> -->
              </div>
              <div class="form-group">
                <label>E-mel</label>
                <input formControlName='email' (click)="scrolldown(target)" type="email" class="form-control"
                  [ngClass]="{ 'is-invalid':  f.email.errors }">

                <div class="form-group">
                  <label>No Kad</label>
                  <input formControlName='nric' type='text' class="form-control"
                    [ngClass]="{ 'is-invalid':  f.nric.errors }">
                  <div *ngIf=" f.nric.errors" class="invalid-feedback" [ngClass]="{ 'is-invalid':  f.nric.errors }">
                    <div *ngIf="f.nric.errors.pattern">No Special character Allowed </div>
                    <div *ngIf="f.nric.errors.maxlength">Max 12 characters are Allowed </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Kata Laluan</label>
                  <input formControlName='password' type="password" class="form-control"
                    [ngClass]="{ 'is-invalid':  f.password.errors }">

                  <div *ngIf=" f.password.errors" class="invalid-feedback">

                    <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                  </div>
                </div>


                <div class='row'>
                  <p style="width: 100%;margin: 0px;padding-top: 0.2rem;">
                    <img *ngIf='upWord' class="fixed" (click)="scrollUp(target2)" src="assets/imgs/up-arrow.svg">
                    <img *ngIf='downWord' class="fixed" (click)="scrolldown(target)" src="assets/imgs/down-arrow.svg">
                  </p>

                </div>


                <!-- <div class="fix" >

              <img class="fixed"  (click)="scrollUp(target2)" src="assets/imgs/up-arrow.svg" >
            </div>
       <div class="fix" >

         <img class="fixed" (click)="scrolldown(target)" src="assets/imgs/down-arrow.svg">
       </div> -->


                <div class="custom-control custom-checkbox">
                  <input checked type="checkbox" class="custom-control-input" id="customCheck" name="example1">
                  <label class="custom-control-label" for="customCheck">Melalui penyerahan borang pendaftaran ini,
                    peserta
                    dengan ini membenarkan, dan PNB berhak menyimpan dan memproses maklumat peribadi peserta, jika ada,
                    untuk tujuan pengiklanan, publisiti atau penyelidikan, menurut peruntukan perundangan berkaitan,
                    tanpa
                    membayar sebarang kos atau pampasan. <br>Saya bersetuju untuk menerima e-mel promosi.</label>
                </div>
                <button id='positionId' #target class="btn darkBtn btn-block" [disabled]='registrationForm.invalid'
                  (click)='submit()'>DAFTAR</button>
                <p class="font-14 my-0 pointer"><u>Akta Perlindungan Data Peribadi 2010</u></p>
              </div>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fall" [fullScreen]="true">
  <p style="color: white"></p>
</ngx-spinner>