<app-header></app-header>
<div class="languageCont">
  <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
    BM
  </h6>
  <h6 class="mx-2">|</h6>
  <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
</div>
<div class="row mainCont">
  <div class="col-sm-10">
    <h1 class="CustFont text-center">
      {{ englishMode ? "promotion" : "promosi" }}
    </h1>
    <div id="expertsSlider" class="carousel slide programsSlider" data-ride="carousel" data-interval="false">
      <div class="carousel-inner row" role="listbox">
        <div class="carousel-item col-sm-12 px-0 {{ item.active }}" *ngFor="let item of imageItems">
          <div class="row justify-content-around mt-5 pt-3">
            <div class="col-sm-5 mobileView">
              <img src="{{ item.src }}" width="75%" style="object-fit: cover" />
              <!-- <a href="{{ item.url }}">
                <button class="yellowBtn">{{btText}}</button></a> -->
            </div>
            <div class="col-sm-6 align-center">
              <p class="promoTxt" [innerHTML]="item.description | nl2br"></p>

              <div class="row justify-content-center">
                <a (click)='newtab(item.url)'>
                  <button class="btn yellowBtn mt-2 px-4">{{btText}}</button></a>
              </div>
            </div>
            <div class="col-sm-5 text-center mobileHide">
              <img src="{{ item.src }}" width="75%" />

            </div>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#expertsSlider" role="button" data-slide="prev">
        <img src="/assets/imgs/left _Arrow.svg" height="40px" />
      </a>
      <a class="carousel-control-next" href="#expertsSlider" role="button" data-slide="next">
        <img src="/assets/imgs/right_Arrow.svg" height="40px" />
      </a>
    </div>
  </div>
</div>
<app-footer></app-footer>
<!-- <img src="/src/assets/Plantation/Promotions/Alif-01.png"> -->
