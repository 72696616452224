import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

declare var window: any;


@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss'],
})
export class ProgramsComponent implements OnInit {
  selectedDay: string;
  pageTitle: any;
  public englishMode = false;
  videoItems: VideoItem[];
  videoItemsENG = [
    {
      name: 'Sembang-Sembang ASNB',
      description:
        ' Empower yourself with knowledge in finance where we invite industry experts to share their tips and expertise in finance.',
      dates: ['18, 19, 21 - 24 Nov 2020'],
      time: ['1.00 PM'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473404555',
      active: 'active',
    },
    {
      name: 'Forum Kewangan Syariah',
      description:
        ' Provides an engaging platform to discuss current Islamic finance topics.',
      dates: [''],
      time: ['4.30 PM - 20 Nov', '2:30 PM - 22 Nov', '5.00 PM - 23 Nov'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473404712',
    },
    {
      name: 'Makan Dulu',
      description:
        ' A laid-back talk show in a coffee shop setting to get the conversation going on topics such as finance, investment, entrepreneurship and the economy with our expert panellists who are industry leaders.',
      dates: ['19, 21, 24 Nov 2020'],
      time: ['1.30 PM'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473405444',
    },
    {
      name: 'Cikgu CEO',
      description: ` Gone are the days of formal settings and PowerPoint presentations! An easy-to-follow topical webinar series to encourage discussions among Malaysia's inquisitive teenagers.`,
      dates: ['18, 22 - 23 Nov 2020'],
      time: ['1.30 PM'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473884713',
    },
    {
      name: 'Lepak Je!',
      description:
        ' Hang out with our host and a variety of celebrities, personalities and influencers, as they execute fun challenges or answer your burning questions.',
      dates: ['18 - 19, 23 Nov 2020'],
      time: ['8.30 PM'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473405307',
    },
    {
      name: 'Pass the Mic!',
      description:
        ' Minggu Saham Digital’s Grand Concert, a never-been-done before virtual concert in Malaysia, will bring artistes from different genres to perform their music live online.',
      dates: ['21 Nov 2020'],
      time: ['8.30 PM'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473413431',
    },
    {
      name: 'Masak Macam Mak',
      description:
        ' There’s nothing quite like mom’s cooking. On Masak Macam Mak!, we get local favourite personalities and their moms to share their favourite recipes that have been passed on from generation to generation and capture light-hearted moments over their selected dishes. Brought to you by Sime Darby Plantation Berhad, guests for every episode of Masak Macam Mak! will cook using Minyak Masak Sayuran Alif.',
      dates: ['18 - 21 Nov 2020'],
      time: ['5.30 PM'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473405575',
    },
    {
      name: 'Dunia Kimia',
      description:
        ' A show that features out-of-this-world experiments and more interesting chemistry facts in a fun and engaging manner. Brought to you by CCM Berhad.',
      dates: ['20, 22 Nov 2020'],
      time: ['5.00 PM'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473415497',
    },
    {
      name: 'Betul ke, Doc?',
      description:
        ' In Betul Ke, Doc?, we take unusual health myths and put them to the test with medical professionals to bust those myths. Brought to you by Duopharma Biotech Berhad.',
      dates: ['19, 21 Nov 2020'],
      time: ['5.00 PM'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473404614',
    },
    {
      name: 'GG, Bro!',
      description:
        ' GG, Bro! presents a chance for viewers to be a part of a personality’s FIFA 21 fantasy league and battle it out live with another team!',
      dates: ['20, 22 Nov 2020'],
      time: ['8.30 PM'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473404734',
    },
    {
      name: 'Jumpa di 118 & Selesa',
      description:
        ' A look into Merdeka 118 and properties by S P Setia and Sime Darby Property Berhad.',
      dates: ['21 - 23 Nov 2020'],
      time: ['4.30 PM'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473405631',
    },
    {
      name: 'Kereta Kita',
      description:
        ' Tune in to the conversation between our celebrity host and guests as they talk about their favourite cars from Sime Darby Berhad, Perodua and UMW Holdings.',
      dates: ['21 - 23 Nov 2020'],
      time: ['3.00 PM'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473405134',
    },
    {
      name: 'My Cerita',
      description:
        ' A celebrity host visits PNB, ASNB and UMW to delve into the history and explore the interesting business functions of these companies.',
      dates: ['18,20 - 23 Nov 2020'],
      time: ['3.30 PM'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473413180',
    },
  ];

  videoItemsBM = [
    {
      name: 'Sembang-Sembang ASNB',
      description:
        ' Siri forum bual bicara membincangkan topik-topik utama di ASNB untuk memperkasakan diri anda dengan pengetahuan dalam bidang kewangan di mana kami menjemput pakar industri untuk berkongsi rahsia dan kepakaran mereka.',
      dates: ['18, 19, 21 - 24 Nov 2020'],
      time: ['1.00 tengah hari'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473404555',
      active: 'active',
    },
    {
      name: 'Forum Kewangan Syariah',
      description:
        ' Forum Kewangan Syariah’ adalah platform yang menarik untuk membincangkan topik kewangan Islam semasa.',
      dates: [''],
      time: [
        '20 Nov (4.30 petang)',
        '22 Nov (2.30 petang)',
        '23 Nov (5.00 petang)',
      ],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473404712',
    },
    {
      name: 'Makan Dulu',
      description:
        ' Siri bual bicara santai bertemakan ‘Kedai Kopi’ bersama panel Peneraju Industri membincangkan tentang kewangan, keusahawanan dan ekonomi.',
      dates: ['19, 21 & 24 Nov 2020'],
      time: ['1.30 tengah hari'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473405444',
    },
    {
      name: 'Cikgu CEO',
      description:
        ' Lupakan cara lama anda belajar bersama cikgu di dalam kelas.\nIkuti siri webinar yang amat mudah khas untuk remaja!',
      dates: ['18, 22 & 23 Nov 2020'],
      time: ['1.30 tengah hari'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473884713',
    },
    {
      name: 'Lepak Je!',
      description:
        ' Lepak Je mengajak anda bersantai bersama hos dan selebriti jemputan! Lebih menghiburkan lagi, tetamu akan diberikan cabaran serta ujian atau topik untuk dibincangkan, memang bukan hanya berbual kosong!',
      dates: ['18, 19 & 23 Nov 2020'],
      time: ['8.30 malam'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473405307',
    },
    {
      name: 'Pass the Mic!',
      description:
        ' Konsert Mega Minggu Saham secara maya yang menggabungkan ramai artis dari genre berbeza untuk menyanyi secara LIVE bersama-sama!',
      dates: ['21 Nov 2020'],
      time: ['8.30 malam'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473413431',
    },
    {
      name: 'Masak Macam Mak',
      description:
        ' Rancangan memasak yang menyeronokkan di mana para selebriti bersama ibu mereka akan memasak resipi kegemaran mereka, mengimbau kembali nostalgia dan berbual kisah di sebalik masakan mereka. Dibawakan kepada anda oleh Sime Darby Plantation Berhad, para tetamu akan masak dengan menggunakan Minyak Masak Sayuran Alif.',
      dates: ['18 - 21 Nov 2020'],
      time: ['5.30 petang'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473405575',
    },
    {
      name: 'Dunia Kimia',
      description:
        ' Dihoskan oleh CCM Berhad penonton akan dibawa untuk melihat eksperimen sains menarik yang pasti akan menghiburkan dan mendidik semua!',
      dates: ['20 & 22 Nov 2020'],
      time: ['5.00 petang'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473415497',
    },
    {
      name: 'Betul ke, Doc?',
      description:
        ' Jangan mudah percaya tip kesihatan yang dikongsi di WhatsApp & Internet, tanya saja ... ‘Betul Ke, Doc?‘ dengan pakar jemputan kami! Program ini dihoskan oleh Duopharma Biotech Berhad.',
      dates: ['19 & 21 Nov 2020'],
      time: ['5.00 petang'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473404614',
    },
    {
      name: 'GG, Bro!',
      description:
        ' GG, Bro! membawakan penonton untuk melihat cabaran antara selebriti dan personaliti terkenal di medan Esports, distrim secara live!',
      dates: ['20 & 22 Nov 2020'],
      time: ['8.30 malam'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473404734',
    },
    {
      name: 'Jumpa di 118 & Selesa',
      description:
        ' Meninjau sekitar pembinaan Merdeka 118 dan pembangunan lain di bawah S P Setia dan Sime Darby Property Berhad.',
      dates: ['21 - 23 Nov 2020'],
      time: ['4.30 petang'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473405631',
    },
    {
      name: 'Kereta Kita',
      description:
        ' Ikuti hos selebriti jemputan dan tetamu berbual mengenai kereta kegemaran mereka dari Sime Darby Berhad, Perodua dan UMW Holdings.',
      dates: ['21 - 23 Nov 2020'],
      time: ['3.00 petang'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473405134',
    },
    {
      name: 'My Cerita',
      description:
        ' Hos selebriti melawat PNB, ASNB dan UMW untuk menyelami sejarah dan meneroka fungsi perniagaan syarikat yang menarik.',
      dates: ['18, 20 - 23 Nov 2020'],
      time: ['3.30 petang'],
      poster: '/assets/imgs/poster.png',
      src: 'https://player.vimeo.com/video/473413180',
    },
  ];
  Schedules: Schedule[];
  SchedulesENG = [
    {
      date: '18.11.2020',
      day: 'Wednesday',
      scheduleSlots: [
        {
          timeslot: '12:30 PM - 1:00 PM',
          speaker: 'Kickoff',
          description: 'Official kick-off and giveaways worth over RM30,000',
        },
        {
          timeslot: '1:00 PM - 1:30 PM',
          speaker: 'Sembang-Sembang ASNB',
          description: 'Special guest: Suraya Zainudin (Author and Founder of the Blog RinggitOhRinggit)',
        },
        {
          timeslot: '1:30 PM - 2:00 PM',
          speaker: 'Cikgu CEO',
          description: 'Maybank Edition with special guest: Datuk Abdul Farid Alias (Group President & Chief Executive Officer, Maybank)',
        },
        {
          timeslot: '2:00 PM - 2:30 PM',
          speaker: 'Live Trivia',
          description: 'Maybank Edition (Over RM1,500 in prizes)',
        },
        {
          timeslot: '2.30 PM - 3:00 PM',
          speaker: 'ASB-Iclif MBA Masterclass Series',
          description: 'Platform Strategies by Melati Nungsari',
        },
        {
          timeslot: '3:00 PM - 3:30 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '3:30 PM - 4:00 PM',
          speaker: 'My Cerita',
          description: 'PNB Edition',
        },
        {
          timeslot: '4:00 PM - 4:30 PM',
          speaker: 'Live Trivia',
          description: 'MIDF Edition (Over RM1,500 in prizes)',
        },
        {
          timeslot: '4:30 PM - 5:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '5:00 PM - 5:30 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '5:30 PM - 6:00 PM',
          speaker: 'Masak Macam Mak',
          description: 'Special guests: Fizo Omar (Sime Darby Plantation Edition)',
        },
        {
          timeslot: '6:00 PM - 6:30 PM',
          speaker: 'Live Trivia',
          description: 'Perodua Edition (Over RM1,500 in prizes)',
        },
        {
          timeslot: '6:30 PM - 7:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '7:00 PM - 7:30 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '7.30 PM - 8:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '8:00 PM - 8:30 PM',
          speaker: 'Live Trivia',
          description: 'Sime Darby Berhad Edition (Over RM1,500 in prizes)',
        },
        {
          timeslot: '8:30 PM - 9:30 PM',
          speaker: 'Lepak Je',
          description: 'Lepak with Fikry Ibrahim and Nana Al Haleq',
        },
        {
          timeslot: '9:30 PM - 10:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
      ],
    },
    {
      date: '19.11.2020',
      day: 'thursday',
      scheduleSlots: [
        {
          timeslot: '12:30 PM - 1:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '1:00 PM - 1:30 PM',
          speaker: 'Sembang-Sembang ASNB',
          description: 'Special Guest: Hann Liew (Chief Executive Officer RinggitPlus.com)',
        },
        {
          timeslot: '1:30 PM - 2:00 PM',
          speaker: 'Makan Dulu',
          description: 'Special Guest: Dato’ Ahmad Fuaad Kenali (President & Group CEO), Dato’ Abdul Rashid (President Manufacturing & Engineering Division), Encik Megat Shahrul Azmir Nordin (President Equipment Division) & Mr Ravindran Kurusamy (President UMW Toyota Motor Sdn Bhd) - UMW Edition',
        },
        {
          timeslot: '2:00 PM - 2:30 PM',
          speaker: 'Live Trivia',
          description: 'UMW Edition (Over RM1,500 in prizes).',
        },
        {
          timeslot: '2.30 PM - 3:00 PM',
          speaker: 'ASB-Iclif MBA Masterclass Series',
          description:
            'The Strategic Intra/Entrepreneurial Organisation - Nail it, Scale it, Sail it by Loredana Padurean',
        },
        {
          timeslot: '3.00 PM - 4:30 PM',
          speaker: 'Grand Launch of Minggu Saham Digital',
          description: '',
        },
        {
          timeslot: '4:30 PM - 5:00 PM',
          speaker: 'Brand Video',
          description: '',
        },
        {
          timeslot: '5:00 PM - 5:30 PM',
          speaker: 'Betul Ke Doc',
          description: 'Special Guest: Dr Zanariah Hussein by Duopharma',
        },
        {
          timeslot: '5:30 PM - 6:00 PM',
          speaker: 'Masak Macam Mak',
          description: 'Special guests: Elfira Loy (Sime Darby Plantation Edition)',
        },
        {
          timeslot: '6:00 PM - 6:30 PM',
          speaker: 'Live Trivia',
          description: 'Velesto Energy Edition (Over RM1500 in prizes)',
        },
        {
          timeslot: '6:30 PM - 7:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '7.00 PM - 7:30 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '7.30 PM - 8:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '8:00 PM - 8:30 PM',
          speaker: 'Live Trivia',
          description: 'S P Setia Edition (Over RM1500 in prizes)',
        },
        {
          timeslot: '8:30 PM - 9:30 PM',
          speaker: 'Lepak Je',
          description: 'Lepak with Afieq Shazwan and Remy Ishak',
        },
        {
          timeslot: '9:30 PM - 10:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
      ],
    },
    {
      date: '20.11.2020',
      day: 'friday',
      scheduleSlots: [
        {
          timeslot: '12:30 PM - 1:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '1:00 PM - 2:30 PM',
          speaker: 'Solat Jumaat',
          description: '',
        },
        {
          timeslot: '2.30 PM - 3:00 PM',
          speaker: 'ASB-Iclif MBA Masterclass Series',
          description:
            'The Dollar, The Renminbi and the International Monetary System by Hans Genberg',
        },
        {
          timeslot: '3:00 PM - 3:30 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '3:30 PM - 4:00 PM',
          speaker: 'My Cerita',
          description: 'PNB Edition',
        },
        {
          timeslot: '4:00 PM - 4:30 PM',
          speaker: 'Live Trivia',
          description: 'Sime Darby Plantation Edition (Over RM1500 in prizes)',
        },
        {
          timeslot: '4:30 PM - 5:00 PM',
          speaker: 'Forum Kewangan Syariah',
          description: 'Special guests: Imam Muda Asyraf & Ustaz Wan Jemizan W. Deraman, Head of Shariah & Business Advisory Department Takaful Ikhlas Family Berhad - MNRB Edition',
        },

        {
          timeslot: '5:00 PM - 5:30 PM',
          speaker: 'Dunia Kimia',
          description: 'Special Guests Haziq Ajar by CCM',
        },
        {
          timeslot: '5:30 PM - 6:00 PM',
          speaker: 'Masak Macam Mak',
          description: 'Special guests: Sherson Lian (Sime Darby Plantation Edition)',
        },
        {
          timeslot: '6:00 PM - 6:30 PM',
          speaker: 'Live Trivia',
          description: 'Sapura Energy Edition (Over RM1500 in prizes)',
        },
        {
          timeslot: '6:30 PM - 7:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '7:00 PM - 7:30 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '7.30 PM - 8:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '8:00 PM - 8:30 PM',
          speaker: 'Live Trivia',
          description: 'Sime Darby Property Edition (Over RM1,500 in prizes)',
        },
        {
          timeslot: '8:30 PM - 9:30 PM',
          speaker: 'GG Bro',
          description: 'FIFA 2021 with Nureddy Nusral (Daddy Hood) and Azim Ikromi (Draxx)',
        },
        {
          timeslot: '9:30 PM - 10:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
      ],
    },
    {
      date: '21.11.2020',
      day: 'saturday',
      scheduleSlots: [
        {
          timeslot: '12.30 PM - 1:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '1:00 PM - 1:30 PM',
          speaker: 'Sembang-Sembang ASNB',
          description: 'Special Guest: Datuk Nadzim Johan, Chairman of PPIM and Social Activist',
        },
        {
          timeslot: '1.30 PM - 2:00 PM',
          speaker: 'Makan Dulu',
          description: 'Special Guests: Datuk Jeffry Mohd Ali (Head of Consumer Finance, Community Financial Services), Hazlinda Abdul Hamid (Head of Business Development SME Banking, Community Financial Services) & Goh How Khiam (Head of Marketing, Content, UX & Analytics, Virtual Banking & Payments, Community Financial Services) - Maybank Edition',
        },
        {
          timeslot: '2.00 PM - 2:30 PM',
          speaker: 'Live Trivia Bonanza',
          description: 'Perodua Edition (Over RM2,500 in prizes)',
        },
        {
          timeslot: '2.30 PM - 3:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '3:00 PM - 3:30 PM',
          speaker: 'Kereta Kita',
          description: 'UMW Edition',
        },
        {
          timeslot: '3:30 PM - 4:00 PM',
          speaker: 'My Cerita',
          description: 'ASNB Edition',
        },
        {
          timeslot: '4:00 PM - 4:30 PM',
          speaker: 'Live Trivia Bonanza',
          description: 'Maybank Edition (Over RM2,500 in prizes)',
        },
        {
          timeslot: '4:30 PM - 5:00 PM',
          speaker: 'Selesa',
          description:
            'A preview of Setia Alam Sari with Datuk Tan Hon Lim & Norlina Mohd Noor',
        },

        {
          timeslot: '5:00 PM - 5:30 PM',
          speaker: 'Betul Ke Doc',
          description: 'Special Guest: Dr Rajini Sarvananthan, Consultant Developmental and General Paediatrician - Duopharma Edition',
        },
        {
          timeslot: '5:30 PM - 6:00 PM',
          speaker: 'Masak Macam Mak',
          description: `Special guests: Dato Fazley Yaakob (Sime Darby Plantation Edition)`,
        },
        {
          timeslot: '6:00 PM - 6:30 PM',
          speaker: 'Live Trivia Bonanza',
          description: 'S P Setia Edition (Over RM2500 in prizes)',
        },
        {
          timeslot: '6:30 PM - 7:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '7.00 PM - 7:30 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '7.30 PM - 8:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '8:00 PM - 8:30 PM',
          speaker: 'Live Trivia Bonanza',
          description: 'Sime Darby Plantation Edition (Over RM2500 in prizes)',
        },
        {
          timeslot: '8:30 PM - 10:30 PM',
          speaker: 'Pass the Mic',
          description: 'Grand Concert with Dayang Nurfaizah, Hael Husaini, Akim Ahmad, K-Clique, DJ CZA and Aman RA',
        },
      ],
    },
    {
      date: '22.11.2020',
      day: 'sunday',
      scheduleSlots: [
        {
          timeslot: '12:30 PM - 1:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '1:00 PM - 1:30 PM',
          speaker: 'Sembang-Sembang ASNB',
          description: 'Special Guest: Syukor Hashim (General Manager, Public Gold Marketing Sdn Bhd)',
        },
        {
          timeslot: '1.30 PM - 2:00 PM',
          speaker: 'Cikgu CEO',
          description: 'Special Guest: Dato Ahmad Fuaad Kenali, President & Chief Executive Officer, UMW Holdings - UMW Edition',
        },
        {
          timeslot: '2.00 PM - 2:30 PM',
          speaker: 'Live Trivia Bonanza',
          description: 'MIDF Edition (Over RM2,500 in prizes)',
        },
        {
          timeslot: '2.30 PM - 3:00 PM',
          speaker: 'Forum Kewangan Syariah',
          description: 'Special guests En. Nor Azman Zainal, President & CEO Takaful Ikhlas Family Berhad & Naz Rahman - MNRB Edition',
        },
        {
          timeslot: '3:00 PM - 3:30 PM',
          speaker: 'Kereta Kita',
          description: 'Sime Darby Berhad Edition',
        },
        {
          timeslot: '3:30 PM - 4:00 PM',
          speaker: 'My Cerita',
          description: 'UMW Edition',
        },
        {
          timeslot: '4:00 PM - 4:30 PM',
          speaker: 'Live Trivia Bonanza',
          description: 'UMW Edition (Over RM2,500 in prizes)',
        },
        {
          timeslot: '4:30 PM - 5:00 PM',
          speaker: 'Selesa',
          description: 'A preview of Serini Melawati with Ameera Fatiena Abdul Hadi, Sales Team Lead, Sime Darby Property',
        },

        {
          timeslot: '5:00 PM - 5:30 PM',
          speaker: 'Dunia Kimia',
          description: 'Special Guest En Haziq Ajar by CCM',
        },
        {
          timeslot: '5:30 PM - 6:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '6:00 PM - 6:30 PM',
          speaker: 'Live Trivia Bonanza',
          description: 'Sime Darby Property Edition (Over RM2500 in prizes)',
        },
        {
          timeslot: '6:30 PM - 7:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '7.00 PM - 7:30 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '7.30 PM - 8:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '8:00 PM - 8:30 PM',
          speaker: 'Live Trivia Bonanza',
          description: 'Sime Darby Berhad Edition (Over RM2,500 in prizes)',
        },
        {
          timeslot: '8:30 PM - 9:30 PM',
          speaker: 'GG Bro',
          description: 'FIFA 2021 with Nureddy Nusral (Daddy Hood) and Azim Ikromi (Draxx)',
        },
        {
          timeslot: '9:30 PM - 10:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
      ],
    },
    {
      date: '23.11.2020',
      day: 'monday',
      scheduleSlots: [
        {
          timeslot: '12:30 PM - 1:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '1:00 PM - 1:30 PM',
          speaker: 'Sembang-Sembang ASNB',
          description: 'Special Guest: Suzardi Maulan (Pak Di) (Penasihat Kewangan Bertauliah)',
        },
        {
          timeslot: '1.30 PM - 2:00 PM',
          speaker: 'Cikgu CEO',
          description: 'Special Guest: Ahmad Zulqarnain Onn, President and Group Chief Executive of PNB - PNB Edition',
        },
        {
          timeslot: '2.00 PM - 2:30 PM',
          speaker: 'Live Trivia',
          description: 'MNRB Edition (Over RM1,500 in prizes)',
        },
        {
          timeslot: '2.30 PM - 3:00 PM',
          speaker: 'ASB-Iclif MBA Masterclass Series',
          description: `Climate Action Leadership: Tackling the World's Biggest Challenge by Renato Lima-de-Oliveria`,
        },
        {
          timeslot: '3:00 PM - 3:30 PM',
          speaker: 'Kereta Kita',
          description: 'Perodua',
        },
        {
          timeslot: '3:30 PM - 4:00 PM',
          speaker: 'My Cerita',
          description: 'ASNB Edition',
        },
        {
          timeslot: '4:00 PM - 4:30 PM',
          speaker: 'Live Trivia',
          description: 'ASNB Edition (Over RM1500 in prizes)',
        },
        {
          timeslot: '4:30 PM - 5:00 PM',
          speaker: 'Jumpa Di 118',
          description:
            'A Special Preview of Merdeka 118 with En. Rick Ramli & Tengku Dato’ Ab. Aziz bin Tengku Mahmud',
        },

        {
          timeslot: '5:00 PM - 5:30 PM',
          speaker: 'Forum Kewangan Syariah',
          description:
            'Special Guest: Ustazah Isfadiah Mohd Dasuki - Pengarah Fitrah Guidance & Consultancy - ASNB Edition',
        },
        {
          timeslot: '5:30 PM - 6:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '6:00 PM - 6:30 PM',
          speaker: 'Live Trivia',
          description: 'CCM Edition (Over RM1,500 in prizes)',
        },
        {
          timeslot: '6:30 PM - 7:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '7:00 PM - 7:30 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '7.30 PM - 8:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '8:00 PM - 8:30 PM',
          speaker: 'Live Trivia',
          description: 'Duopharma Edition (Over RM1500 in prizes)',
        },
        {
          timeslot: '8:30 PM - 9:30 PM',
          speaker: 'Lepak Je',
          description: 'Lepak with Celebrity Guests: Dr Say Shazril & Rozita Che Wan',
        },
        {
          timeslot: '9:30 PM - 10:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
      ],
    },
    {
      date: '24.11.2020',
      day: 'tuesday',
      scheduleSlots: [
        {
          timeslot: '12:30 PM - 1:00 PM',
          speaker: 'Our Partners',
          description: '',
        },
        {
          timeslot: '1:00 PM - 1:30 PM',
          speaker: 'Sembang-Sembang ASNB',
          description: '',
        },
        {
          timeslot: '1.30 PM - 2:00 PM',
          speaker: 'Makan Dulu',
          description: 'Special Guest: Wan Ahmad Satria Wan Hussein (Head of Client Advisory Solutions & Origination, MIDF Investment), Azizi Hj Mustafa (Head of Development Finance Division, MIDF Berhad) & Imran Yassin Md Yusof (Head of Research, MIDF Investment) - MIDF Edition',
        },
        {
          timeslot: '2.00 PM - 2:30 PM',
          speaker: 'Live Trivia',
          description: 'Prolintas Edition (Over RM1,500 in prizes)',
        },
        {
          timeslot: '2.30 PM - 3:00 PM',
          speaker: 'ASB-Iclif MBA Masterclass Series',
          description: 'The Leadership Journey by Thun Thamrongnawasawat',
        },
        {
          timeslot: '3:00 PM - 3:30 PM',
          speaker: 'Thank you to our partners',
          description: '',
        },
        {
          timeslot: '3:30 PM - 4:00 PM',
          speaker: 'Closing Ceremony',
          description: '',
        },
      ],
    },
  ];
  SchedulesBM = [
    {
      date: '18.11.2020',
      day: 'Rabu',
      scheduleSlots: [
        {
          timeslot: '12.30 tghr - 1.00 tghr',
          speaker: 'Kita Bermula!',
          description:
            'Program bermula dan pemberian hadiah bernilai lebih daripada RM30,000',
        },
        {
          timeslot: '1.00 tghr - 1.30 tghr',
          speaker: 'Sembang-Sembang ASNB',
          description: 'Tetamu Istimewa: Suraya Zainudin (Penulis dan Pengasas Blog RinggitOhRinggit)',
        },
        {
          timeslot: '1.30 tghr - 2.00 ptg',
          speaker: 'Cikgu CEO',
          description: 'Edisi Maybank bersama tetamu istimewa:  Datuk Abdul Farid Alias (Group President & Chief Executive Officer, Maybank)',
        },
        {
          timeslot: '2.00 ptg - 2.30 ptg',
          speaker: 'Live Trivia',
          description: 'Edisi Maybank (Hadiah bernilai lebih daripada RM1,500)',
        },
        {
          timeslot: '2.30 ptg - 3.00 ptg',
          speaker: 'ASB-Iclif MBA Masterclass Series',
          description: 'Platform Strategies oleh Melati Nungsari',
        },
        {
          timeslot: '3.00 ptg - 3.30 ptg',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '3.30 ptg - 4.00 ptg',
          speaker: 'My Cerita',
          description: 'Edisi PNB',
        },
        {
          timeslot: '4.00 ptg - 4.30 ptg',
          speaker: 'Live Trivia',
          description: 'Edisi MIDF (Hadiah bernilai lebih daripada RM1,500)',
        },
        {
          timeslot: '4.30 ptg - 5.00 ptg',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '5.00 ptg - 5.30 ptg',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '5.30 ptg - 6.00 ptg',
          speaker: 'Masak Macam Mak',
          description: 'Tetamu Istimewa: Fizo Omar (Edisi Sime Darby Plantation)',
        },
        {
          timeslot: '6.00 ptg - 6.30 ptg',
          speaker: 'Live Trivia',
          description: 'Edisi Perodua (Hadiah bernilai lebih daripada RM1,500)',
        },
        {
          timeslot: '6.30 ptg - 7.00 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '7.00 mlm - 7.30 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '7.30 mlm - 8.00 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },

        {
          timeslot: '8.00 mlm - 8.30 mlm',
          speaker: 'Live Trivia',
          description:
            'Edisi Sime Darby Berhad (Hadiah bernilai lebih daripada RM1,500)',
        },
        {
          timeslot: '8.30 mlm - 9.30 mlm',
          speaker: 'Lepak Je',
          description: 'Lepak dengan Fikry Ibrahim dan Nana Al Haleq',
        },
        {
          timeslot: '9.30 mlm - 10.00 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
      ],
    },
    {
      date: '19.11.2020',
      day: 'Khamis',
      scheduleSlots: [
        {
          timeslot: '12.30 tghr - 1.00 tghr',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '1.00 tghr - 1.30 tghr',
          speaker: 'Sembang-Sembang ASNB',
          description: 'Tetamu Istimewa: Hann Liew (Ketua Pegawai Eksekutif RinggitPlus.com)',
        },
        {
          timeslot: '1.30 tghr - 2.00 ptg',
          speaker: 'Makan Dulu',
          description: 'Tetamu Istimewa: Dato Ahmad Fuaad Kenali (President & Group CEO), Dato Abdul Rashid (President Manufacturing & Engineering Division), Encik Megat Shahrul Azmir Nordin (President Equipment Division) & Mr Ravindran Kurusamy (President UMW Toyota Motor Sdn Bhd) - Edisi UMW',
        },
        {
          timeslot: '2.00 ptg - 2.30 ptg',
          speaker: 'Live Trivia',
          description: 'Edisi UMW (Hadiah bernilai lebih daripada RM1,500)',
        },
        {
          timeslot: '2.30 ptg - 3.00 ptg',
          speaker: 'ASB-Iclif MBA Masterclass Series',
          description:
            'The Strategic Intra/Entrepreneurial Organisation - Nail it, Scale it, Sail it oleh Loredana Padurean',
        },
        {
          timeslot: '3.00 ptg - 4.30 ptg',
          speaker: 'Pelancaran Rasmi Minggu Saham Digital ',
          description: '',
        },
        {
          timeslot: '4.30 ptg - 5.00 ptg',
          speaker: 'Video Rasmi',
          description: '',
        },
        {
          timeslot: '5.00 ptg - 5.30 ptg',
          speaker: 'Betul Ke Doc',
          description: 'Tetamu Istimewa Dr Zanariah Hussein oleh Duopharma',
        },
        {
          timeslot: '5.30 ptg - 6.00 ptg',
          speaker: 'Masak Macam Mak',
          description: 'Tetamu Istimewa: Elfira Loy (Edisi Sime Darby Plantation)',
        },
        {
          timeslot: '6.00 ptg - 6.30 ptg',
          speaker: 'Live Trivia',
          description: 'Edisi Velesto Energy (Hadiah bernilai lebih daripada RM1,500)',
        },
        {
          timeslot: '6.30 ptg - 7.00 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '7.00 mlm - 7.30 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '7.30 mlm - 8.00 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '8.00 mlm - 8.30 mlm',
          speaker: 'Live Trivia Bonanza',
          description:
            'Edisi S P Setia (Hadiah bernilai lebih daripada RM2,500)',
        },
        {
          timeslot: '8.30 mlm - 9.30 mlm',
          speaker: 'Lepak Je',
          description: 'Lepak dengan Afieq Shazwan dan Remy Ishak',
        },
        {
          timeslot: '9.30 mlm - 10.00 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
      ],
    },
    {
      date: '20.11.2020',
      day: 'Jumaat',
      scheduleSlots: [
        {
          timeslot: '12.30 tghr - 1.00 tghr',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '1.00 tghr - 2.30 tghr',
          speaker: 'Solat Jumaat',
          description: '',
        },
        {
          timeslot: '2.30 ptg - 3.00 ptg',
          speaker: 'ASB-Iclif MBA Masterclass Series',
          description:
            'The Dollar, The Renminbi and the International Monetary System oleh Hans Genberg',
        },
        {
          timeslot: '3.00 ptg - 3.30 ptg',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '3.30 ptg - 4.00 ptg',
          speaker: 'My Cerita',
          description: 'Edisi PNB',
        },
        {
          timeslot: '4.00 ptg - 4.30 ptg',
          speaker: 'Live Trivia',
          description: 'Edisi Sime Darby Plantation (Hadiah bernilai lebih daripada RM1,500)',
        },
        {
          timeslot: '4.30 ptg - 5.00 ptg',
          speaker: 'Forum Kewangan Syariah',
          description: 'Tetamu Istimewa: Imam Muda Asyraf & Ustaz Wan Jemizan W. Deraman, Head of Shariah & Business Advisory Department Takaful Ikhlas Family Berhad - Edisi MNRB',
        },

        {
          timeslot: '5.00 ptg - 5.30 ptg',
          speaker: 'Dunia Kimia',
          description: 'Tetamu Istimewa Haziq Ajar oleh CCM',
        },
        {
          timeslot: '5.30 ptg - 6.00 ptg',
          speaker: 'Masak Macam Mak',
          description: 'Tetamu Istimewa: Sherson Lian (Edisi Sime Darby Plantation)',
        },
        {
          timeslot: '6.00 ptg - 6.30 ptg',
          speaker: 'Live Trivia',
          description:
            'Edisi Sapura Energy (Hadiah bernilai lebih daripada RM1,500)',
        },
        {
          timeslot: '6.30 ptg - 7.00 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '7.00 mlm - 7.30 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '7.30 mlm - 8.00 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '8.00 mlm - 8.30 mlm',
          speaker: 'Live Trivia Bonanza',
          description:
            'Edisi Sime Darby Property (Hadiah bernilai lebih daripada RM2,500)',
        },
        {
          timeslot: '8.30 mlm - 9.30 mlm',
          speaker: 'GG Bro',
          description: 'FIFA 2021 bersama Nureddy Nusral (Daddy Hood) and Azim Ikromi (Draxx)',
        },
        {
          timeslot: '9.30 mlm - 10.00 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
      ],
    },
    {
      date: '21.11.2020',
      day: 'Sabtu',
      scheduleSlots: [
        {
          timeslot: '12.30 tghr - 1.00 tghr',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '1.00 tghr - 1.30 tghr',
          speaker: 'Sembang-Sembang ASNB',
          description: 'Tetamu Istimewa: Datuk Nadzim Johan (Pengerusi Persatuan Pengguna Islam Malaysia (PPM) dan Aktivis Masyarakat)',
        },
        {
          timeslot: '1.30 tghr - 2.00 ptg',
          speaker: 'Makan Dulu',
          description: 'Tetamu Istimewa: Datuk Jeffry Mohd Ali (Head of Consumer Finance, Community Financial Services), Hazlinda Abdul Hamid (Head of Business Development SME Banking, Community Financial Services) & Goh How Khiam (Head of Marketing, Content, UX & Analytics, Virtual Banking & Payments, Community Financial Services) - Edisi Maybank',
        },
        {
          timeslot: '2.00 ptg - 2.30 ptg',
          speaker: 'Live Trivia Bonanza',
          description: 'Edisi Perodua (Hadiah bernilai lebih daripada RM2,500)',
        },
        {
          timeslot: '2.30 ptg - 3.00 ptg',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '3.00 ptg - 3.30 ptg',
          speaker: 'Kereta Kita',
          description: 'Edisi UMW',
        },
        {
          timeslot: '3.30 ptg - 4.00 ptg',
          speaker: 'My Cerita',
          description: 'Edisi ASNB',
        },
        {
          timeslot: '4.00 ptg - 4.30 ptg',
          speaker: 'Live Trivia Bonanza',
          description: 'Edisi Maybank (Hadiah bernilai lebih daripada RM2,500)',
        },
        {
          timeslot: '4.30 ptg - 5.00 ptg',
          speaker: 'Selesa',
          description:
            'Prebiu: Setia Alam Sari bersama Datuk Tan Hon Lim & Norlina Mohd Noor',
        },

        {
          timeslot: '5.00 ptg - 5.30 ptg',
          speaker: 'Betul Ke Doc',
          description: 'Tetamu Isitmewa: Dr Rajini Sarvananthan,Consultant Developmental and General Paediatrician - Edisi Duopharma',
        },
        {
          timeslot: '5.30 ptg - 6.00 ptg',
          speaker: 'Masak Macam Mak',
          description: `Tetamu Istimewa: Dato Fazley Yaakob (Edisi Sime Darby Plantation)`,
        },
        {
          timeslot: '6.00 ptg - 6.30 ptg',
          speaker: 'Live Trivia Bonanza',
          description:
            'Edisi S P Setia (Hadiah bernilai lebih daripada RM2,500)',
        },
        {
          timeslot: '6.30 ptg - 7.00 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '7.00 mlm - 7.30 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '7.30 mlm - 8.00 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '8.00 mlm - 8.30 mlm',
          speaker: 'Live Trivia Bonanza',
          description:
            'Edisi Sime Darby Plantation (Hadiah bernilai lebih daripada RM2,500)',
        },
        {
          timeslot: '8.30 mlm - 10.30 mlm',
          speaker: 'Pass the Mic',
          description: 'Konsert Mega bersama Dayang Nurfaizah, Hael Husaini, Akim Ahmad, K-Clique, DJ CZA dan Aman RA',
        },
      ],
    },
    {
      date: '22.11.2020',
      day: 'Ahad',
      scheduleSlots: [
        {
          timeslot: '12.30 tghr - 1.00 tghr',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '1.00 tghr - 1.30 tghr',
          speaker: 'Sembang-Sembang ASNB',
          description: 'Tetamu Istimewa: Syukor Hashim (Pengurus Besar, Public Gold Marketing Sdn Bhd)',
        },
        {
          timeslot: '1.30 tghr - 2.00 ptg',
          speaker: 'Cikgu CEO',
          description: 'Tetamu Istimewa: Dato Ahmad Fuaad Kenali, President & Chief Executive Officer, UMW Holdings - Edisi UMW',
        },
        {
          timeslot: '2.00 ptg - 2.30 ptg',
          speaker: 'Live Trivia Bonanza',
          description: 'Edisi MIDF (Hadiah bernilai lebih daripada RM2,500)',
        },
        {
          timeslot: '2.30 ptg - 3.00 ptg',
          speaker: 'Forum Kewangan Syariah',
          description:
            'Tetamu Istimewa: En. Nor Azman Zainal, Presiden & Ketua Pegawai Eksekutif Takaful Ikhlas Family Berhad & Naz Rahman - Edisi MNRB',
        },
        {
          timeslot: '3.00 ptg - 3.30 ptg',
          speaker: 'Kereta Kita',
          description: 'Edisi Sime Darby Berhad',
        },
        {
          timeslot: '3.30 ptg - 4.00 ptg',
          speaker: 'My Cerita',
          description: 'Edisi UMW',
        },
        {
          timeslot: '4.00 ptg - 4.30 ptg',
          speaker: 'Live Trivia Bonanza',
          description: 'Edisi UMW (Hadiah bernilai lebih daripada RM2,500)',
        },
        {
          timeslot: '4.30 ptg - 5.00 ptg',
          speaker: 'Selesa',
          description: 'Prebiu: Serini Melawati bersama Ameera Fatiena Abdul Hadi, Sales Team Lead, Sime Darby Property',
        },

        {
          timeslot: '5.00 ptg - 5.30 ptg',
          speaker: 'Dunia Kimia',
          description: 'Tetamu Istimewa En Haziq Ajar oleh CCM',
        },
        {
          timeslot: '5.30 ptg - 6.00 ptg',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '6.00 ptg - 6.30 ptg',
          speaker: 'Live Trivia Bonanza',
          description:
            'Edisi Sime Darby Property (Hadiah bernilai lebih daripada RM2,500)',
        },
        {
          timeslot: '6.30 ptg - 7.00 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '7.00 mlm - 7.30 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '7.30 mlm - 8.00 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '8.00 mlm - 8.30 mlm',
          speaker: 'Live Trivia Bonanza',
          description:
            'Edisi Sime Darby Berhad (Hadiah bernilai lebih daripada RM2,500)',
        },
        {
          timeslot: '8.30 mlm - 9.30 mlm',
          speaker: 'GG Bro',
          description: 'FIFA 2021 bersama Nureddy Nusral (Daddy Hood) and Azim Ikromi (Draxx)',
        },
        {
          timeslot: '9.30 mlm - 10.00 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
      ],
    },
    {
      date: '23.11.2020',
      day: 'Isnin',
      scheduleSlots: [
        {
          timeslot: '12.30 tghr - 1.00 tghr',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '1.00 tghr - 1.30 tghr',
          speaker: 'Sembang-Sembang ASNB',
          description: 'Tetamu Istimewa: Suzardi Maulan (Pak Di) (Penasihat Kewangan Bertauliah)',
        },
        {
          timeslot: '1.30 tghr - 2.00 ptg',
          speaker: 'Cikgu CEO',
          description: 'Tetamu Istimewa: Ahmad Zulqarnain Onn, President and Group Chief Executive of PNB - Edisi PNB',
        },
        {
          timeslot: '2.00 ptg - 2.30 ptg',
          speaker: 'Live Trivia',
          description: 'Edisi MNRB (Hadiah bernilai lebih daripada RM1,500)',
        },
        {
          timeslot: '2.30 ptg - 3.00 ptg',
          speaker: 'ASB-Iclif MBA Masterclass Series',
          description: `Climate Action Leadership: Tackling the World's Biggest Challenge oleh Renato Lima-de-Oliveria`,
        },
        {
          timeslot: '3.00 ptg - 3.30 ptg',
          speaker: 'Kereta Kita',
          description: 'Perodua',
        },
        {
          timeslot: '3.30 ptg - 4.00 ptg',
          speaker: 'My Cerita',
          description: 'Edisi ASNB',
        },
        {
          timeslot: '4.00 ptg - 4.30 ptg',
          speaker: 'Live Trivia',
          description: 'Edisi ASNB (Hadiah bernilai lebih daripada RM1,500)',
        },
        {
          timeslot: '4.30 ptg - 5.00 ptg',
          speaker: 'Jumpa Di 118',
          description:
            'Prebiu istimewa bangunan Merdeka 118 bersama  Rick Ramli & Tengku Dato’ Ab. Aziz bin Tengku Mahmud',
        },

        {
          timeslot: '5.00 ptg - 5.30 ptg',
          speaker: 'Forum Kewangan Syariah',
          description:
            'Tetamu Istimewa: Ustazah Isfadiah Mohd Dasuki, Pengarah Fitrah Guidance & Consultancy - Edisi ASNB',
        },
        {
          timeslot: '5.30 ptg - 6.00 ptg',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '6.00 ptg - 6.30 ptg',
          speaker: 'Live Trivia',
          description: 'Edisi CCM (Hadiah bernilai lebih daripada RM1,500)',
        },
        {
          timeslot: '6.30 ptg - 7.00 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '7.00 mlm - 7.30 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '7.30 mlm - 8.00 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '8.00 mlm - 8.30 mlm',
          speaker: 'Live Trivia',
          description:
            'Edisi Duopharma (Hadiah bernilai lebih daripada RM1,500)',
        },
        {
          timeslot: '8.30 mlm - 9.30 mlm',
          speaker: 'Lepak Je',
          description: 'Lepak dengan Tetamu Selebriti: Dr Say Shazril & Rozita Che Wan',
        },
        {
          timeslot: '9.30 mlm - 10.00 mlm',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
      ],
    },
    {
      date: '24.11.2020',
      day: 'Selasa',
      scheduleSlots: [
        {
          timeslot: '12.30 tghr - 1.00 tghr',
          speaker: 'Rakan Korporat Kami',
          description: '',
        },
        {
          timeslot: '1.00 tghr - 1.30 tghr',
          speaker: 'Sembang-Sembang ASNB',
          description: '',
        },
        {
          timeslot: '1.30 tghr - 2.00 ptg',
          speaker: 'Makan Dulu',
          description: 'Tetamu Istimewa: Wan Ahmad Satria Wan Hussein (Head of Client Advisory Solutions & Origination, MIDF Investment), Azizi Hj Mustafa (Head of Development Finance Division, MIDF Berhad) & Imran Yassin Md Yusof (Head of Research, MIDF Investment) - Edisi MIDF',
        },
        {
          timeslot: '2.00 ptg - 2.30 ptg',
          speaker: 'Live Trivia',
          description:
            'Edisi Prolintas (Hadiah bernilai lebih daripada RM1,500)',
        },
        {
          timeslot: '2.30 ptg - 3.00 ptg',
          speaker: 'ASB-Iclif MBA Masterclass Series',
          description: 'The Leadership Journey oleh Thun Thamrongnawasawat',
        },
        {
          timeslot: '3.00 ptg - 3.30 ptg',
          speaker: 'Terima Kasih kepada Rakan Korporat',
          description: '',
        },
        {
          timeslot: '3.30 ptg - 4.00 ptg',
          speaker: 'Majlis Penutupan',
          description: '',
        },
      ],
    },
  ];
  constructor(private service: DataService) { }

  ngOnInit(): void {
    this.service.language.subscribe((lang) => {
      if (lang == 'ENG') {
        this.videoItems = this.videoItemsENG;
        this.Schedules = this.SchedulesENG;
        this.englishMode = true;
        this.selectedDay = 'Wednesday';
        this.pageTitle = 'Programmes';
      } else {
        this.videoItems = this.videoItemsBM;
        this.Schedules = this.SchedulesBM;
        this.englishMode = false;
        this.selectedDay = 'Rabu';
        this.pageTitle = 'program';
      }
    });
  }
  public languageChange = (data: any) => {


    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Language Switch',
      'eventCallback': function () {
        console.log('Language Switch Event Tags Triggered and Fired');
      }
    });
    if (data == 'ENG') {
      this.service.language.next('ENG');
      this.videoItems = this.videoItemsENG;
      this.Schedules = this.SchedulesENG;
      this.englishMode = true;
      this.pageTitle = 'Programmes';
    } else if (data == 'BM') {
      this.videoItems = this.videoItemsBM;
      this.Schedules = this.SchedulesBM;
      this.service.language.next('BM');
      this.englishMode = false;
      this.pageTitle = 'program';
    }
  };
}

interface VideoItem {
  name: string;
  description: string;
  dates: string[];
  time: string[];
  poster: string;
  src: string;
  active?: string;
}
interface Schedule {
  day: string;
  date: string;
  scheduleSlots: ScheduleSlot[];
}
interface ScheduleSlot {
  timeslot: string;
  speaker: string;
  description: string;
}
