import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
declare var $: any;
declare var window: any;


@Component({
  selector: 'app-sime-darby',
  templateUrl: './sime-darby.component.html',
  styleUrls: ['./sime-darby.component.scss'],
})
export class SimeDarbyComponent implements OnInit {
  constructor(public service: DataService, public active: ActivatedRoute) { }

  ngOnInit(): void {
    this.service.language.subscribe((lang) => {
      this.englishMode = lang == 'ENG' ? true : false;
    });
    $('.carousel').carousel({
      interval: 1000,
    });
    this.sectionCheck();
  }
  public englishMode = false;
  public displyModel;
  public section: any;
  public Sime_Darby_EN = {
    titleText: `Sime Darby Berhad is a partner of choice for some of the world’s best brands in the Industrial and Motors sectors, delivering sustainable value to stakeholders in 18 countries and territories across Asia Pacific.`,
    videoLink: 'https://player.vimeo.com/video/7xPYirJj9IY',
    slideImage1: '',
    slideImage2: '',
    slideImage3: '',
    slideImage4: '',
    slideImage5: '',
  };

  public Sime_Darby_BM = {
    titleText: `Sime Darby Berhad merupakan rakan pilihan bagi jenama terbaik dunia dalam sektor Perindustrian dan Automotif, menzahirkan masa depan yang mampan untuk semua pemegang kepentingan di 18 negara dan wilayah di seluruh Asia Pasifik.`,
    videoLink: 'https://player.vimeo.com/video/7xPYirJj9IY',
    slideImage1: '',
    slideImage2: '',
    slideImage3: '',
    slideImage4: '',
    slideImage5: '',
  };

  public UMW_EN = {
    titleText: `As a leading conglomerate, UMW is all about being innovative and delivering excellence in everything that we do. We carry this ideal through the strategic business units that we operate in. The very same drive has allowed the Group to thrive in Malaysia and traverse international shorelines. `,
  };
  public UMW_BM = {
    titleText: `UMW adalah syarikat yang inovatif & menitikberatkan kecemerlangan di dalam setiap aspek melalui unit perniagaan strategik yang kami kendalikan. Usaha yang sama telah membolehkan UMW untuk berkembang maju sebagai salah satu konglomerat terkemuka di Malaysia & luar negara. `,
  };
  public MNRB_EN = {
    titleText: `MNRB Holdings Berhad is an investment holding company. Amongst its wholly owned subsidiaries are Malaysian Reinsurance Berhad, Takaful Ikhlas Family Berhad, Takaful Ikhlas General Berhad and Malaysian Re (Dubai) Ltd. MNRB is listed on the Main Market of the Bursa Malaysia Securities Berhad. `,
    image1: 'assets/MNRB/1A.jpg',
    image2: 'assets/MNRB/2A.jpg',
    image3: 'assets/MNRB/3A.jpg',
    image4: 'assets/MNRB/4A.jpg',
    image5: 'assets/MNRB/5A.jpg',
  };

  public MNRB_BM = {
    titleText: `MNRB Holdings Berhad merupakan syarikat pegangan pelaburan. Antara subsidiari milik penuhnya ialah  Malaysian Reinsurance Berhad, Takaful Ikhlas Family Berhad, Takaful Ikhlas General Berhad dan Malaysian Re (Dubai) Ltd. MNRB disenaraikan di Pasaran Utama Bursa Malaysia Securities Berhad. `,
    image1: 'assets/MNRB/1B.jpg',
    image2: 'assets/MNRB/2B.jpg',
    image3: 'assets/MNRB/3B.jpg',
    image4: 'assets/MNRB/4B.jpg',
    image5: 'assets/MNRB/5B.jpg',
  };

  public PNB_BM = {
    titleText: `PNB ditubuhkan sebagai instrumen Dasar Ekonomi Baru negara. Sepanjang empat dekad yang lalu, PNB telah berkembang menjadi syarikat pengurusan dana yang terbesar di Malaysia. Mengekalkan fokus kepada mandat kami, kami berusaha memperkayakan kehidupan Bumiputera dan semua rakyat Malaysia.`,
  };

  public PNB_EN = {
    titleText: `PNB was established as one of the instruments of the Government's New Economic Policy. Over the last four decades, PNB has grown to become one of the largest fund management companies in Malaysia. Staying focused on our mandate, we strive to enrich the lives of Bumiputeras and all Malaysians.`,
  };

  public setia_EN = {
    titleText: `As a multiple award-winning public listed company, S P Setia strives to produce innovative and quality products for Malaysian and International communities. Our offerings range from Townships to Eco Homes, Luxury Homes, High Rise Residences, Retail, Commercial and Integrated Developments.`,
  };
  public setia_BM = {
    titleText: `Sebagai syarikat tersenarai awam yang memenangi pelbagai anugerah, S P Setia berusaha menghasilkan produk yang inovatif dan berkualiti di Malaysia dan juga Antarabangsa yang terdiri dari perbandaran, rumah eko, rumah mewah, kediaman bertingkat, kedai & pusat komersial dan pembangunan bersepadu.`,
  };
  public MIDF_EN = {
    titleText: `Established in 1960, Malaysian Industrial Development Finance Berhad (MIDF) is a diversified financial services provider in three core areas – Investment Banking, Development Finance and Asset Managementfor manufacturing-based small and medium enterprises (SMEs).`,
  };

  public MIDF_BM = {
    titleText: `Ditubuhkan pada tahun 1960, Malaysian Industrial Development Finance Berhad (MIDF) menyediakan pelbagai perkhidmatan kewangan berkaitan tiga bidang utama - Perbankan Pelaburan, Pembiayaan Pembangunan dan Pengurusan Aset untuk perusahaan kecil dan sederhana (PKS) berasaskan perkilangan.`,
  };

  public CCM_EN = {
    titleText: `At CCM, we dedicate ourselves to the Chemicals and Polymers industry and become one of the leading manufacturers in Malaysia. We also believe in nurturing young generations to develop interest in STEM so that they would always aspire to make life better for everyone through innovative sciences.`,
  };
  public CCM_BM = {
    titleText: `Kami sentiasa bekerja keras untuk industri Kimia & Polimer dan merupakan salah satu pengeluar terkemuka di Malaysia. Kami percaya dalam memupuk generasi muda untuk mengembangkan minat mereka terhadap STEM agar mereka selalu bercita-cita untuk memperbaiki kehidupan melalui inovasi sains.`,
  };

  public Duophrma_EN = {
    titleText: `Duopharma Biotech Berhad is a pharmaceutical company with core competencies in Manufacturing, Research & Development, Commercialisation & Marketing of generics prescribed medicines including Consumer Healthcare (CHC) products. `,
  };

  public Duophrma_BM = {
    titleText: `Duopharma Biotech Berhad adalah syarikat farmaseutikal yang menjalankan aktiviti perniagaan dalam Pembuatan, Penyelidikan & Pembangunan, dan  Pemasaran ubat-ubatan generik termasuk produk Penjagaan Kesihatan Pengguna (CHC). `,
  };
  public velesto_EN = {
    titleText: `Velesto Energy is a multinational provider of drilling services for the upstream sector of the oil & gas industry. We provide drilling services for exploration, development & production wells with our fleet of offshore drilling rigs, and workover services through our hydraulic workover units.`,
  };
  public velesto_BM = {
    titleText: `Velesto Energy adalah penyedia perkhidmatan penggerudian multinasional industri minyak & gas. Kami menyediakan perkhidmatan penggerudian telaga minyak bagi tujuan eksplorasi, pembangunan & pengeluaran menggunakan rig penggerudian, & perkhidmatan penyelenggaraan melalui hydraulic workover unit.`,
  };
  public sapura_BM = {
    titleText: `Sapura Energy merupakan syarikat perkhidmatan dan penyelesaian minyak & gas bersepadu yang beroperasi di peringkat antarabangsa. Keupayaan kami merangkumi penerokaan, pembinaan, pengeluaran, rejuvenasi & kegiatan pasca operasi. Kini, kami menyalurkan khidmat & kepakaran kami kepada lebih 20 negara. `,
  };
  public sapura_EN = {
    titleText: `Sapura Energy is a global integrated oil & gas services and solutions provider. Our capabilities cover exploration, development, production, rejuvenation, decommissioning and abandonment. Today we deliver our integrated solutions & expertise in over 20 countries.`,
  };
  public Prolintas_EN = {
    titleText: `Projek Lintasan Kota Holdings Sdn Bhd (PROLINTAS) was incorporated on 19 January 1995 as an investment holding company with interest in infrastructure development.

  The principal activities of its subsidiaries are design, construction, operation and maintenance of the highway.`,
  };
  public Prolintas_BM = {
    titleText: `Projek Lintasan Kota Holdings Sdn Bhd (PROLINTAS) telah diperbadankan pada tahun 1995. Ianya merupakan syarikat yang merekabentuk, membina, mengurus dan menyelenggara lebuh raya.`,
  };

  public AMBank_EN = {
    titleText: `AmBank Group is a leading financial services group with over 40 years of expertise in supporting the economic development of Malaysia. With over 3 million customers, the Group aspires to help individuals and businesses to grow and win together through offering them our best banking solutions.`,
  };
  public AMBank_BM = {
    titleText: `Kumpulan AmBank ialah sebuah kumpulan kewangan terkemuka dengan kepakaran melebihi 40 tahun dalam menyokong pembangunan ekonomi di Malaysia. Dengan lebih 3 juta pelanggan, kami beraspirasi untuk membantu individu & perniagaan dengan menawarkan kepada mereka penyelesaian perbankan kami yang terbaik.`,
  };

  public bankIslam_BM = {
    titleText: `
   Ketahui lebih lanjut mengenai Pembiayaan ASB-i Bank Islam dan produk patuh Syariah seperti Al-Awfar, East Spring, BIMB Invest dan Takaful.
   `,
  };

  public bankIslam_EN = {
    titleText: `Find out more about Bank Islam ASB Financing-i and our other shariah-compliant financial products such as Al-Awfar,East Spring, BIMB Invest and Takaful.
  `,
  };

  public CIMB_BM = {
    titleText: `CIMB Bonus Booster Campaign

    Cepat! Jangan lepaskan peluang anda untuk menangi Bonus Tunai RM60,000 dan dapatkan sehingga 2% p.a. Faedah / Keuntungan Bonus.

    Tonton video ini dan lihat betapa senangnya. Hanya simpan, belanja dan menang! Tertakluk kepada terma dan syarat. #CIMBBonusBooster.`,
    image1: '/assets/CIMB/IMAGE_2340 (w) x 1065 (h)__IMAGE_11.png',
    image2: '/assets/CIMB/IMAGE_2340 (w) x 1065 (h)__IMAGE_12.png',
  };
  public CIMB_EN = {
    titleText: `CIMB is one of ASEAN’s leading universal banking groups and Malaysia’s second largest financial services provider, by assets with a market capitalisation of approximately RM35.1 billion as at 30 June 2020. Headquartered in Kuala Lumpur, the Group is present in 10 ASEAN nations.`,
    image1: '/assets/CIMB/IMAGE_2340 (w) x 1065 (h)__IMAGE_11.png',
    image2: '/assets/CIMB/IMAGE_2340 (w) x 1065 (h)__IMAGE_12.png',
  };
  public ASNB_EN = {
    titleText: `The unit trust management of PNB with 33 branches and more than 2,700 agents’ branches. We manage 15 unit trust funds with 262 billion units in circulation and 14.5 million accounts. We offer a range of unit trust funds and services for all Malaysians to suit each investment need.`,
  };
  public ASNB_BM = {
    titleText: `Syarikat pengurusan unit amanah PNB dengan 33 pejabat cawangan & lebih 2,700 cawangan ejen seluruh negara. Kami mengurus 15 dana unit amanah dengan 262 bilion unit dalam edaran dan 14.5 juta akaun. Kami menawarkan dana & perkhidmatan untuk rakyat Malaysia bagi memenuhi setiap keperluan pelaburan.`,
  };
  public RHB_EN = {
    titleText: `Diversify your portfolio with ASB. Save and invest with RHB to enjoy long term return with competitive dividend payout.`,
  };
  public RHB_BM = {
    titleText: `Pelbagaikan portfolio anda dengan pelaburan ASB untuk masa depan. Simpan dan labur dengan RHB untuk jangka panjang dan nikmati pulangan dividen yang kompetitif.`,
  };

  public ASB_EN = {
    titleText: `A collaboration between Bank Negara and MIT Sloan School of Management, Asia School of Business infuses global ex cellence, regional expertise and a uniquely Asian perspective into an extraordinary and unconventional educational experience that seeks to create principled and transformative leaders.`,
  };
  public ASB_BM = {
    titleText: `Asia School of Business, satu hasil kerjasama antara Bank Negara & Sekolah Pengurusan MIT Sloan, menggabungkan kecemerlangan tahap global, kemahiran serantau, & perspektif Asia yang unik untuk membolehkan pengalaman pendidikan yang luarbiasa bagi membangunkan pemimpin berprinsip & transformatif.`,
  };

  public attana_BM = {
    titleText: `Attana Hotels & Resorts adalah anak syarikat PNB yang bertanggungjawab bagi pengurusan hospitaliti. Di bawah portfolio Attana, kami menguruskan hotel dan resort serta kelab golf bernilai lebih daripada RM800 juta. Di bawah Attana, kami mempunyai sub-sub jenama iaitu “Perdana”, “Espira” dan “Villea”.`,
    image1: '/assets/attana/IMAGE_2340 (w) x 1065 (h)__IMAGE_1.png',
    image2: '/assets/attana/IMAGE_2340 (w) x 1065 (h)__IMAGE_2.png',
    image3: '/assets/attana/IMAGE_2340 (w) x 1065 (h)__IMAGE_3.png',
    image4: '/assets/attana/IMAGE_2340 (w) x 1065 (h)__IMAGE_4.png',
    image5: '/assets/attana/IMAGE_2340 (w) x 1065 (h)__IMAGE_5.png',
  };
  public attana_EN = {
    titleText: `Attana Hotels & Resorts is a subsidiary of PNB responsible for hospitality management. Under Attana's portfolio, we manage hotels and resorts and golf clubs worth more than RM800 million. Under Attana, we have sub-brands namely "Perdan", "Espira" and "Villea".`,
    image1: '/assets/attana/IMAGE_2340 (w) x 1065 (h)__IMAGE_1.png',
    image2: '/assets/attana/IMAGE_2340 (w) x 1065 (h)__IMAGE_2.png',
    image3: '/assets/attana/IMAGE_2340 (w) x 1065 (h)__IMAGE_3.png',
    image4: '/assets/attana/IMAGE_2340 (w) x 1065 (h)__IMAGE_4.png',
    image5: '/assets/attana/IMAGE_2340 (w) x 1065 (h)__IMAGE_5.png',
  };
  public jdv_BM = {
    titleText: `JDV adalah syarikat pelaburan yang ditubuhkan oleh PNB untuk menerajui strategi digitalisasi, mencari peluang peningkatan transformasi dan inovasi dalam mengalakkan daya saing organisasi. JDV menerokai pelaburan dan pembangunan dalam syarikat permulaan berteknologi (FinTech) tempatan dan serantau.`,
  };
  public jdv_EN = {
    titleText: `JDV is an investment vehicle set up by PNB to spearhead its digital business strategy, continuously find new opportunities to unlock digital value propositions and create growth through innovation. JDV is looking at venture investment and venture building in local and regional Fintech startups.`,
  };

  public merdeka_BM = {
    titleText: `PNB Merdeka Ventures Sdn Berhad (“PNBMV”), merupakan anak syarikat milik penuh Permodalan Nasional Berhad (PNB), diperbadankan pada bulan Jun 2000 bagi membangunkan presint Merdeka 118, dan juga pemilik tanah dan penjaga tunggal kedua-dua Stadium Merdeka dan Stadium Negara.`,
    image1: '/assets/merdeka/2018 - 1101 - Hang Jebat Drop Off copy.jpg',
    image2: '/assets/merdeka/2018 - 1101 - Hero Day Shot copy.jpg',
    image3: '/assets/merdeka/2018 - 1101 - Hero Night Shot copy.jpg',
    image4: '/assets/merdeka/2018 - 1101 - Office Reception L1 copy.jpg',
    image5: '/assets/merdeka/2018 - 1101 - Tower Lobby L4 copy.jpg',
  };
  public merdeka_EN = {
    titleText: `PNB Merdeka Ventures Sdn Berhad (“PNBMV”), a wholly-owned subsidiary of Permodalan Nasional Berhad (PNB), was incorporated in June 2000 for the purpose of developing the Merdeka 118 precinct, and is also the landowner and sole custodian of both Stadium Merdeka and Stadium Negara.`,
    image1: '/assets/merdeka/2018 - 1101 - Hang Jebat Drop Off copy.jpg',
    image2: '/assets/merdeka/2018 - 1101 - Hero Day Shot copy.jpg',
    image3: '/assets/merdeka/2018 - 1101 - Hero Night Shot copy.jpg',
    image4: '/assets/merdeka/2018 - 1101 - Office Reception L1 copy.jpg',
    image5: '/assets/merdeka/2018 - 1101 - Tower Lobby L4 copy.jpg',
  };

  public prop_BM = {
    titleText: `Raiz merupakan aplikasi perkhidmatan kewangan untuk membantu anda menyimpan dan membuat pelaburan kecil secara berskala. Ringkas dan mudah. Dengan kerjasama PNB dan dengan lebih sejuta muat turun di Australia, ia kini tiba di Malaysia. Terbuka kepada pemegang Kad Debit Maybank.`,
    image1: '/assets/raiz/Raiz_Photo_1.png',
    image2: '/assets/raiz/Raiz_Photo_2_v2.png',
    image3: '/assets/raiz/Raiz_Photo_3_v2.png',
    image4: '/assets/raiz/Raiz_Photo_4.png',
    image5: '/assets/raiz/Raiz_Photo_5_v2.png',
  };
  public prop_EN = {
    titleText: `Raiz is a financial service app, helping you to save and invest small amounts and your spare change regularly, easily and effortlessly. In partnership with PNB and with over a million downloads in Australia, it is now available in Malaysia! Made available first for Maybank Debit Cardmembers.`,
    image1: '/assets/raiz/Raiz_Photo_1.png',
    image2: '/assets/raiz/Raiz_Photo_2_v2.png',
    image3: '/assets/raiz/Raiz_Photo_3_v2.png',
    image4: '/assets/raiz/Raiz_Photo_4.png',
    image5: '/assets/raiz/Raiz_Photo_5_v2.png',
  };
  public raiz_BM = {
    titleText: `Raiz merupakan aplikasi perkhidmatan kewangan untuk membantu anda menyimpan dan membuat pelaburan kecil secara berskala. Ringkas dan mudah. Dengan kerjasama PNB dan dengan lebih sejuta muat turun di Australia, ia kini tiba di Malaysia. Terbuka kepada pemegang Kad Debit Maybank.`,
    image1: '/assets/raiz/Raiz_Photo_1.png',
    image2: '/assets/raiz/Raiz_Photo_2_v2.png',
    image3: '/assets/raiz/Raiz_Photo_3_v2.png',
    image4: '/assets/raiz/Raiz_Photo_4.png',
    image5: '/assets/raiz/Raiz_Photo_5_v2.png',
  };
  public raiz_EN = {
    titleText: `Raiz is a financial service App, helping you to save and invest small amounts and your spare change regularly -easily and effortlessly.In partnership with Permodalan Nasional Berhad(PNB) and with over a million downloads in Australia, it is now available in Malaysia! Made available first for Maybank Debit Cardmembers.`,
    image1: '/assets/raiz/Raiz_Photo_1.png',
    image2: '/assets/raiz/Raiz_Photo_2_v2.png',
    image3: '/assets/raiz/Raiz_Photo_3_v2.png',
    image4: '/assets/raiz/Raiz_Photo_4.png',
    image5: '/assets/raiz/Raiz_Photo_5_v2.png',
  };
  public Plantation_EN = {
    titleText: `Sime Darby Plantation is the world's largest producer of Certified Sustainable Palm Oil. We are involved in various activities along the full spectrum of the palm oil value chain, including upstream plantation, downstream operations, Research & Development, renewables and agro-business.`,
  };

  public Plantation_BM = {
    titleText: `Sime Darby Plantation merupakan pengeluar Minyak Sawit Diperakui Mampan atau Certified Sustainable Palm Oil terbesar dunia. Kami menjalankan aktiviti-aktiviti perladangan huluan, operasi hiliran, penyelidikan & pembangunan, tenaga boleh diperbaharui dan perniagaan agro. `,
  };

  public MayBank_EN = {
    titleText: `Maybank is one of Asia’s leading banking groups & South East Asia’s 4th largest by assets. Crowned World’s Best Consumer Digital Bank in Malaysia, check out what we have for you! Whether it’s online banking, savings, financing, or insurance plans - we have something for you & your loved ones.`,
  };
  public MayBank_BM = {
    titleText: `Maybank adalah antara kumpulan perbankan terkemuka di Asia & bank keempat terbesar dari segi aset di Asia Tenggara. Dinobatkan sebagai Bank Digital Pengguna Terbaik Sedunia di Malaysia, kami menawarkan pelbagai kemudahan, sama ada pelan perbankan, simpanan, pinjaman atau insurans dalam talian.`,
  };
  public SimeDarbyProperty_BM = {
    titleText:`Sime Darby Property merupakan pemaju hartanah terulung di Malaysia dengan lebih dari 47 tahun pengalaman membina komuniti yg mampan. Sime Darby Property setakat ini telah membina 24 perbandaran aktif dengan capaian merangkumi aset dan operasi perumahan, komersil, dan perindustrian.`
  }

  public SimeDarbyProperty_EN = {
    titleText:`Sime Darby Property is a leading property developer with a strong success rate of developing sustainable communities for over 47 years.  With 24 active townships/developments, Sime Darby Property has a wide reach that encompasses residential, commercial and industrial assets and operations.`
  }

  public Perodua_EN = {
    titleText:`Established in 1993, Perodua aims to be the leading affordable automotive brand regionally with global standards. Our products and services are supported by a vast sales and service network to satisfy your various needs and wants.`
  }
  public Perodua_BM = {
    titleText:`Ditubuhkan 1993, Perodua bertujuan untuk menjadi jenama automotif mampu milik terkemuka serantau dengan piawaian global. Produk dan perkhidmatan kami disokong oleh rangkaian jualan dan servis yang besar untuk memenuhi keperluan dan kehendak anda.`
  }
  public languageChange = (data: any) => {


    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Language Switch',
      'eventCallback': function () {
        console.log('Language Switch Event Tags Triggered and Fired');
      }
    });
  

    if (data == 'ENG') {
      this.service.language.next('ENG');
      if(this.section=='perodua'){
        this.displyModel = this.Perodua_EN;
      }
     if(this.section == 'SimeDarbyProperty'){
       this.displyModel = this.SimeDarbyProperty_EN
     }
      if (this.section == 'PNB') {
        this.displyModel = this.PNB_EN;
      }
      if (this.section == 'sime_plant') {
        this.displyModel = this.Sime_Darby_EN;
      }
      if (this.section == 'UMW') {
        this.displyModel = this.UMW_EN;
      }
      if (this.section == 'setia') {
        this.displyModel = this.setia_EN;
      }
      if (this.section == 'MIDF') {
        this.displyModel = this.MIDF_EN;
      }
      if (this.section == 'MNRB') {
        this.displyModel = this.MNRB_EN;
      }
      if (this.section == 'CCM') {
        this.displyModel = this.CCM_EN;
      }
      if (this.section == 'Duophrma') {
        this.displyModel = this.Duophrma_EN;
      }
      if (this.section == 'velesto') {
        this.displyModel = this.velesto_EN;
      }
      if (this.section == 'sapura') {
        this.displyModel = this.sapura_EN;
      }
      if (this.section == 'Prolintas') {
        this.displyModel = this.Prolintas_EN;
      }
      if (this.section == 'AMBank') {
        this.displyModel = this.AMBank_EN;
      }
      if (this.section == 'bankIslam') {
        this.displyModel = this.bankIslam_EN;
      }
      if (this.section == 'CIMB') {
        this.displyModel = this.CIMB_EN;
      }
      if (this.section == 'ASNB') {
        this.displyModel = this.ASNB_EN;
      }
      if (this.section == 'RHB') {
        this.displyModel = this.RHB_EN;
      }
      if (this.section == 'ASB') {
        this.displyModel = this.ASB_EN;
      }
      if (this.section == 'attana') {
        this.displyModel = this.attana_EN;
      }
      if (this.section == 'jdv') {
        this.displyModel = this.jdv_EN;
      }
      if (this.section == 'raiz') {
        this.displyModel = this.raiz_EN;
      }
      if (this.section == 'merdeka') {
        this.displyModel = this.merdeka_EN;
      }
      if (this.section == 'Plantation') {
        this.displyModel = this.Plantation_EN;
      }
      if (this.section == 'MayBank') {
        this.displyModel = this.MayBank_EN;
      }
      this.englishMode = true;
    }
     else if (data == 'BM') {
      this.service.language.next('ENG');
      if(this.section=='perodua'){
        this.displyModel = this.Perodua_BM;
      }
      if(this.section == 'SimeDarbyProperty'){
        this.displyModel = this.SimeDarbyProperty_BM
      }
      if (this.section == 'PNB') {
        this.displyModel = this.PNB_BM;
      }
      if (this.section == 'sime_plant') {
        this.displyModel = this.Sime_Darby_BM;
      }
      if (this.section == 'UMW') {
        this.displyModel = this.UMW_BM;
      }
      if (this.section == 'setia') {
        this.displyModel = this.setia_BM;
      }
      if (this.section == 'MIDF') {
        this.displyModel = this.MIDF_BM;
      }
      if (this.section == 'MNRB') {
        this.displyModel = this.MNRB_BM;
      }
      if (this.section == 'CCM') {
        this.displyModel = this.CCM_BM;
      }

      if (this.section == 'Duophrma') {
        this.displyModel = this.Duophrma_BM;
      }
      if (this.section == 'velesto') {
        this.displyModel = this.velesto_BM;
      }
      if (this.section == 'sapura') {
        this.displyModel = this.sapura_BM;
      }
      if (this.section == 'Prolintas') {
        this.displyModel = this.Prolintas_BM;
      }
      if (this.section == 'AMBank') {
        this.displyModel = this.AMBank_BM;
      }
      if (this.section == 'bankIslam') {
        this.displyModel = this.bankIslam_BM;
      }
      if (this.section == 'CIMB') {
        this.displyModel = this.CIMB_BM;
      }
      if (this.section == 'ASNB') {
        this.displyModel = this.ASNB_BM;
      }
      if (this.section == 'RHB') {
        this.displyModel = this.RHB_BM;
      }
      if (this.section == 'ASB') {
        this.displyModel = this.ASNB_BM;
      }
      if (this.section == 'attana') {
        this.displyModel = this.attana_BM;
      }
      if (this.section == 'jdv') {
        this.displyModel = this.jdv_BM;
      }
      if (this.section == 'raiz') {
        this.displyModel = this.raiz_BM;
      }
      if (this.section == 'merdeka') {
        this.displyModel = this.merdeka_BM;
      }
      if (this.section == 'Plantation') {
        this.displyModel = this.Plantation_BM;
      }
      if (this.section == 'MayBank') {
        this.displyModel = this.MayBank_BM;
      }

      this.englishMode = false;
      this.service.language.next('BM');
    }
  };
  public sectionCheck = () => {
    window.scroll(0, 0);
    this.section = this.active.snapshot.params.id;
    if(this.section == 'perodua'){
      this.displyModel = this.englishMode ? this.Perodua_EN:this.Perodua_BM
    }
    if (this.section == 'sime_plant') {
      this.displyModel = this.englishMode
        ? this.Sime_Darby_EN
        : this.Sime_Darby_BM;
    }
    if (this.section == 'UMW') {
      this.displyModel = this.englishMode ? this.UMW_EN : this.UMW_BM;
    }
    if (this.section == 'setia') {
      this.displyModel = this.englishMode ? this.setia_EN : this.setia_BM;
    }
    if (this.section == 'MIDF') {
      this.displyModel = this.englishMode ? this.MIDF_EN : this.MIDF_BM;
    }
    if (this.section == 'MNRB') {
      this.displyModel = this.englishMode ? this.MNRB_EN : this.MNRB_BM;
    }
    if (this.section == 'CCM') {
      this.displyModel = this.englishMode ? this.CCM_EN : this.CCM_BM;
    }
    if (this.section == 'Duophrma') {
      this.displyModel = this.englishMode ? this.Duophrma_EN : this.Duophrma_BM;
    }
    if (this.section == 'velesto') {
      this.displyModel = this.englishMode ? this.velesto_EN : this.velesto_BM;
    }
    if (this.section == 'sapura') {
      this.displyModel = this.englishMode ? this.sapura_EN : this.sapura_BM;
    }
    if (this.section == 'Prolintas') {
      this.displyModel = this.englishMode
        ? this.Prolintas_EN
        : this.Prolintas_BM;
    }
    if (this.section == 'AMBank') {
      this.displyModel = this.englishMode ? this.AMBank_EN : this.AMBank_BM;
    }
    if (this.section == 'bankIslam') {
      this.displyModel = this.englishMode
        ? this.bankIslam_EN
        : this.bankIslam_BM;
    }
    if (this.section == 'CIMB') {
      this.displyModel = this.englishMode ? this.CIMB_EN : this.CIMB_BM;
    }
    if (this.section == 'ASNB') {
      this.displyModel = this.englishMode ? this.ASNB_EN : this.ASNB_BM;
    }
    if (this.section == 'RHB') {
      this.displyModel = this.englishMode ? this.RHB_EN : this.RHB_BM;
    }
    if (this.section == 'ASB') {
      this.displyModel = this.englishMode ? this.ASB_EN : this.ASB_BM;
    }
    if (this.section == 'attana') {
      this.displyModel = this.englishMode ? this.attana_EN : this.attana_BM;
    }
    if (this.section == 'jdv') {
      this.displyModel = this.englishMode ? this.jdv_EN : this.jdv_BM;
    }
    if (this.section == 'raiz') {
      this.displyModel = this.englishMode ? this.raiz_EN : this.raiz_BM;
    }
    if (this.section == 'merdeka') {
      this.displyModel = this.englishMode ? this.merdeka_EN : this.merdeka_BM;
    }
    if (this.section == 'Plantation') {
      this.displyModel = this.englishMode
        ? this.Plantation_EN
        : this.Plantation_BM;
    }
    if (this.section == 'MayBank') {
      this.displyModel = this.englishMode ? this.MayBank_EN : this.MayBank_BM;
    }
    if (this.section == 'PNB') {
      this.displyModel = this.englishMode ? this.PNB_EN : this.PNB_BM;
    } 
    if(this.section == "SimeDarbyProperty"){
      this.displyModel = this.englishMode ? this.SimeDarbyProperty_EN : this.SimeDarbyProperty_BM
    }
  };

  public logoSite = (data) => {
    console.log(data);
    window.open(data, '_blank');
  };

  public hyperlink = (data: any) => {
    window.open(data, '_blank');
  };
}
