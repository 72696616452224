<nav class="navbar navbar-expand-lg bg-dark navbar-dark py-0 sticky" #stickyNavbar>
  <a routerLink="/" class="navbar-brand mr-3 pointer">
    <!-- <a href="https://www.pnb.com.my" target="_blank"> <img src="/assets/imgs/png_logo.svg" height="45px"> </a> -->
    <a (click)="openLink('https://www.pnb.com.my')">
      <img src="/assets/imgs/pnb-logo.jpeg" height="30px" />
    </a>

    <!--   <img class="ml-4" src="/assets/imgs/pnb_logo_2.svg" height="20px"> -->
    <a (click)="openLink('https://www.asnb.com.my/')">
      <img class="ml-4" src="/assets/imgs/asnb-logo.jpeg" height="30px" /></a>
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span class="navbar-toggler-icon" #helpToggle></span>
  </button>
  <div class="collapse  navbar-collapse" id="collapsibleNavbar">
    <ul class="navbar-nav text-center ml-auto main">
      <li class="nav-item pointer">
        <a routerLink="/" class="nav-link py-3" [routerLinkActiveOptions]="{ exact: true }" Type a message
          routerLinkActive="selected">{{ header.header0 }}</a>
      </li>
      <li class="nav-item pointer">
        <a routerLink="/programs" class="nav-link py-3" routerLinkActive="selected">{{ header.header1 }}</a>
      </li>
      <li class="nav-item pointer">
        <a routerLink="/competitions" class="nav-link py-3" routerLinkActive="selected">{{ header.header2 }}</a>
      </li>
      <li class="nav-item pointer">
        <a routerLink="/promotions" class="nav-link py-3" routerLinkActive="selected">{{ header.header3 }}</a>
      </li>
      <li class="nav-item pointer">
        <a routerLink="/partners" class="nav-link py-3" routerLinkActive="selected">{{ header.header4 }}</a>
      </li>
      <li class="nav-item pointer">
        <a routerLink="/khidmat" class="nav-link py-3" routerLinkActive="selected">{{ header.header5 }}</a>
      </li>
      <li class="nav-item pointer">
        <a routerLink="/faqs" class="nav-link py-3" routerLinkActive="selected">{{ header.header6 }}</a>
      </li>
      <li class="nav-item pointer" href="#" id="userDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false">
        <a class="nav-link py-3">{{ header.header7 }} <i class="fas fa-chevron-down pl-1 font-12"></i></a>
      </li>
      <div class="dropdown-menu addDropDown dropdown-menu-right shadow" aria-labelledby="userDropdown2">
        <span *ngFor="let menu of header.menus; let i = index">
          <a *ngIf="header.pdfFiles[i]" class="dropdown-item" target="_blank" [href]="header.pdfFiles[i]">{{ menu }}</a>
          <a *ngIf="!header.pdfFiles[i]" class="dropdown-item">{{ menu }}</a>
        </span>
      </div>
      <li class="nav-item pointer">
        <a *ngIf="userIsLogOut" class="nav-link loginBtn py-3 text1 px-0">
          <button style="display: none" id="modal2bt" data-toggle="modal" data-target="#this"></button>
          <a class="loginBtn pr-3 pl-0" id="modal2" data-toggle="modal" data-target="#this"
            (click)="hiddenToggle.click()">{{ header.header8 }}</a>
          <a class="loginBtn" data-toggle="modal" data-target="#siginModal" id="openModel"
            (click)="forgetPasswors = false;hiddenToggle.click()">{{ header.header9 }}</a></a>
        <a class="nav-link loginBtn py-3 text1" *ngIf="!userIsLogOut" (click)="logOut()">{{ header.header10 }}</a>
      </li>
      <button style="display: none;" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"
        #hiddenToggle>Pricing</button>


    </ul>
  </div>
</nav>

<!-- <div class="languageCont">
  <h6 class="my-0">BM</h6>
  <h6 class="mx-2">|</h6>
  <h6>ENG</h6>
</div> -->

<!-- Login Modal -->
<div class="modal fade" id="siginModal">
  <div class="modal-dialog myModal modal-dialog-centered">
    <div class="modal-content">
      <i class="fas fa-times closeBtn pointer" id="closeModal1" data-dismiss="modal"></i>
      <div class="inputCont">
        <!-- <h3 class="heading">daftar <br />sekarang</h3> -->
        <h5 class="mt-2 mb-3">
          {{ displyModel.loginHader1 }}<br />{{ displyModel.loginHader2 }}
        </h5>

        <h5 class="mt-2 mb-3" *ngIf="temporarilyBlocked" style="color: red">
          User is temporarily Blocked
        </h5>
        <form [formGroup]="loginFrom" *ngIf="!forgetPasswors">
          <div class="row justify-content-center">
            <div class="col-sm-8 col-9 px-0">
              <!-- <div class="form-group">
              <label>Nama</label>
              <input type="text" class="form-control" value="Nama Saya">
            </div>
            <div class="form-group">
              <label>Tarikh Lahir</label>
              <input type="email" class="form-control" value="01/12/1987">
            </div> -->
              <div class="form-group">
                <label>{{ displyModel.field2 }}</label>
                <input formControlName="email" type="email" class="form-control" value="namasaya@emel.com" />
              </div>
              <div class="form-group">
                <label>{{ displyModel.field5 }}</label>
                <input formControlName="password" type="password" class="form-control" value="xcvvvvvvv" />
              </div>
              <div class="custom-control custom-checkbox">
                <!-- <input checked type="checkbox" class="custom-control-input" id="customCheck" name="example1"> -->
                <label (click)="password()">{{ displyModel.forget }}</label>
              </div>
              <button class="btn darkBtn btn-block" id="loginBtn" [disabled]="loginFrom.invalid" (click)="login()">
                {{ displyModel.LoginBtn }}
              </button>
              <p class="font-14 my-0 pointer" (click)="openPDF()">
                <u>{{ displyModel.info }}</u>
              </p>
              <p class="font-14 my-0 pointer" (click)="openPDF2()">
                <u>{{ displyModel.info2 }}</u>
              </p>
            </div>
          </div>
        </form>

        <form *ngIf="forgetPasswors" [formGroup]="restPasswordMail">
          <div class="row justify-content-center">
            <div class="col-sm-8 col-9 px-0">
              <!-- <div class="form-group">
                <label>Nama</label>
                <input type="text" class="form-control" value="Nama Saya">
              </div>
              <div class="form-group">
                <label>Tarikh Lahir</label>
                <input type="email" class="form-control" value="01/12/1987">
              </div> -->
              <div class="form-group">
                <label>Email</label>
                <input formControlName="email" type="email" class="form-control" value="namasaya@emel.com" />
              </div>

              <button class="btn darkBtn btn-block" [disabled]="restPasswordMail.invalid" (click)="restPasswod()">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="this">
  <div class="modal-dialog myModal modal-dialog-centered">
    <div class="modal-content">
      <i id="closeModal" class="fas fa-times closeBtn pointer" data-dismiss="modal" (click)="scrollUp(target2)"></i>
      <div class="inputCont2" id="Sdiv" (scroll)="onScroll($event)">
        <div class="innerBlock">
          <h5 class="mt-2 mb-3" #target2>
            {{ displyModel.ModalHeader1 }}<br />
            {{ displyModel.ModalHeader2 }}
          </h5>
          <form [formGroup]="registrationForm">
            <div class="row justify-content-center">
              <div class="col-sm-8 col-9 px-0">
                <div class="form-group">
                  <label>{{ displyModel.field1 }}</label>
                  <input formControlName="name" type="text" class="form-control" />
                </div>
                <div class="form-group">
                  <label>{{ displyModel.field4 }}</label>
                  <input class="form-control" type="tel" formControlName="phone"
                    [ngClass]="{ 'is-invalid': f.phone.errors }" />
                  <div *ngIf="f.phone.errors" class="invalid-feedback" [ngClass]="{ 'is-invalid': f.phone.errors }">
                    <div *ngIf="f.phone.errors.pattern">
                      {{ displyModel.numberValidator }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>{{ displyModel.field2 }}</label>
                  <input formControlName="email" (click)="scrolldown(target)" type="email" class="form-control"
                    [ngClass]="{ 'is-invalid': f.email.errors }" />

                  <div *ngIf="f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.email">
                      {{ displyModel.EmailValidator }}
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>{{ displyModel.field3 }}</label>
                  <input formControlName="nric" type="text" class="form-control"
                    [ngClass]="{ 'is-invalid': f.nric.errors }" />
                  <div *ngIf="f.nric.errors" class="invalid-feedback" [ngClass]="{ 'is-invalid': f.nric.errors }">
                    <div *ngIf="f.nric.errors.pattern">
                      {{ displyModel.numberValidator }}
                    </div>
                    <div *ngIf="f.nric.errors.maxlength">
                      {{ displyModel.lengthValidator }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>{{ displyModel.field5 }}</label>
                  <input formControlName="password" type="password" class="form-control"
                    [ngClass]="{ 'is-invalid': f.password.errors }" />

                  <div *ngIf="f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.minlength">
                      {{ displyModel.passwordValidator }}
                    </div>
                  </div>
                </div>

                <div class="row">
                  <p style="width: 100%; margin: 0px; padding-top: 0.2rem">
                    <img *ngIf="upWord" class="fixed" (click)="scrollUp(target2)" src="assets/imgs/up-arrow.svg" />
                    <img *ngIf="downWord" class="fixed" (click)="scrolldown(target)" src="assets/imgs/down-arrow.svg" />
                  </p>
                </div>

                <div class="custom-control custom-checkbox">
                  <input checked type="checkbox" class="custom-control-input" id="customCheck" name="example1" />
                  <label class="custom-control-label" for="customCheck">{{
                    displyModel.bottomText
                  }}</label>
                </div>
                <button id="positionId" #target class="btn darkBtn btn-block" [disabled]="registrationForm.invalid"
                  (click)="submit()">
                  {{ displyModel.ModalBtn }}
                </button>

                <p class="font-14 my-0 italic-small">
                  {{ displyModel.bySubmitting }}
                </p>

                <p class="font-14 my-0 pointer" (click)="openPDF()">
                  <u>{{ displyModel.info }}</u>
                </p>
                <p class="font-14 my-0 pointer" (click)="openPDF2()">
                  <u>{{ displyModel.info2 }}</u>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fall" [fullScreen]="true">
  <p style="color: white"></p>
</ngx-spinner>
