import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';

declare var window: any;



import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipDefaultOptions,
} from '@angular/material/tooltip';

/** Custom options the configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 1000,
  touchendHideDelay: 1000,
};
@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
  ],
})
export class PartnersComponent implements OnInit {
  englishMode = false;

  public language: any;

  public BM = {
    header1: 'RAKAN PREMIER',
    header2: 'RAKAN UTAMA',
    header3: 'RAKAN',
    header4: 'Ejen',
    header5: 'Rakan Acara',
    popMsg: 'Akan Datang',
  };

  public EN = {
    header1: 'RAKAN PREMIER',
    header2: 'RAKAN UTAMA',
    header3: 'RAKAN',
    header4: 'PARTICIPATING AGENTS',
    header5: 'EVENT PARTNERS',
    popMsg: 'Coming Soon',
  };

  constructor(public roueter: Router, private service: DataService) {
    this.language = this.BM;
  }

  ngOnInit(): void {
    this.service.language.subscribe((lang) => {
      this.englishMode = lang == 'ENG' ? true : false;
      this.language = this.englishMode ? this.EN : this.BM;
    });
  }
  public languageChange = (data: any) => {

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Language Switch',
      'eventCallback': function () {
        console.log('Language Switch Event Tags Triggered and Fired');
      }
    });


    if (data == 'ENG') {
      this.service.language.next('ENG');
      this.englishMode = true;
      this.language = this.EN;
    } else if (data == 'BM') {
      this.service.language.next('BM');
      this.englishMode = false;
      this.language = this.BM;
    }
  };
  public toPartner = (data) => {
    this.roueter.navigate(['/partnersDetails', { id: data }]);
  };

  /* public newTab = () => {
    window.open('https://www.pnb.com.my/', '_blank');
  }; */
}
