import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-may-bank-partner',
  templateUrl: './may-bank-partner.component.html',
  styleUrls: ['./may-bank-partner.component.scss']
})
export class MayBankPartnerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('.carousel').carousel({
      interval: 1500,
    });
  }

}
