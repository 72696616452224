<div class="footer">
  <div class="text-center">
    <a href="https://www.facebook.com/minggusahamdigital" target="_blank">
      <img src="/assets/imgs/facebook_icon.svg">
    </a>
    <a href="https://www.instagram.com/minggusahamdigital/" target="_blank">
      <img src="/assets/imgs/insta_icon.svg">
    </a>
    <a href="https://www.youtube.com/channel/UCoNCjruylHYpwBYkIvRwClA/" target="_blank">
      <img src="/assets/imgs/youtube_icon.svg">
    </a>
    <a href="http://minggusahamdigital.chatnow.my/" target="_blank">
      <img src="/assets/imgs/whatsapp_icon.svg">
    </a>
    <p class="mt-4 font-13">Permodalan Nasional Berhad © 2020</p>
  </div>
</div>
