import { state } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../data.service';

@Component({
  selector: 'app-refer',
  templateUrl: './refer.component.html',
  styleUrls: ['./refer.component.scss']
})
export class ReferComponent implements OnInit , AfterViewInit {

  constructor(private http: HttpClient,public fb:FormBuilder ,public service:DataService,public toster:ToastrService,public spiner:NgxSpinnerService) { }
  ngAfterViewInit(): void {
     let x = document.getElementById('refModal');
     if(x){
       x.click();
     }
  }

  ngOnInit(): void {
    this.readCVS();
   this.refForm = this.fb.group({
    agent_bank:['' , Validators.required],
    state:['' , Validators.required],
    branch:['' ,Validators.required]
   })

  }

  // <-- Variable Section --> //
  public userArray:any =[];
  public classifyArray:any = [];
  public states = [];
  public branches = [];
  public refForm:FormGroup;
 
 
  // <-- Function Section --> //
  public readCVS = ()=>{
    this.http.get('assets/Book1.csv', {responseType: 'text'})
    .subscribe(
        data => {
             
            let csvToRowArray = data.split("\n");
 
            for (let index = 1; index < csvToRowArray.length - 1; index++) {
              let row = csvToRowArray[index].split(",");
          
              let obj = {
                name:row[0],
                state:row[1],
                branch:row[2],

              }
              this.userArray.push(obj);
            }
      
            this.dataModaling();
        },
        error => {
            console.log(error);
        }
    );

  
  }
  public dataModaling = () =>{
    
    this.userArray.forEach((element:any,index) => {
      if(this.classifyArray.length==0){
        this.classifyArray.push({bankName:element.name,states:[{stateName:element.state,branches:[element.branch]}]})
      }
      else{
        let bankFound = false;
        this.classifyArray.forEach((elements:any,index) => {

          if(element.name == elements.bankName){
            bankFound = true
           
            let stateNameFound = false;
            elements.states.forEach((elementState:any) => {
              if(elementState.stateName == element.state){
             
                elementState.branches.push(element.branch)
                stateNameFound = true
              } 
            
              
            });
            if(stateNameFound == false){
              elements.states.push({stateName:element.state,branches:[element.branch]}) 
            } 
          }
        });
       if(bankFound == false) {
        this.classifyArray.push({bankName:element.name,states:[{stateName:element.state,branches:[element.branch]}]})
       }

      }
    
    });
    console.log(this.classifyArray);
  }
  bankSelection(event) {
      console.log(event);
    this.classifyArray.forEach((element:any,index) => {
      if(element.bankName == event){
        this.states = element.states;
       
       
      }
    });
    this.refForm.get('state').setValue('');
    this.refForm.get('branch').setValue('');
  }

  public  stateSelection(event){
    console.log(event);
    this.states.forEach((element:any,index)=>{
      if(element.stateName == event){
         this.branches = element.branches;
      }
    })
    console.log(this.branches.sort());
    this.refForm.get('branch').setValue('');
  }
  public submit = () => {
     this.spiner.show();
    this.service.addRef(this.refForm.value).subscribe((res:any)=>{
      console.log(res);
      this.states = [];
      this.branches = [];
      this.toster.success('Ref Added Successfully');
      let y = document.getElementById('RefcloseModal');
      if(y){
        y.click();
      }
      this.refForm.reset();
      this.refForm.patchValue({
        agent_bank:''
      })
      this.spiner.hide();
    }, (err)=>{
      this.spiner.hide();
      this.toster.error(err.error.message + 'Try Again');
    })
  }


}


// export class Data{
//   No: number;
//   AGENT: String;
//   lastName: String;

//   constructor(id: number, name: String, lastName: String){
//     this.id = id;
//     this.name = name;
//     this.lastName = lastName;
//   }
// }