import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../data.service';


declare var window: any;


@Component({
  selector: 'app-per-register',
  templateUrl: './per-register.component.html',
  styleUrls: ['./per-register.component.scss']
})

export class PerRegisterComponent implements OnInit {

  constructor(public fb: FormBuilder, public service: DataService, public toastr: ToastrService, private spinner: NgxSpinnerService) { }
  ngAfterViewInit(): void {
    this.loginCheck();
  }


  public submitted = false;
  public pattern = /^[a-zA-Z0-9"]*$/
  ngOnInit(): void {
    this.displyModel = this.BMObj;
    this.registrationForm = this.fb.group({
      name: ['', Validators.required],
      dob: '',
      email: ['', [Validators.required, Validators.email]],
      nric: ['', Validators.required],
      /* nric: ['', [Validators.required, Validators.minLength(1
      ), Validators.maxLength(12), Validators.pattern(this.pattern)]], */
      password: ['', [Validators.required, Validators.minLength(6)]],
      phone: ['', Validators.required]
    })
  }

  // <-- variable Section  --> //
  public registrationForm: FormGroup;
  public isLogin = false;
  public downWord = true;
  public upWord = false;
  public englishMode = false
  public EnglishObj = {
    image1: '/assets/imgs/top-banner-en.png',
    image2: '/assets/imgs/syno2.png',
    buttonText: 'REGISTER NOW',
    ModalHeader1: 'Register Now and stand a',
    ModalHeader2: ' chance to win prizes!',
    field1: 'Name',
    field2: 'E-mail',
    field3: 'IC Number',
    field4: 'Mobile Number',
    field5: 'Password',
    bottomText: 'I agree to receive e-mails on promotions',
    bySubmitting: '*By submitting this registration form, participant hereby agrees to allow PNB, and PNB reserves the right to collect and process participant’s personal data, if any, for advertising, publicity or research purposes, in accordance with the requirement of the relevant laws, without any cost or compensation.',
    ModalBtn: 'REGISTER',
    info: 'Terms and Conditions (Eh, Mana Kod?)',
    info2: 'Terms and Conditions (Lucky Draw)',
    successMessage: 'Successfully registered',
    error: 'User already exists',
    T_C: '/assets/TERMS%20_%20CONDITIONS_%20MANA%20KOD%20-%201910%20-%20final.pdf',
    T_C_2: '/assets/TERMS%20_%20CONDITIONS_%20Lucky%20Draw%20-%201910%20-%20final.pdf'
  };
  public BMObj = {
    image1: '/assets/imgs/top-banner-bm.png',
    text1: 'Baucar-Baucar Bernilai RM125,000',
    image2: '/assets/imgs/synopsis.png',
    buttonText: 'DAFTAR SEKARANG',
    ModalHeader1: 'Daftar sekarang untuk ',
    ModalHeader2: 'menyertai pertandingan!',
    field1: 'Nama',
    field2: 'E-mel',
    field3: 'Nombor KP',
    field4: 'Nombor Telefon Mudah Alih',
    field5: 'Kata Laluan',
    bottomText: 'Saya bersetuju untuk menerima e-mel promosi',
    bySubmitting: '*Dengan menyerahkan borang pendaftaran ini, peserta dengan ini setuju untuk mengizinkan PNB, dan PNB berhak mengumpulkan dan memproses data peribadi peserta, jika ada, untuk tujuan periklanan, publisiti atau penyelidikan, sesuai dengan kehendak undang-undang yang relevan, tanpa sebarang biaya atau pampasan.',
    ModalBtn: 'DAFTAR',
    info: 'Terma & Syarat (Eh, Mana Kod?)',
    info2: 'Terma & Syarat (Cabutan Bertuah)',
    successMessage: 'Pendaftaran berjaya!',
    error: 'Nama pengguna telah diambil.',
    T_C: '/assets/TERMA%20_%20SYARAT%20PERADUAN_%20MANA%20KOD%20-%201910%20-%20final.pdf',
    T_C_2: '/assets/TERMA%20_%20SYARAT%20PERADUAN_%20Cabutan%20Bertuah%20-%201910%20-%20final.pdf',
  };
  public displyModel: any;

  // <-- Function Section  --> //

  get f() { return this.registrationForm.controls; }

  public submit = () => {
    this.spinner.show();
    console.log(this.registrationForm.value);
    this.service.registerUser(this.registrationForm.value).subscribe((res: any) => {
      console.log(res);
      localStorage.setItem('authToken', res.jwt)
      this.spinner.hide();
      this.registrationForm.reset();

      let x = document.getElementById('thisModal');
      x.click();
      if (x) {
        x.click();
        this.toastr.success(this.displyModel.successMessage);
      }


      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'successful-form-submission',
        'eventCallback': function () {
          console.log('ALL tags which fire on successful-form-submission have now fired');
        }
      });

    }, (err) => {
      this.spinner.hide();
      if (err.error.message == 'User already exists.') {
        this.toastr.error(this.displyModel.error)
        console.log(err.error.message)
      }
    });
  }


  formOpen() {
    // let y =  document.getElementById('positionId');
    // let rect =  y.getBoundingClientRect();
    // console.log(rect.top);
    this.registrationForm.reset();
  }
  public loginCheck = () => {
    let token = localStorage.getItem('authToken');
    if (token) {
      this.isLogin = true;
    }
    else {
      this.isLogin = false;
      //  let y = document.getElementById('openModalBtn');

      //  if(y){
      //    y.click();
      //  }
    }
  }

  openPicker() {
    let x = document.getElementById('picker');
    console.log(x);
  }

  scrolldown(el: HTMLElement) {
    this.downWord = false;
    this.upWord = true;
    el.scrollIntoView();
  }

  scrollUp(el: HTMLElement) {
    this.downWord = true;
    this.upWord = false;
    el.scrollIntoView();
  }

  onScroll(event) {
    let y = document.getElementById('positionId');
    let rect = y.getBoundingClientRect();
    console.log(rect.top);
    if (rect.top <= 516.515625) {
      this.downWord = false;
      this.upWord = true
    }
    else if (rect.top > 516.515625) {
      this.downWord = true;
      this.upWord = false;
    }

  }

  public checkScrollDirectionIsUp(event) {
    console.log(window.scrollY)

    if (event.wheelDelta) {
      return event.wheelDelta > 0;
    }
    return event.deltaY < 0;
  }

  public authCheck = () => {
    let token = localStorage.getItem('authToken');
    if (token) {
      return false;;
    }
    else {
      return true;
    }
  }

  public languageChange = (data: any) => {

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Language Switch',
      'eventCallback': function () {
        console.log('Language Switch Event Tags Triggered and Fired');
      }
    });
    if (data == 'ENG') {
      this.displyModel = this.EnglishObj;
      this.englishMode = true
    }
    else if (data == 'BM') {
      this.displyModel = this.BMObj;
      this.englishMode = false
    }
  }
  openPDF() {
    window.open(this.displyModel.T_C, "_blank");
  }
  openPDF2() {
    window.open(this.displyModel.T_C_2, "_blank")
  }


}
