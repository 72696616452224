import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../data.service';
import { HeaderComponent } from '../header/header.component';

declare var window: any;



@Component({
  selector: 'app-submit-code',
  templateUrl: './submit-code.component.html',
  styleUrls: ['./submit-code.component.scss'],
})
export class SubmitCodeComponent implements OnInit, AfterViewInit {
  constructor(
    public fb: FormBuilder,
    public service: DataService,
    public toster: ToastrService,
    private spinner: NgxSpinnerService
  ) { }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.openModal();
    }, 1000);
  }

  @ViewChild(HeaderComponent) header: HeaderComponent;
  ngOnInit(): void {
    this.formStater();
    this.logInCheck();
    this.loginFrom = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }
  // <-- Variable Section --> //
  public kotForm: FormGroup;
  public feildObj;
  isLogin = false;
  public loginFrom: FormGroup;
  public fieldsArray = [];
  public lastField = 0;
  public forgetPasswors = false;
  public error = false;
  public index;
  public codes = [
    'CIK157',
    'CIK192',
    'CIK186',
    'ASB243',
    'ASB207',
    'ASB261',
    'ASB276',
    'ASB295',
    'MKD347',
    'MKD310',
    'MKD349',
    'SNB451',
    'SNB492',
    'SNB435',
    'SNB460',
    'SNB403',
    'SNB415',
    'HKO500',
    'KTK511',
    'KTK781',
    'KTK723',
    'DTL841',
    'DTL820',
    'DTL858',
    'DTL807',
    'UDL573',
    'IFF946',
    'IFF909',
    'IFF955',
    'BDV549',
    'SEL529',
    'SEL598',
    'SWM535',
    'BKD380',
    'BKD354',
    'DNK747',
    'DNK796',
    'MMM971',
    'MMM933',
    'MMM942',
    'MMM978',
    'MMM903',
    'LPJ123',
    'LPJ179',
    'LPJ164',
    'LPJ188',
    'BRO729',
    'BRO758',
    'PCM900',
    'PNB001',
    'ANB002',
    'MNR003',
    'BTS004',
    'ATT005',
    'RIZ006',
    'MYB007',
    'SMP008',
    'UMW009',
    'SDB010',
    'SDP011',
    'SPS012',
    'MDF013',
    'PRD014',
    'MRB015',
    'PLT016',
    'VLT017',
    'CCM018',
    'DPB019',
    'SPE020',
    'RHB021',
    'CMB022',
    'BMB023',
    'AMB024',
    'ASB025',
    'LCH101',
    'CLO693',
    'BRO252',
    'BRO526',
    'JDV026',
     "LTV578",
'LTV945',
'LTV783',
'LTV609',
'LTV822',
'LTV451',
'LTV113',
'LTV637',
'LTV206',
'LTV362',
'LTV692',
'LTV080',
'LTV575',
'LTV184',
'LTV324',
'LTB595',
'LTB607',
'LTB137',
'LTB420',
'LTB785',
'LTB803',
'LTB529',
'LTB217'
  ];
  // <-- Function Secction --> //
  public formStater = () => {
    this.lastField = 0;
    this.kotForm = this.fb.group({
      kot: this.fb.array([
        this.fb.group({ kotValue: ['', Validators.required] }),
      ]),
    });
  };
  public addfield = () => {
    this.lastField = this.f.length;
    this.f.push(this.fb.group({ kotValue: ['', Validators.required] }));
  };

  public remove = (i) => {
    this.f.removeAt(i);
    this.lastField = this.f.length - 1;
  };

  get f() {
    return this.kotForm.get('kot') as FormArray;
  }

  password() {
    this.forgetPasswors = true;
  }
  public login = () => {
    this.spinner.show();
    this.service.signIn(this.loginFrom.value).subscribe((res: any) => {
      let x = document.getElementById('closeModal1');

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'login',
        'eventCallback': function () {
          console.log('Login Event Tags Triggered and Fired');
        }
      });

      this.loginFrom.reset();
      this.spinner.hide();
      if (x) {
        x.click();
      }
      localStorage.setItem('authToken', res.jwt);
      this.logInCheck();
    });
  };

  public submit = () => {
    this.index = null;
    this.error = false;
    if (this.kotForm.invalid) {
      console.log('Form IS in Valid');
      return;
    } else {
      let y = this.kotForm.value;
      let sameCode = false;

      for (let i = 0; i < y.kot.length; i++) {
        for (let j = i + 1; j < y.kot.length; j++) {
          if (y.kot[i].kotValue.toLowerCase() == y.kot[j].kotValue.toLowerCase()) {
            sameCode = true;
            this.toster.error(`Code is Same at Kot ${i + 1} and kot ${j + 1}`);
          }
        }
      }
      if (sameCode == false) {
        let codefound: any = false;
        y.kot.forEach((element: any, index) => {
          codefound = false;
          for (let i = 0; i <= this.codes.length; i++) {
            if (element.kotValue && this.codes[i]) {
              if (element.kotValue.toLowerCase() == this.codes[i].toLowerCase()) {
                codefound = true;
                element.status = true;
              }
            }
          }
          if (codefound == false) {
            document.getElementById(`err${index}`).innerHTML =
              'Code is Invalid';
          }
        });

        let decide = false;
        y.kot.forEach((element: any) => {
          if (!element.status) {
            decide = true;
          }
        });
        console.log(y.kot);
        console.log(decide);
        if (decide == false) {
          console.log(this.kotForm.value);
          console.log('All Fields Are Valid');
          let token = {
            jwt: localStorage.getItem('authToken'),
          };
          console.log(token);
          let alreadyRegiterd: any = false;
          this.spinner.show();
          this.service.getUserCodes(token).subscribe(
            (res: any) => {
              console.log(res);
              let data = res.result;
              this.spinner.hide();
              y.kot.forEach((element: any, index) => {
                data.forEach((elements, index2) => {
                  if (element.kotValue.toLowerCase() == elements.code.toLowerCase()) {
                    alreadyRegiterd = true;
                    document.getElementById(`err${index}`).innerHTML =
                      'Code Already Registered';
                  }
                });
              });
              if (alreadyRegiterd == false) {
                console.log('Finilly Are fine');
                this.addKot();
              }
            },
            (err) => {
              this.spinner.hide();
            }
          );
        }
      }
    }
  };

  public addKot = () => {
    this.spinner.show();
    let kotValues = [];
    let value = this.kotForm.value;
    console.log(value.kot);
    value.kot.forEach((element: any) => {
      console.log(element.kotValue);
      kotValues.push(element.kotValue);
    });

    console.log(kotValues);
    let obj = {
      codes: kotValues,
      jwt: localStorage.getItem('authToken'),
    };

    this.service.addKot(obj).subscribe(
      (res: any) => {
        console.log(res);
        this.kotForm.reset();
        this.spinner.hide();

        //Mana Kod Submission
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Mana Kod Submission',
          'eventCallback': function () {
            console.log('Mana Kod Submission Event Tags Triggered and Fired');
          }
        });

        this.toster.success('Kod Added successfully');
        this.formStater();
      },
      (err) => {
        this.toster.error(err.error.message + 'Please Try Again');
        this.spinner.hide();
      }
    );
  };

  public logInCheck = () => {
    this.service.login.subscribe((res) => {
      if (res == true) {
        this.isLogin = false;
      } else {
        this.isLogin = true;
      }
    });
  };
  public openModal() {
    let y = localStorage.getItem('authToken');
    if (!y) {
      this.header.openMD();
    }
  }

  public change = (i) => {
    document.getElementById(`err${i}`).innerHTML = '';
  };
}
declare global {
  interface Window {
    dataLayer: any[];
  }
}
