<app-header></app-header>
<div class="languageCont">
  <h6 class="my-0" (click)='languageChange("BM")' [class.select]='!englishMode'>BM</h6>
  <h6 class="mx-2">|</h6>
  <h6 (click)='languageChange("ENG")' [class.select]='englishMode'>EN</h6>
</div>


<section class="sect1">
  <div class="bgLayer"></div>
</section>


<div class="row sect2">
  <div class="col-sm-9 px-0 prizeCont">
    <img routerLink="/competitions" [src]="displyModel.image1" width="100%" style="cursor: pointer;">
    <!-- <img [src]="displyModel.image1" width="100%" data-toggle="modal" data-target="#this" (click)='formOpen()'> -->


    <!-- <div class="textCont">
        <h5 class="mb-3 appTxt CustFont">Hadiah-hadiah bernilai lebih daripada</h5>
        <h1 class="mb-4 CustFont text1 bold">RM500,000</h1>
        <h4 class="mb-0 CustFont">untuk dimenangi</h4>
        <button data-toggle="modal" data-target="#this" class="btn yellowBtn mt-4">DAFTAR SEKARANG</button>
      </div> -->
    <!-- <div class="text-center btnsCont">
        <img src="/assets/imgs/p7.svg" height="40px">
        <img src="/assets/imgs/p8.svg" height="40px" class="ml-4">
        <p class="mt-3 mb-0 appTxt">Baucar-Baucar Bernilai RM125,000</p>
      </div> -->

  </div>

  <div style="display: block;" class="col-sm-8">
    <div class="embed-responsive embed-responsive-4by3">
      <!-- <iframe class="embed-responsive-item" style="min-width:100%; min-height: 100%;" [src]="displyModel.src | safe"
        allowfullscreen></iframe> -->
        <iframe class="embed-responsive-item" style="min-width:100%; min-height: 100%;" src="https://player.vimeo.com/video/480604425"
        allowfullscreen></iframe>
        
    </div>

  </div>


  <!--   <div style="display: none;" class="row video-frame-row">
      <iframe class="video-frame" src="https://player.vimeo.com/video/470026805" frameborder="0"
        allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
   -->

  <div class="col-sm-9 text-center pt-5 detailCont" *ngIf='!englishMode'>
    <!-- <h5 class="mb-2 appTxt CustFont" style="font-family: Roboto !important;">UNTUK PERTAMA KALI</h5>
      <h2 class="mb-3 CustFont text1 bold" style="font-family: Roboto !important;">MINGGU SAHAM DIGITAL</h2>
      <h3 class="mb-0 font-weight-bolder" style="font-family: Roboto !important;">PNB</h3> -->
    <img [src]="displyModel.image2" class="mt-5" width="100%">
    <button *ngIf='isLogin' data-toggle="modal" data-target="#this" class="btn yellowBtn pinkBtn"
      (click)='formOpen()'>{{displyModel.buttonText}}</button>
  </div>
  <div class="col-sm-9 text-center pt-5 detailCont" *ngIf='englishMode'>
    <!-- <h5 class="mb-2 appTxt CustFont" style="font-family: Roboto !important;">PNB'S</h5>
      <h3 class="mb-0 font-weight-bolder" style="font-family: Roboto !important;">FIRST TIME EVER</h3>
      <h2 class="mb-3 CustFont text1 bold" style="font-family: Roboto !important;">MINGGU SAHAM DIGITAL</h2> -->

    <img [src]="displyModel.image2" class="mt-5" width="100%">
    <button data-toggle="modal" data-target="#this" class="btn yellowBtn pinkBtn"
      (click)='formOpen()'>{{displyModel.buttonText}}</button>
  </div>
  <div class="col-sm-12 partnerCont px-0">
    <div class="row justify-content-center">
      <div class="col-sm-8 px-0 text-center">
        <img src="/assets/imgs/Partners.jpeg" width="90%">
      </div>
    </div>
  </div>
</div>



<app-footer3></app-footer3>


<div class="modal fade" id="this">
  <div class="modal-dialog myModal modal-dialog-centered">
    <div class="modal-content">
      <i id="thisModal" class="fas fa-times closeBtn pointer" data-dismiss="modal" (click)='scrollUp(target2)'></i>
      <div class="inputCont" id='Sdiv' (scroll)="onScroll($event)">

        <h5 class="mt-2 mb-3" #target2>{{displyModel.ModalHeader1}}<br /> {{displyModel.ModalHeader2}}</h5>
        <form [formGroup]='registrationForm'>
          <div class="row justify-content-center">

            <div class="col-sm-8 col-9 px-0">

              <div class="form-group">
                <label>{{displyModel.field1}}</label>
                <input formControlName='name' type="text" class="form-control">
              </div>
              <div class="form-group">
                <label>{{displyModel.field4}}</label>
                <input class="form-control" type='number' formControlName='phone'>
              </div>
              <div class="form-group">
                <label>{{displyModel.field2}}</label>
                <input formControlName='email' (click)="scrolldown(target)" type="email" class="form-control"
                  [ngClass]="{ 'is-invalid': f.email.errors }">

                <div *ngIf=" f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                </div>
              </div>

              <div class="form-group">
                <label>{{displyModel.field3}}</label>
                <input formControlName='nric' type='text' class="form-control"
                  [ngClass]="{ 'is-invalid': f.nric.errors }">
                <div *ngIf=" f.nric.errors" class="invalid-feedback" [ngClass]="{ 'is-invalid': f.nric.errors }">
                  <div *ngIf="f.nric.errors.pattern">No Special character Allowed </div>
                  <div *ngIf="f.nric.errors.maxlength">Max 12 characters are Allowed </div>
                </div>

              </div>
              <div class="form-group">
                <label>{{displyModel.field5}}</label>
                <input formControlName='password' type="password" class="form-control"
                  [ngClass]="{ 'is-invalid': f.password.errors }">

                <div *ngIf=" f.password.errors" class="invalid-feedback">

                  <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                </div>
              </div>

              <div class='row'>
                <p style="width: 100%;margin: 0px;padding-top: 0.2rem;">
                  <img *ngIf='upWord' class="fixed" (click)="scrollUp(target2)" src="assets/imgs/up-arrow.svg">
                  <img *ngIf='downWord' class="fixed" (click)="scrolldown(target)" src="assets/imgs/down-arrow.svg">
                </p>
              </div>

              <div class="custom-control custom-checkbox">
                <input checked type="checkbox" class="custom-control-input" id="customCheck" name="example1">
                <label class="custom-control-label" for="customCheck">{{displyModel.bottomText}}</label>
              </div>
              <button id='positionId' #target class="btn darkBtn btn-block" [disabled]='registrationForm.invalid'
                (click)='submit()'>{{displyModel.ModalBtn}}</button>

              <p class="font-14 my-0 italic-small">{{displyModel.bySubmitting}}</p>

              <p class="font-14 my-0 pointer" (click)='openPDF()'><u>{{displyModel.info}}</u></p>
              <p class="font-14 my-0 pointer" (click)='openPDF2()'><u>{{displyModel.info2}}</u></p>
            </div>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="referModal">
  <div class="modal-dialog myModal modal-dialog-centered">
    <div class="modal-content">
      <i class="fas fa-times closeBtn pointer" id="RefcloseModal" data-dismiss="modal"></i>
      <div class="inputCont">
        <h5 class="mt-2 mb-4">Dapatkan peluang untuk<br /> memenagi hadiah mewah!</h5>
        <div class="row justify-content-center">
          <div class="col-sm-8 col-9 px-0">
            <form [formGroup]='refForm'>
              <div class="form-group">
                <label>Bank</label>
                <select class="form-control" formControlName='agent_bank' (change)='bankSelection($event.target.value)'>
                  <option hidden value="" disabled selected>Select Bank</option>
                  <option *ngFor='let data of classifyArray' [value]='data.bankName'>{{data.bankName}}</option>


                </select>
              </div>
              <div class="form-group">
                <label>State</label>
                <select class="form-control" formControlName='state' (change)='stateSelection($event.target.value)'>
                  <option hidden value="" disabled selected>Select State</option>
                  <option *ngFor='let data of states' [value]='data.stateName'>{{data.stateName}}</option>

                </select>
              </div>
              <div class="form-group">
                <label>Branch</label>
                <select class="form-control" formControlName='branch'>
                  <option disabled value="" selected>Select Branch</option>
                  <option *ngFor='let data of branches' [value]='data'>{{data}}</option>
                </select>
              </div>
            </form>
            <button class="btn darkBtn btn-block" (click)='submitReferal()' [disabled]='refForm.invalid'>HANTAR</button>
            <!--  <p class="font-14 my-0 pointer"><u>Akta Perlindungan Data Peribadi 2010</u></p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<button style="display: none;" data-toggle="modal" data-target="#referModal" id="refModal"
  class="btn loginBtn">Daftar</button>


<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fall" [fullScreen]="true">
  <p style="color: white"></p>
</ngx-spinner>
