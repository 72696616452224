<app-header></app-header>
<div *ngIf="section == 'sime_plant'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <!--  -->
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img style="cursor: pointer" (click)="logoSite('http://www.simedarby.com/')" src="/assets/Darby/logo.png"
            height="100%" width="70%" />
          <p class="appTxt mb-0 mt-5">
            {{ displyModel.titleText }}
          </p>

          <p routerLink="/submit-code" class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer">
            KOD: SDB010
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">

          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/7xPYirJj9IY?&loop=0"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
          <li data-target="#expertsSlider" data-slide-to="4"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img (click)="hyperlink('http://www.simedarby.com')" src="assets/Darby/1-Corporate_Simedarby.jpg"
                  class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" (click)="hyperlink('http://www.simedarbyindustrial.com')"
                  src="assets/Darby/2 - Industrial_Simedarby.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9">
                <img style="cursor: pointer" (click)="hyperlink('http://www.simedarbymotors.com')"
                  src="assets/Darby/3 - Motors_Simedarby.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9">
                <img style="cursor: pointer" (click)="hyperlink('http://www.ramsaysimedarby.com')"
                  src="/assets/Darby/4 - Healthcare_Simedarby.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9">
                <img style="cursor: pointer" (click)="hyperlink('http://www.simedarbylogistics.com')"
                  src="assets/Darby/5 - Logistics_Simedarby.jpg" class="slideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="section == 'UMW'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img (click)="logoSite('https://www.umw.com.my/')" src="/assets/UMW/logo.png" height="100%" width="70%" />
          <p class="appTxt mb-0 mt-5">
            {{ displyModel.titleText }}
          </p>
          <p class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer" routerLink="/submit-code">
            KOD: UMW009
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/xHptr-dyvR0?&loop=0"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
          <li data-target="#expertsSlider" data-slide-to="4"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/UMW/1.JPG" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="assets/UMW/2.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="assets/UMW/3.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/UMW/4.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/UMW/5.JPG" class="slideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="section == 'setia'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img (click)="logoSite('https://www.spsetia.com/')" src="/assets/setia/SETIA Colored Logo_1570 x 470-01.png"
            height="100%" width="70%" />
          <p class="appTxt mb-0 mt-5">
            {{ displyModel.titleText }}
          </p>

          <p class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer" routerLink="/submit-code">
            KOD: SPS012
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/pGc32xcy9P4?&loop=0"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
          <li data-target="#expertsSlider" data-slide-to="4"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/setia/1.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/setia/2.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/setia/3.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/setia/4.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/setia/5.png" class="slideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="section == 'MIDF'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img class="mt-5" (click)="logoSite('http://www.midf.com.my/index.php/en/')" src="/assets/MIDF/logo2.png"
            height="100%" width="70%" />
          <p class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer" routerLink="/submit-code">
            KOD: MDF013
          </p>
        </div>
        <div class="col-sm-6 align-center">
          <p class="appTxt">
            {{ displyModel.titleText }}
          </p>
        </div>
      </div>
    </div>

    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
          <li data-target="#expertsSlider" data-slide-to="4"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/MIDF/6.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/MIDF/5.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/MIDF/9.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/MIDF/10.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" (click)="hyperlink('http://www.midf.com.my/index.php/en/MSTG2020')"
                  src="/assets/MIDF/11.jpg" class="slideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="section == 'MNRB'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img style="cursor: pointer" (click)="logoSite('https://www.mnrb.com.my/')" src="/assets/MNRB/400x300logo.png"
            height="100%" width="70%" />
          <p class="appTxt mb-0 mt-5">
            {{ displyModel.titleText }}
          </p>

          <p class="appTxt mb-0 mt-5" style="color: #f3c50d">
            KOD: MRB015
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/LjDcH4WobMQ?&loop=0"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
          <li data-target="#expertsSlider" data-slide-to="4"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img (click)='hyperlink("https://www.takaful-ikhlas.com.my/minggu-saham-digital")'
                  [src]="displyModel.image1" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img (click)='hyperlink("https://www.takaful-ikhlas.com.my/minggu-saham-digital")'
                  [src]="displyModel.image2" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img (click)='hyperlink("https://www.takaful-ikhlas.com.my/minggu-saham-digital")'
                  [src]="displyModel.image3" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img [src]="displyModel.image4" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img [src]="displyModel.image5" class="slideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="section == 'CCM'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img style="cursor: pointer" (click)="logoSite('https://www.ccmberhad.com/')"
            src="assets/CCM/CCM logo only.png" height="100%" width="70%" />

          <p class="appTxt mb-0 mt-5">
            {{ displyModel.titleText }}
          </p>
          <p class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer" routerLink="/submit-code">
            KOD: CCM018
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/-km4L-B5qI0?&loop=0"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
          <li data-target="#expertsSlider" data-slide-to="4"></li>
          <li data-target="#expertsSlider" data-slide-to="5"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="assets/CCM/CCM_-01.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="assets/CCM/CCM_-02.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="assets/CCM/CCM_-03.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="assets/CCM/CCM_-04.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/CCM/CCM_-05.jpg" class="slideImg" />
              </div>
            </div>
          </div>

          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/CCM/CCM_-06.jpg" class="slideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Duophrma -->

<div *ngIf="section == 'Duophrma'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img style="cursor: pointer" (click)="logoSite('https://duopharmabiotech.com/')"
            src="assets/Duophrma/duopharma.png" height="100%" width="70%" />

          <p class="appTxt mb-0 mt-5">
            {{ displyModel.titleText }}
          </p>
          <p class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer" routerLink="/submit-code">
            KOD: DPB019
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/el4l5jtTZBw?&loop=0"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
          <li data-target="#expertsSlider" data-slide-to="4"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/Duophrma/Minggu Saham 2020 - Champs M (microsite).png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/Duophrma/Minggu Saham 2020 - Flavettes (Approved).png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9  pointer">
                <img src="/assets/Duophrma/Minggu Saham 2020 - Naturalle Tini (microsite).png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/Duophrma/Minggu Saham 2020 - Proviton (Approved).png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/Duophrma/Minggu Saham 2020 - Uphamol (microsite).png" class="slideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="section == 'velesto'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img style="cursor: pointer" (click)="logoSite('https://velesto.com/')" src="/assets/velesto/velesto.png"
            height="100%" width="70%" />

          <p class="appTxt mb-0 mt-5">
            {{ displyModel.titleText }}
          </p>
          <p class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer" routerLink="/submit-code">
            KOD: VLT017
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/aYFjWBVtea0?&loop=0"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
          <li data-target="#expertsSlider" data-slide-to="4"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/velesto/Drilling Academy 1 & 2_Velesto-01.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/velesto/Drilling Academy 3 &4 _Velesto-02.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/velesto/GAIT_Velesto-03.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/velesto/RIG 1_Velesto-04.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/velesto/Rig 2_Velesto-05.jpg" class="slideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- sapura -->
<div *ngIf="section == 'sapura'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img style="cursor: pointer" (click)="logoSite('https://www.sapuraenergy.com/')" src="/assets/sapura/Logo.png"
            height="100%" width="70%" />

          <p class="appTxt mb-0 mt-5">
            {{ displyModel.titleText }}
          </p>

          <p class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer" routerLink="/submit-code">
            KOD: SPE020
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/hKbnq_CBtzY?&loop=0"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
          <li data-target="#expertsSlider" data-slide-to="4"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/sapura/1. Navigating Forward.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/sapura/2. E&C.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/sapura/3. E&P.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/sapura/4. Drilling.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/sapura/5. Fabrication.png" class="slideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="section == 'Prolintas'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img style="cursor: pointer" (click)="logoSite('http://www.prolintas.com.my/')"
            src="assets/Prolintas/prolintas.png" height="100%" width="70%" />

          <p class="appTxt mb-0 mt-5">
            {{ displyModel.titleText }}
          </p>
          <p class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer" routerLink="/submit-code">
            KOD: PLT016
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/HHV1pLQkoGI?&loop=0"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
          <li data-target="#expertsSlider" data-slide-to="4"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/Prolintas/PHOTO PROLINTAS FOR PNB MSAM 2020-01.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/Prolintas/PHOTO PROLINTAS FOR PNB MSAM 2020-02.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="assets/Prolintas/PHOTO PROLINTAS FOR PNB MSAM 2020-03.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="assets/Prolintas/PHOTO PROLINTAS FOR PNB MSAM 2020-04.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="assets/Prolintas/PHOTO PROLINTAS FOR PNB MSAM 2020-05.png" class="slideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="section == 'AMBank'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img style="cursor: pointer" (click)="logoSite('http://www.ambankgroup.com/')"
            src="/assets/AMBank/AmBank 400x336.png" height="100%" width="70%" />
          <p class="appTxt mb-0 mt-5">
            {{ displyModel.titleText }}
          </p>
          <p class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer" routerLink="/submit-code">
            KOD: AMB024
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/wyRFj1syUPs?&loop=0"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" (click)="
                    hyperlink(
                      'https://www.ambank.com.my/eng/loan_asb_financing'
                    )
                  " src="/assets/AMBank/ASB.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" (click)="
                    hyperlink(
                      'https://www.ambank.com.my/eng/promotions-page/Cashback-Acquisition-Campaign'
                    )
                  " src="/assets/AMBank/CashRebate.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" (click)="
                    hyperlink(
                      'https://ambankspot.com/campaign/amazing30/'
                    )
                  " src="/assets/AMBank/Amazing30.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" (click)="
                    hyperlink(
                      'https://www.ambank.com.my/eng/promotions-page/CASA-Payroll-Campaign'
                    )
                  " src="/assets/AMBank/CASAPayroll.png" class="slideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="section == 'bankIslam'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <!--   KOD: BMB023 -->
  <div class="row mainCont">
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img style="cursor: pointer" (click)="logoSite('https://www.bankislam.biz/')" src="/assets/BankIslam/logo.png"
            height="100%" width="70%" />

          <p class="appTxt mb-0 mt-5">
            {{ displyModel.titleText }}
          </p>
          <p class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer" routerLink="/submit-code">
            KOD: BMB023
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/f8xLAeMQFY8?&loop=0"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
          <li data-target="#expertsSlider" data-slide-to="4"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/BankIslam/ASB Financing.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img
                  src="/assets/BankIslam/Eastspring Dana al-Ilham_Placemat_August2020_Final Approved_01.09.2020_2....png"
                  class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img
                  src="/assets/BankIslam/Eastspring Dana al-Ilham_Placemat_August2020_Final Approved_01.09.2020_2..._1.png"
                  class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/BankIslam/iGlobal-Webanner-RV-1-2310.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="assets/BankIslam/Sukuk-i-Growth-Webanner-RV1.png" class="slideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="section == 'ASNB'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img style="cursor: pointer" (click)="logoSite('https://www.myasnb.com.my/')"
            src="/assets/ASNB/ASNB 1570x470.png" height="100%" width="70%" />

          <p class="appTxt mb-0 mt-5">
            {{ displyModel.titleText }}
          </p>
          <p class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer" routerLink="/submit-code">
            KOD: ANB002
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/TtqeZROUuoo?&loop=0"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
          <li data-target="#expertsSlider" data-slide-to="4"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img (click)='hyperlink("https://www.asnb.com.my/ASB30tahun_KumpulMenang.php")' src="/assets/ASNB/5.png"
                  class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img (click)='hyperlink("https://www.asnb.com.my/ASB30tahun_JomAutoLabur.php")' src="/assets/ASNB/4.png"
                  class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img (click)='hyperlink("https://www.asnb.com.my/ASB30tahun_HapaOct.php#HA")' src="/assets/ASNB/2.png"
                  class="slideImg" />
              </div>
            </div>
          </div>

          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img (click)='hyperlink("https://www.asnb.com.my/ASB30tahun_HapaOct.php#PA")' src="/assets/ASNB/3.png"
                  class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img (click)='hyperlink("https://www.asnb.com.my/asnbv2_2funds.php#asn-i3")' src="/assets/ASNB/1.png"
                  class="slideImg" />
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="section == 'SimeDarbyProperty'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img style="cursor: pointer" (click)="logoSite('https://www.simedarbyproperty.com/')"
            src="/assets/Revised Logo and Images/SDP LOGO_400(px) x 470(px)/SIMEDARBY PROPERTY___WhiteBG_1570w x 470h.png"
            height="100%" width="70%" />

          <p class="appTxt mb-0 mt-5">
            {{ displyModel.titleText }}
          </p>
          <p class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer" routerLink="/submit-code">
            KOD:SDP011
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/l-pPiEPtq7o?&loop=0"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
          <li data-target="#expertsSlider" data-slide-to="4"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img
                  src="assets/Revised Logo and Images/SDP Product Image 2340(px) x 1065(px)/SDP_Product Image_2340(px) x1065(px)-01.png"
                  class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img
                  src="assets/Revised Logo and Images/SDP Product Image 2340(px) x 1065(px)/SDP_Product Image_2340(px) x1065(px)-02.png"
                  class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img
                  src="assets/Revised Logo and Images/SDP Product Image 2340(px) x 1065(px)/SDP_Product Image_2340(px) x1065(px)-03.png"
                  class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img
                  src="assets/Revised Logo and Images/SDP Product Image 2340(px) x 1065(px)/SDP_Product Image_2340(px) x1065(px)-04.png"
                  class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img
                  src="assets/Revised Logo and Images/SDP Product Image 2340(px) x 1065(px)/SDP_Product Image_2340(px) x1065(px)-05.png"
                  class="slideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="section == 'RHB'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img style="cursor: pointer" (click)="logoSite('https://www.rhbgroup.com/')"
            src="/assets/Microsite (1)/RHB Logo.png" height="100%" width="70%" />

          <p class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer" routerLink="/submit-code">
            KOD: RHB021
          </p>
        </div>
        <div class="col-sm-6 videoCont">
          <p class="appTxt mb-0">
            {{ displyModel.titleText }}
          </p>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
          <li data-target="#expertsSlider" data-slide-to="4"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" (click)="
                    hyperlink(
                      'https://www.rhbgroup.com/utfd1/index.html?utm_source=MSAM&utm_medium=Banner&utm_campaign=utfd'
                    )
                  " src="/assets/Microsite (1)/1.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" (click)="
                    hyperlink(
                      'https://www.rhbgroup.com/jom/index.html?utm_source=MSAM&utm_medium=Banner&utm_campaign=jomsimpan'
                    )
                  " src="/assets/Microsite (1)/2.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" (click)="
                    hyperlink(
                      'https://www.rhbgroup.com/personalfinancing-i_thematicBM/index.html?utm_source=MSAM&utm_medium=Banner&utm_campaign=PersonalFinancing'
                    )
                  " src="/assets/Microsite (1)/3.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" (click)="
                    hyperlink(
                      'https://rhbgroup.com/BTCX_2020/index.html?utm_source=MSAM&utm_medium=Banner&utm_campaign=BTCX'
                    )
                  " src="/assets/Microsite (1)/4.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" (click)="
                    hyperlink(
                      'https://www.rhbgroup.com/asb/index.html?utm_source=MSAM&utm_medium=Banner&utm_campaign=asbfinancing'
                    )
                  " src="/assets/Microsite (1)/5.jpg" class="slideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="section == 'ASB'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <!-- <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img style="cursor: pointer" (click)="logoSite('https://www.asb.edu.my/')"
            src="/assets/Asia School of Business/ASB 1570x470.png" height="100%" width="70%" />

          <p class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer" routerLink="/submit-code">
            KOD: ASB025
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">
          <p class="appTxt mb-0">
            {{ displyModel.titleText }}
          </p>
        </div>
      </div>
    </div> -->
    <!--  sample -->
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img style="cursor: pointer" (click)="logoSite('https://www.asb.edu.my/')"
            src="/assets/Asia School of Business/ASB 1570x470.png" height="100%" width="70%" />

          <p class="appTxt mb-0 mt-5">
            {{ displyModel.titleText }}
          </p>
          <p class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer" routerLink="/submit-code">
            KOD: ASB025
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/4_gwbH30MxI?&loop=0"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>

    <!-- end -->
    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
          <li data-target="#expertsSlider" data-slide-to="4"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/Asia School of Business/1.png" class="slideImg"
                  (click)='hyperlink("https://asb.edu.my/academic-program/mba-program")' />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/Asia School of Business/2.png" class="slideImg"
                  (click)='hyperlink("https://asb.edu.my/academic-program/mba-working-professionals")' />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/Asia School of Business/3.png" class="slideImg"
                  (click)='hyperlink("https://asb.edu.my/executive-education")' />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/Asia School of Business/4.png" class="slideImg"
                  (click)='hyperlink("https://asb.edu.my/action-learning")' />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/Asia School of Business/5.png" class="slideImg"
                  (click)='hyperlink("https://asb.edu.my/getsmartnsharp")' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="section == 'Plantation'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img (click)="logoSite('http://www.simedarbyplantation.com/')" src="/assets/Plantation/1570x470-01.png"
            style="cursor: pointer" height="100%" width="70%" />
          <p class="appTxt mb-0 mt-5">
            {{ displyModel.titleText }}
          </p>

          <p class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer" routerLink="/submit-code">
            KOD: SMP008
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/xsjxMCboVcg?&loop=0"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
          <li data-target="#expertsSlider" data-slide-to="4"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" (click)="hyperlink('http://www.simedarbyplantation.com/')"
                  src="/assets/Plantation/image-01.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" (click)="hyperlink('http://www.simedarbyplantation.com/')"
                  src="/assets/Plantation/image-02.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" (click)="hyperlink('http://www.simedarbyplantation.com/')"
                  src="/assets/Plantation/image-03.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" (click)="hyperlink('http://www.simedarbyplantation.com/')"
                  src="/assets/Plantation/image-04.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" (click)="hyperlink('https://www.simedarbyoils.com/')"
                  src="/assets/Plantation/image-05.jpg" class="slideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- CIMB -->
<div *ngIf="section == 'CIMB'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <!--  -->
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img style="cursor: pointer" (click)="logoSite('https://www.cimbclicks.com.my/')" src="/assets/CIMB/cimb.png"
            height="100%" width="70%" />

          <p class="appTxt mb-0 mt-5">
            {{ displyModel.titleText }}
          </p>
          <p routerLink="/submit-code" class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer">
            KOD: CMB022
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/KacomcyXCMQ?&loop=0"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img (click)="
                    hyperlink(
                      'https://www.cimb.com.my/en/personal/day-to-day-banking/insurance-takaful/car-insurance/ikhlas-privatecar-takaful.html?cid=a1:pi_a2:banca_a3:motorwakaf_a4:140920_a5:ban_a6:bank_a7:promo_a8:ban2'
                    )
                  " src="{{ displyModel.image1 }}" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" (click)="
                    hyperlink(
                      'https://www.cimb.com.my/ecard?cid=a1:pb_a2:cc_a3:ecard_a4:021120_a5:afl_a6:bank_a7:thumb_a8:ban1'
                    )
                  " src="{{ displyModel.image2 }}" class="slideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- attana-->

<div *ngIf="section == 'attana'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <!--  -->
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img style="cursor: pointer" (click)="logoSite('http://www.attanahotels.com/')"
            src="/assets/attana/Attana_LOGO_1570 w x 336 h_ATTANA_B.png" height="100%" width="70%" />

          <p class="appTxt mb-0 mt-5">
            {{ displyModel.titleText }}
          </p>
          <p routerLink="/submit-code" class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer">
            KOD: ATT005
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/lES3Tuuhwv0?&loop=0"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
          <li data-target="#expertsSlider" data-slide-to="4"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="{{ displyModel.image1 }}" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" src="{{ displyModel.image2 }}" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" src="{{ displyModel.image3 }}" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9">
                <img style="cursor: pointer" src="{{ displyModel.image4 }}" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" src="{{ displyModel.image5 }}" class="slideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- jdv -->
<div *ngIf="section == 'jdv'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img (click)="logoSite('https://www.jeweldv.com/')" src="/assets/jvd/JDV Logo_1570x470px-01.png"
            style="cursor: pointer" height="100%" width="70%" />

          <p class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer" routerLink="/submit-code">
            KOD:JDV026
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">
          <p class="appTxt mb-0">
            {{ displyModel.titleText }}
          </p>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="assets/jdv/JDV_PartnerPgPhotos-01.png" class="slideImg"
                  (click)="hyperlink('http://msd2020.jeweldv.com/')" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" src="assets/jdv/JDV_PartnerPgPhotos-02.png" class="slideImg"
                  (click)="hyperlink('http://msd2020.jeweldv.com/')" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" src="assets/jdv/JDV_PartnerPgPhotos-03.png" class="slideImg"
                  (click)="hyperlink('http://msd2020.jeweldv.com/')" />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- raiz -->
<div *ngIf="section == 'raiz'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <!--  -->
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img style="cursor: pointer" (click)="logoSite('https://www.raiz.com.my/')"
            src="/assets/raiz/RAIZ Logo_1570x470px-01.png" height="100%" width="70%" />
          <p class="appTxt mb-0 mt-5">
            {{ displyModel.titleText }}
          </p>
          <p routerLink="/submit-code" class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer">
            KOD: RIZ006
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/-OMJ2pFXoWo?loop=0&t=103s"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
          <li data-target="#expertsSlider" data-slide-to="4"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="{{ displyModel.image1 }}" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" src="{{ displyModel.image2 }}" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" src="{{ displyModel.image3 }}" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" src="{{ displyModel.image4 }}" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" src="{{ displyModel.image5 }}" class="slideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- merdeka-->

<div *ngIf="section == 'merdeka'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <!--  -->
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img style="cursor: pointer" (click)="logoSite('https://www.merdeka118.com/')"
            src="/assets/merdeka/Merdeka 188 Logo_1570x470px-01.png" height="100%" width="70%" />

          <p routerLink="/submit-code" class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer">
            KOD: MNR003
          </p>
        </div>
        <div class="col-sm-6 align-center">
          <p class="appTxt mb-5">
            {{ displyModel.titleText }}
          </p>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
          <li data-target="#expertsSlider" data-slide-to="4"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="{{ displyModel.image1 }}" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" src="{{ displyModel.image2 }}" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" src="{{ displyModel.image3 }}" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" src="{{ displyModel.image4 }}" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" src="{{ displyModel.image5 }}" class="slideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Perodua -->
<div *ngIf="section == 'perodua'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img (click)="logoSite('https://www.perodua.com.my/')" style="cursor: pointer"
            src="/assets/Perodu/1570x470-PERODUA Logo.png" height="100%" width="70%" />

          <p class="appTxt mb-0 mt-5">
            {{ displyModel.titleText }}
          </p>
          <p class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer" routerLink="/submit-code">
            KOD: PRD014
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/uDmx1PqXtWs?&loop=0"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
          <li data-target="#expertsSlider" data-slide-to="4"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/Perodu/imgs/1 Manifesto.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/Perodu/imgs/2 Our-People.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="/assets/Perodu/imgs/3 Sustainability.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img src="assets/Perodu/imgs/4 Safety.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img (click)='hyperlink("https://www.perodua.com.my/our-models/aruz.html")'
                  src="assets/Perodu/imgs/5 Aruz to website.png" class="slideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="section == 'MayBank'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img (click)="logoSite('https://www.maybank.com/')" style="cursor: pointer"
            src="/assets/MyBank/MAYBANK_ID_BOX-01.png" height="100%" width="70%" />

          <p class="appTxt mb-0 mt-5">
            {{ displyModel.titleText }}
          </p>
          <p class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer" routerLink="/submit-code">
            KOD: MYB007
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/DVhOFSXLbr4?&loop=0"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
          <li data-target="#expertsSlider" data-slide-to="4"></li>
        </ul>
        <div class="carousel-inner row" role="listbox">
          <div class="carousel-item col-sm-12 px-0 active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img (click)="
                    hyperlink(
                      'https://www.maybank2u.com.my/maybank2u/malaysia/en/personal/promotions/loans/promo_my_asb1120.page?utm_source=pnb&utm_medium=affliate&utm_campaign=cnv-rf-mass-MSD-18112020&utm_content=microsite-asbf-350'
                    )
                  " src="/assets/MyBank/1-mbb.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img (click)="
                    hyperlink(
                      'https://www.maybank2u.com.my/maybank2u/malaysia/en/business/financing/sme_digital_financing.page?utm_source=pnb&utm_medium=affliate&utm_campaign=awa-sme-mass-MSD-18112020&utm_content=microsite-sme-digital'
                    )
                  " src="/assets/MyBank/2-mbb.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img (click)="
                    hyperlink(
                      'https://www.maybank2u.com.my/maybank2u/malaysia/en/personal/services/digital_banking/mae_by_maybank2u.page?utm_source=pnb&utm_medium=affliate&utm_campaign=awa-m2u-mass-MSD-18112020&utm_content=microsite-maya'

                    )
                  " src="/assets/MyBank/3-mbb.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img (click)="
                    hyperlink(
                      'https://www.maybank2u.com.my/maybank2u/malaysia/en/personal/promotions/account_and_banking/promo_my_casa-growth-bm.page?utm_source=pnb&utm_medium=affliate&utm_campaign=awa-dep-mass-MSD-18112020&utm_content=microsite-casa'

                    )
                  " src="/assets/MyBank/4-mbb.jpg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img (click)="
                    hyperlink(
                      'https://www.maybank2u.com.my/maybank2u/malaysia/en/personal/promotions/investment_insurance/promo_my_etiqa-gi0820.page?utm_source=pnb&utm_medium=affliate&utm_campaign=awa-banca-mass-MSD-18112020&utm_content=microsite-etiqa'
                    )
                  " src="/assets/MyBank/5-mbb.jpg" class="slideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="section == 'PNB'">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="row mainCont">
    <!--  -->
    <div class="col-sm-9 px-0">
      <div class="row">
        <div class="col-sm-6 align-center">
          <img style="cursor: pointer" (click)="logoSite('https://www.pnb.com.my/')"
            src="/assets/PNB/PNB_1570(w) x 470(h)-01.png" height="100%" width="70%" />

          <p class="appTxt mb-0 mt-5">
            {{ displyModel.titleText }}
          </p>

          <p routerLink="/submit-code" class="appTxt mb-0 mt-5" style="color: #f3c50d; cursor: pointer">
            KOD: PNB001
          </p>
        </div>
        <div class="col-sm-6 videoCont" style="height: 100%">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/2o4ZCuLffg0?&loop=0"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <!--   -->

    <div class="col-sm-12 px-0 sliderCont">
      <div id="expertsSlider" class="carousel slide imgSlider" data-ride="carousel">
        <ul class="carousel-indicators">
          <li data-target="#expertsSlider" data-slide-to="0" class="active"></li>
          <li data-target="#expertsSlider" data-slide-to="1"></li>
          <li data-target="#expertsSlider" data-slide-to="2"></li>
          <li data-target="#expertsSlider" data-slide-to="3"></li>
          <li data-target="#expertsSlider" data-slide-to="4"></li>
        </ul>
        <div class="carousel-inner row " role="listbox">

          <div class="carousel-item col-sm-12 px-0 pointer active">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" (click)="
                    hyperlink(
                      'https://www.pnb.com.my/02_1Corporate_Information.php'
                    )
                  " src="/assets//PNB/IMAGE_2340 (w) x 1065 (h)__IMAGE_7.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0 pointer">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" (click)="
                    hyperlink('https://www.pnb.com.my/02_4Value_Chain.php')
                  " src="/assets//PNB/IMAGE_2340 (w) x 1065 (h)__IMAGE_8.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0 pointer">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" (click)="
                    hyperlink('https://www.pnb.com.my/03_5InvestmentPhilosophyProcess.php')
                  " src="/assets//PNB/IMAGE_2340 (w) x 1065 (h)__IMAGE_9.png" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img (click)="
                    hyperlink(
                      'https://www.pnb.com.my/05_3Community_Upliftment.php'
                    )
                  " src="/assets//PNB/IMAGE_2340 (w) x 1065 (h)__IMAGE_6.jpeg" class="slideImg" />
              </div>
            </div>
          </div>
          <div class="carousel-item col-sm-12 px-0 pointer">
            <div class="row justify-content-center">
              <div class="col-sm-9 pointer">
                <img style="cursor: pointer" (click)="hyperlink('https://www.pnb.com.my/07_1Career.php')"
                  src="/assets//PNB/IMAGE_2340 (w) x 1065 (h)__IMAGE_10.png" class="slideImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
