<app-header></app-header>

<div class="row mainCont">
  <div class="languageCont">
    <h6 class="my-0" (click)="languageChange('BM')" [class.select]="!englishMode">
      BM
    </h6>
    <h6 class="mx-2">|</h6>
    <h6 (click)="languageChange('ENG')" [class.select]="englishMode">EN</h6>
  </div>
  <div class="col-sm-10">
    <h1 class="CustFont text-center">{{ mainObj.title }}</h1>
    <!-- <div id="expertsSlider" class="carousel slide programsSlider" data-ride="carousel" data-interval="false">
      <div class="carousel-inner row" role="listbox">
        <div class="carousel-item col-sm-12 px-0 active">
          <div class="row justify-content-around">
            <div class="col-sm-5 text-center">
              <img src="/assets/imgs/ipad.png" width="95%">
            </div>
            <div class="col-sm-6 programDetailCont">
              <h3 class="CustFont mb-3">Eh, Mana kod?</h3>
              <p class="font-17">
                Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, adipiscing elit.
              </p>
              <button routerLink="/submit-code" class="btn yellowBtn mt-5">Eh, Dah Dapat KOD?</button>
            </div>
          </div>
        </div>
        <div class="carousel-item col-sm-12 px-0">
          <div class="row justify-content-around">
            <div class="col-sm-5 text-center">
              <img src="/assets/imgs/ipad.png" width="95%">
            </div>
            <div class="col-sm-6 programDetailCont">
              <h3 class="CustFont mb-3">Eh, Mana kod?</h3>
              <p class="font-17">
                Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, adipiscing elit.
              </p>
              <button routerLink="/submit-code" class="btn yellowBtn mt-5">Eh, Dah Dapat KOD?</button>
            </div>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#expertsSlider" role="button" data-slide="prev">
        <img src="/assets/imgs/left _Arrow.svg" height="40px">
      </a>
      <a class="carousel-control-next" href="#expertsSlider" role="button" data-slide="next">
        <img src="/assets/imgs/right_Arrow.svg" height="40px">
      </a>
    </div> -->

    <ul class="nav nav-tabs shadow" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" data-toggle="tab" href="#competition1" role="tab" aria-controls="competition1">Eh,
          Mana Kod?</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#competition2" role="tab" aria-controls="competition2">PASS THE
          MIC</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#competition3" role="tab"
          aria-controls="competition3">{{ mainObj.btn1 }}
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#competition4" role="tab" aria-controls="competition4">live
          trivia</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#competition5" role="tab"
          aria-controls="competition5">#LakaranJuara</a>
      </li>
    </ul>

    <div class="row justify-content-center">
      <div class="col-sm-11">
        <div class="tab-content">
          <div class="tab-pane active" id="competition1" role="tabpanel">
            <div class="text-center competitionImg">
              <img [src]="dis_Obj1.img" width="100%" />
            </div>
            <div class="row justify-content-center mt-5 pt-3">
              <div class="col-sm-8 text-center">
                <h5 class="CustFont mb-2" [innerHTML]="dis_Obj1.heading1.title"></h5>
                <p (click)="MOdal()" class="mb-4 pb-2 appTxt" [innerHTML]="dis_Obj1.heading1.text"
                  style='cursor: pointer;'></p>
                <h5 class="CustFont mb-2" [innerHTML]="dis_Obj1.heading2.title"></h5>
                <p class="mb-4 pb-2 appTxt" [innerHTML]="dis_Obj1.heading2.text"></p>
                <h5 class="CustFont mb-2" [innerHTML]="dis_Obj1.heading3.title"></h5>
                <p routerLink="/partners" class="mb-4 pb-2 appTxt" [innerHTML]="dis_Obj1.heading3.text"
                  style='cursor: pointer;'></p>
                <h5 class="CustFont mb-2" [innerHTML]="dis_Obj1.heading4.title"></h5>
                <p class="mb-4 pb-2 appTxt" [innerHTML]="dis_Obj1.heading4.text"></p>
                <h5 class="CustFont mb-2" [innerHTML]="dis_Obj1.heading5.title"></h5>
                <p class="mb-4 pb-2 appTxt" [innerHTML]="dis_Obj1.heading5.text"></p>
                <button routerLink="/submit-code" class="btn yellowBtn mt-5">
                  {{ dis_Obj1.btn }}
                </button>
                <p (click)="openPDF()" class="mt-4 font-14" style='cursor: pointer;'>
                  <u>{{ dis_Obj1.T_C }}</u>
                </p>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="competition2" role="tabpanel">
            <div class="text-center competitionImg">
              <img [src]="dis_Obj2.img" width="95%" />
            </div>
            <div class="row justify-content-center pt-4">
              <div class="col-sm-7 text-center">
                <p class="appTxt mt-5 mb-4" [innerHTML]="dis_Obj2.text"></p>
                <p class="font-14" (click)="openPDF2()">
                  <u class="pointer">{{ dis_Obj2.T_C }}</u>
                </p>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="competition3" role="tabpanel">
            <div class="text-center competitionImg">
              <img [src]="dis_Obj3.img" width="90%" />
            </div>
            <div class="row justify-content-center mt-5 pt-3">
              <div class="col-sm-8 text-center">
                <h5 class="CustFont mb-2" [innerHTML]="dis_Obj3.header1.heading1"></h5>
                <p (click)="MOdal()" class="mb-4 pb-2 appTxt" [innerHTML]="dis_Obj3.header1.text"
                  style='cursor: pointer;'></p>
                <h5 class="CustFont mb-2" [innerHTML]="dis_Obj3.header2.heading1">
                  Langkah #2
                </h5>
                <p class="mb-4 appTxt" [innerHTML]="dis_Obj3.header2.text"></p>
                <button (click)="MOdal()" class="btn yellowBtn mt-4" style='cursor: pointer;'>
                  {{ dis_Obj3.btn }}
                </button>
                <p class="mt-4 font-14" (click)="openPDF3()">
                  <u class="pointer">{{ dis_Obj3.T_C }}</u>
                </p>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="competition4" role="tabpanel">
            <div class="text-center competitionImg">
              <img [src]="dis_Obj4.img" width="90%" />
            </div>
            <div class="row justify-content-center mt-5 pt-3">
              <div class="col-sm-7 text-center">
                <p routerLink='/programs' style="cursor: pointer;" class="appTxt mb-5" [innerHTML]="dis_Obj4.Info"></p>
                <h5 class="CustFont mb-2" [innerHTML]="dis_Obj4.heading1.title"></h5>
                <p class="mb-4 pb-2 appTxt" [innerHTML]="dis_Obj4.heading1.text"></p>
                <h5 class="CustFont mb-2" [innerHTML]="dis_Obj4.heading2.title"></h5>
                <p class="mb-4 pb-2 appTxt" [innerHTML]="dis_Obj4.heading2.text"></p>
                <h5 class="CustFont mb-2" [innerHTML]="dis_Obj4.heading3.title"></h5>
                <p class="mb-4 pb-2 appTxt" [innerHTML]="dis_Obj4.heading3.text"></p>
                <h5 class="CustFont mb-2" [innerHTML]="dis_Obj4.heading4.title"></h5>
                <p class="mb-4 pb-2 appTxt" [innerHTML]="dis_Obj4.heading4.text"></p>
                <p class="mt-4 font-14" (click)="openPDF4()">
                  <u class="pointer">{{ dis_Obj4.T_C }}</u>
                </p>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="competition5" role="tabpanel">
            <div class="text-center competitionImg">
              <img [src]="dis_Obj5.img" width="90%" />
            </div>
            <div class="row justify-content-center mt-5 pt-3">
              <div class="col-sm-7 text-center">
                <p class="appTxt mb-5">
                  {{ dis_Obj5.Info }}
                </p>
                <h5 class="CustFont mb-2">{{ dis_Obj5.heading1.title }}</h5>
                <a href="https://www.instagram.com/minggusahamdigital/" target="_blank"
                  style="text-decoration: none; color: white ;">
                  <p class="mb-4 pb-2 appTxt" [innerHTML]="dis_Obj5.heading1.text"></p>
                </a>
                <h5 class="CustFont mb-2">{{ dis_Obj5.heading2.title }}</h5>
                <p class="mb-4 pb-2 appTxt">
                  {{ dis_Obj5.heading2.text }}
                </p>
                <h5 class="CustFont mb-2">{{ dis_Obj5.heading3.title }}</h5>
                <p class="mb-4 pb-2 appTxt">
                  {{ dis_Obj5.heading3.text }}
                </p>
                <h5 class="CustFont mb-2">{{ dis_Obj5.heading4.title }}</h5>
                <a href="https://www.instagram.com/minggusahamdigital/" target="_blank"
                  style="text-decoration: none; color: white ;">
                  <p class="mb-4 pb-2 appTxt">
                    {{ dis_Obj5.heading4.text }}
                  </p>
                </a>
                <p class="mt-4 font-14" (click)="openPDF5()">
                  <u class="pointer">{{ dis_Obj5.T_C }}</u>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>