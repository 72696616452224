<nav class="navbar navbar-expand-lg bg-dark navbar-dark sticky-top py-0">
  <a class="navbar-brand pointer">
    <!-- <a href="https://www.pnb.com.my" target="_blank"> <img src="/assets/imgs/png_logo.svg" height="45px"> </a> -->
    <a href="https://www.pnb.com.my" target="_blank"> <img src="/assets/imgs/pnb-logo.jpeg" height="30px"> </a>

    <!--   <img class="ml-4" src="/assets/imgs/pnb_logo_2.svg" height="20px"> -->
    <a href="https://www.asnb.com.my/" target="_blank"> <img class="ml-4" src="/assets/imgs/asnb-logo.jpeg"
        height="30px"></a>
  </a>
</nav>
<div class="languageCont">
  <h6 class="my-0" (click)='languageChange("BM")' [class.select]='!englishMode'>BM</h6>
  <h6 class="mx-2">|</h6>
  <h6 (click)='languageChange("ENG")' [class.select]='englishMode'>EN</h6>
</div>


<section class="sect1">
  <div class="bgLayer"></div>
</section>


<div class="row sect2">
  <div class="col-sm-9 px-0 prizeCont">
    <img [src]="displyModel.image1" width="100%" data-toggle="modal" data-target="#this" (click)='formOpen()'>
    <!-- <div class="textCont">
      <h5 class="mb-3 appTxt CustFont">Hadiah-hadiah bernilai lebih daripada</h5>
      <h1 class="mb-4 CustFont text1 bold">RM500,000</h1>
      <h4 class="mb-0 CustFont">untuk dimenangi</h4>
      <button data-toggle="modal" data-target="#this" class="btn yellowBtn mt-4">DAFTAR SEKARANG</button>
    </div> -->
    <!-- <div class="text-center btnsCont">
      <img src="/assets/imgs/p7.svg" height="40px">
      <img src="/assets/imgs/p8.svg" height="40px" class="ml-4">
      <p class="mt-3 mb-0 appTxt">Baucar-Baucar Bernilai RM125,000</p>
    </div> -->
  </div>

  <div style="display: block;" class="col-sm-8">
    <iframe class="video-frame" src="https://player.vimeo.com/video/470026805" width="640" height="360" frameborder="0"
      allow="autoplay; fullscreen" allowfullscreen></iframe>
  </div>


  <!--   <div style="display: none;" class="row video-frame-row">
    <iframe class="video-frame" src="https://player.vimeo.com/video/470026805" frameborder="0"
      allow="autoplay; fullscreen" allowfullscreen></iframe>
  </div>
 -->

  <div class="col-sm-9 text-center pt-5 detailCont" *ngIf='!englishMode'>
    <!-- <h5 class="mb-2 appTxt CustFont" style="font-family: Roboto !important;">UNTUK PERTAMA KALI</h5>
    <h2 class="mb-3 CustFont text1 bold" style="font-family: Roboto !important;">MINGGU SAHAM DIGITAL</h2>
    <h3 class="mb-0 font-weight-bolder" style="font-family: Roboto !important;">PNB</h3> -->
    <img [src]="displyModel.image2" class="mt-5" width="100%">
    <button data-toggle="modal" data-target="#this" class="btn yellowBtn pinkBtn"
      (click)='formOpen()'>{{displyModel.buttonText}}</button>
  </div>
  <div class="col-sm-9 text-center pt-5 detailCont" *ngIf='englishMode'>
    <!-- <h5 class="mb-2 appTxt CustFont" style="font-family: Roboto !important;">PNB'S</h5>
    <h3 class="mb-0 font-weight-bolder" style="font-family: Roboto !important;">FIRST TIME EVER</h3>
    <h2 class="mb-3 CustFont text1 bold" style="font-family: Roboto !important;">MINGGU SAHAM DIGITAL</h2> -->

    <img [src]="displyModel.image2" class="mt-5" width="100%">
    <button data-toggle="modal" data-target="#this" class="btn yellowBtn pinkBtn"
      (click)='formOpen()'>{{displyModel.buttonText}}</button>
  </div>
  <div class="col-sm-12 partnerCont px-0">
    <div class="row justify-content-center">
      <div class="col-sm-8 px-0 text-center">
        <img src="/assets/imgs/Partners.jpeg" width="90%">
      </div>
    </div>
  </div>
</div>



<app-footer3></app-footer3>


<div class="modal fade" id="this">
  <div class="modal-dialog myModal modal-dialog-centered">
    <div class="modal-content">
      <i id="thisModal" class="fas fa-times closeBtn pointer" data-dismiss="modal" (click)='scrollUp(target2)'></i>
      <div class="inputCont" id='Sdiv' (scroll)="onScroll($event)">

        <h5 class="mt-2 mb-3" #target2>{{displyModel.ModalHeader1}}<br /> {{displyModel.ModalHeader2}}</h5>
        <form [formGroup]='registrationForm'>
          <div class="row justify-content-center">

            <div class="col-sm-8 col-9 px-0">

              <div class="form-group">
                <label>{{displyModel.field1}}</label>
                <input formControlName='name' type="text" class="form-control">
              </div>
              <div class="form-group">
                <label>{{displyModel.field4}}</label>
                <input class="form-control" type='number' formControlName='phone'>
              </div>
              <div class="form-group">
                <label>{{displyModel.field2}}</label>
                <input formControlName='email' (click)="scrolldown(target)" type="email" class="form-control"
                  [ngClass]="{ 'is-invalid': f.email.errors }">

                <div *ngIf=" f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                </div>
              </div>

              <div class="form-group">
                <label>{{displyModel.field3}}</label>
                <input formControlName='nric' type='text' class="form-control"
                  [ngClass]="{ 'is-invalid': f.nric.errors }">
                <div *ngIf=" f.nric.errors" class="invalid-feedback" [ngClass]="{ 'is-invalid': f.nric.errors }">
                  <div *ngIf="f.nric.errors.pattern">No Special character Allowed </div>
                  <div *ngIf="f.nric.errors.maxlength">Max 12 characters are Allowed </div>
                </div>

              </div>
              <div class="form-group">
                <label>{{displyModel.field5}}</label>
                <input formControlName='password' type="password" class="form-control"
                  [ngClass]="{ 'is-invalid': f.password.errors }">

                <div *ngIf=" f.password.errors" class="invalid-feedback">

                  <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                </div>
              </div>

              <div class='row'>
                <p style="width: 100%;margin: 0px;padding-top: 0.2rem;">
                  <img *ngIf='upWord' class="fixed" (click)="scrollUp(target2)" src="assets/imgs/up-arrow.svg">
                  <img *ngIf='downWord' class="fixed" (click)="scrolldown(target)" src="assets/imgs/down-arrow.svg">
                </p>
              </div>

              <div class="custom-control custom-checkbox">
                <input checked type="checkbox" class="custom-control-input" id="customCheck" name="example1">
                <label class="custom-control-label" for="customCheck">{{displyModel.bottomText}}</label>
              </div>
              <button id='positionId' #target class="btn darkBtn btn-block" [disabled]='registrationForm.invalid'
                (click)='submit()'>{{displyModel.ModalBtn}}</button>

              <p class="font-14 my-0 italic-small">{{displyModel.bySubmitting}}</p>

              <p class="font-14 my-0 pointer" (click)='openPDF()'><u>{{displyModel.info}}</u></p>
              <p class="font-14 my-0 pointer" (click)='openPDF2()'><u>{{displyModel.info2}}</u></p>
            </div>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fall" [fullScreen]="true">
  <p style="color: white"></p>
</ngx-spinner>
