<app-header></app-header>
<div class="languageCont">
  <h6 class="my-0" (click)='languageChange("BM")' [class.select]='!englishMode'>BM</h6>
  <h6 class="mx-2">|</h6>
  <h6 (click)='languageChange("ENG")' [class.select]='englishMode'>EN</h6>
</div>
<div class="row mainCont">
  <div class="col-sm-10">
    <h1 class="CustFont text-center">{{this.pageTitle}}</h1>
    <div id="expertsSlider" class="carousel slide programsSlider" data-ride="carousel" data-interval="false">
      <div class="carousel-inner row" role="listbox">
        <div class="carousel-item col-sm-12 px-0 {{ item.active }}" *ngFor="let item of videoItems">
          <div class="row">
            <div class="col-sm-5 programDetailCont">
              <h3 class="CustFont mb-3">{{ item.name }}</h3>
              <p [innerHTML]="item.description | nl2br"></p>
              <div class="row daystimeCont">
                <div class="col-sm-12 pl-0">
                  <h5 *ngFor="let date of item.dates">
                    {{ date }}
                  </h5>
                  <h6 *ngFor="let time of item.time">
                    {{ time }}
                  </h6>
                </div>

              </div>
            </div>
            <div class="col-sm-7">
              <iframe class="video-frame" [src]="item.src | safe" frameborder="0" allow="fullscreen"
                allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#expertsSlider" role="button" data-slide="prev">
        <img src="/assets/imgs/left _Arrow.svg" height="40px" />
      </a>
      <a class="carousel-control-next" href="#expertsSlider" role="button" data-slide="next">
        <img src="/assets/imgs/right_Arrow.svg" height="40px" />
      </a>
    </div>
    <h1 class="CustFont text-center pt-2">
      {{ englishMode ? "SCHEDULE" : "JADUAL" }}
    </h1>
    <div class="row justify-content-center">
      <div class="col-sm-11">
        <div class="ScheduleCont" style="font-family: 'Furore' !important;">
          <div (click)="selectedDay = schedule.day" [class.active]="selectedDay === schedule.day" class="dayCont"
            *ngFor="let schedule of Schedules">
            <div class="text-center">
              <h5 class="mb-0" style="font-family: 'Furore' !important;">{{ schedule.day }}</h5>
              <p class="mb-0" style="font-family: 'Furore' !important;">{{ schedule.date }}</p>
            </div>
          </div>
        </div>
        <ng-container *ngFor="let schedule of Schedules">
          <ng-container *ngIf="selectedDay === schedule.day">
            <div class="row scheduleDetail" *ngFor="let scheduleSlot of schedule.scheduleSlots">
              <div class="col-sm-3 px-0    text1">
                <p class="font-17 mb-0 px-1">{{ scheduleSlot.timeslot }}</p>
              </div>
              <div class="col-sm-3">
                <p class="mb-0 bold font-17 px-1">{{ scheduleSlot.speaker }}</p>
              </div>
              <div class="col-sm-6">
                <p class="font-15 mb-0 px-1">
                  {{ scheduleSlot.description }}
                </p>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
