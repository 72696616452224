import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

declare var window: any;

@Component({
  selector: 'app-khidmat',
  templateUrl: './khidmat.component.html',
  styleUrls: ['./khidmat.component.scss'],
})
export class KhidmatComponent implements OnInit {
  englishMode = false;

  constructor(private service: DataService) { }

  public hyperlink = (data: any) => {
    window.open(data, '_blank');
  };

  ngOnInit(): void {
    this.service.language.subscribe((lang) => {
      this.englishMode = lang == 'ENG' ? true : false;
    });
  }
  public languageChange = (data: any) => {

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Language Switch',
      'eventCallback': function () {
        console.log('Language Switch Event Tags Triggered and Fired');
      }
    });
    if (data == 'ENG') {
      this.service.language.next('ENG');
      this.englishMode = true;
    } else if (data == 'BM') {
      this.service.language.next('BM');
      this.englishMode = false;
    }
  };
}
